import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, debounceTime } from 'rxjs';
import { CartItemModel } from 'src/core/models/cart';
import { ProductModel } from 'src/core/models/product';
import { RedemptionResultModel } from 'src/core/models/redemption';
import { AccountService } from 'src/core/services/account.service';
import { CartService } from 'src/core/services/cart.service';

@Component({
  selector: 'app-fca-redeem',
  templateUrl: './fca-redeem.component.html',
  styleUrls: ['./fca-redeem.component.scss']
})
export class FcaRedeemComponent {

  @Input() product: ProductModel;
  fcaRedeemForm: FormGroup;
  formInvalid: boolean;

  maxRedemptionAmt: string;
  dollarAmountEntered: number;
  pointsValue: string;
  orderResult: RedemptionResultModel;
  subscriptionOrder: Subscription;

  formSubscription: Subscription;

  validForm: any;
  closeResult: string;
  success: boolean;
  failed: boolean;
  neutral: boolean = true;
  submitedDollarAmount: number;

  @ViewChild('confirmation') sampleCheckDialog = {} as TemplateRef<any>;
  confirmationDialogRef: MatDialogRef<any, any>;


  constructor(private cart: CartService, private accountService: AccountService,
    public formBuilder: FormBuilder, public dialog: MatDialog) {

  }

  ngOnInit(): void {
    // Subscribe to redemtion order to check the redemtion status
    this.subscriptionOrder = this.accountService.$redemptionResult
      .subscribe(orderResult => {
        this.orderResult = orderResult;
        if (this.orderResult !== undefined && this.dollarAmountEntered !== undefined) {
          this.updateStatus();
        }
      });

    this.calculateFCAMax();
    this.setupForm();

  }

  //setting up form with validators
  setupForm() {
    this.fcaRedeemForm = this.formBuilder.group({
      dollarAmount: ['', [Validators.required, Validators.minLength(1),
      Validators.maxLength(16), Validators.pattern('^\d*(\.\d+)?$')]],
    });

    


    this.formSubscription = this.fcaRedeemForm.valueChanges.pipe(debounceTime(500)).subscribe(x => {
      this.formInvalid = false;
      this.onValueChange();
    })
  }

  //Calculate the up to dollar value the max value the user can enter
  calculateFCAMax() {
    let CurrentPointsBalance = this.accountService.accountInfo.points;
    let BPS = this.product.productBPSRate;
    this.maxRedemptionAmt = (CurrentPointsBalance / ((1 / BPS) * 10000)).toFixed(2);
  }

  //Convert the dollar amount entered by the user to points.
  calculateFCARedeem() {
    let BPS = this.product.productBPSRate;
    this.pointsValue = ((((1 / BPS) * 10000)) * this.dollarAmountEntered).toFixed(2);
  }


  //Update the status message and buttons
  updateStatus() {
    if (this.orderResult.status === 2) {

      this.submitedDollarAmount = this.dollarAmountEntered;
      //If doRedemption is successful, clear the cart.
      this.cart.clearCart();
      this.success = true;
      this.failed = false;
      this.neutral = false;
      this.accountService.clearRedemtionResult();
    }
    else {
      
      this.failed = true;
      this.success = false;
      this.neutral = false;
      this.accountService.clearRedemtionResult();
    }

    this.formInvalid =false;
  }

  onValueChange() {

    this.isFormValid();
    this.success = false;
    this.failed = false;
    this.neutral = true;
    this.accountService.clearRedemtionResult();
  }


  //Add to cart and submit the doRedemption
  submit() {

    if (!this.isFormValid) {
      this.formInvalid = true;
      this.closeConfirmationModal();
      return;
    }

    let cartItem = new CartItemModel();
    this.cart.clearCart();
    cartItem.description = this.product.items[0].description;
    cartItem.imageUrl = this.product.items[0].smallImageUrl;
    cartItem.itemId = this.product.items[0].inventoryItemId;
    cartItem.productName = this.product.name;
    cartItem.name = this.product.items[0].inventoryItemName;
    cartItem.points = parseInt(this.pointsValue);
    cartItem.quantity = 1;
    cartItem.pointsExtended = parseInt(this.pointsValue);
    cartItem.canPartialPay = false;
    cartItem.vendorId = this.product.items[0].vendorId;
    cartItem.cashDollarValue = this.dollarAmountEntered;

    cartItem.shipmentChoicesId = this.product.shipmentChoicesId;
    cartItem.tandC = this.product.tandC;

    // Add the item to the cart
    this.cart.addItem(cartItem);
    

    //Call doRedemption
    this.accountService.doRedemption();

    this.closeConfirmationModal();

    this.formInvalid = true;

  }


  openConfirmationModal() {
  
    if (!this.isFormValid()) {
      this.formInvalid = true;
      return
    } else {
      this.calculateFCARedeem();
      this.confirmationDialogRef = this.dialog.open(this.sampleCheckDialog, {
        disableClose: false,
        panelClass: "fnbo-fca-r-panel",
        hasBackdrop: true
      });

    }
  }

  closeConfirmationModal() {
    if (this.confirmationDialogRef) {

      this.confirmationDialogRef.close();
      this.formInvalid = false;
    }
  }

  /**
   *  if the form is valid, method returns true.
   * @returns boolean
   */
  isFormValid(): boolean {
   
    if (!this.dollarAmountEntered) {
      return false;
    }

    let numAmount = Number(this.dollarAmountEntered);

    // if (numAmount >= 10 && numAmount <= parseFloat(this.maxRedemptionAmt)) {
    if (numAmount >= 10 && numAmount <= Number(this.maxRedemptionAmt)) {
      this.fcaRedeemForm.controls.dollarAmount.setErrors(null);
      this.fcaRedeemForm.controls.dollarAmount.markAsPristine();
      return true;
    }

    this.fcaRedeemForm.controls.dollarAmount.markAsDirty();
    this.fcaRedeemForm.controls.dollarAmount.setErrors({ 'incorrect': true });

    return false;

  }


  //get the controls
  get fcaRedeemFormControls() {
    return this.fcaRedeemForm.controls;
  }

  ngOnDestroy() {
    if (this.subscriptionOrder) {
      this.subscriptionOrder.unsubscribe();
    }

    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

}
