//Manages items like categories and products
import { HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, catchError, Observable, throwError } from "rxjs";
import { CategoryModel, MenuCategory } from "../models/category";
import { FilteredProductModel, ProductModel } from "../models/product";
import { HttpWrapperService } from "./http-wrapper.service";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UtilityService } from "./utility.service";

@Injectable()
export class CatalogService {

    categories: CategoryModel[];
    rootCategories: CategoryModel[];
    menuCategories: MenuCategory[];// = new MenuCategory();
    currentProducts: ProductModel[];

    private _rootCategories: BehaviorSubject<CategoryModel[]>;
    rootCategories$: Observable<CategoryModel[]>;
    private _menuCats$ = new BehaviorSubject<MenuCategory[]>([]);


    constructor(private http: HttpWrapperService, private router: Router, private utilityService: UtilityService) {

        this._rootCategories = new BehaviorSubject<CategoryModel[]>(this.categories);
        this.rootCategories$ = this._rootCategories.asObservable();
    }




    //get all categories
    getCategories() {

        this.http.get('api/categories').pipe(catchError(error => this.handleError(error)))
            .subscribe(
                cat => {
                    //global loading
                    this.utilityService.addLoadingCallsCount(1);
                    this.categories = cat

                    if (this.categories.length > 0 && this.categories[0].errorMessage !== null) {
                        this.router.navigate(['error']);
                    }
                    //setting the root cats
                    this.rootCategories = this.categories.filter(c => c.parentId === 0);

                    //setting up the menu links
                    this.getMenuBarNavigation(this.rootCategories);

                    if (this.rootCategories) {
                        let rootCategories = this.rootCategories;

                        this.rootCategories = rootCategories.sort((a, b) => {
                            return (a.displayOrder > b.displayOrder) ? 1 : -1;
                        });
                    }
                    //sending out the data to all listeners/subscribers of the observable 
                    this._rootCategories.next(this.rootCategories);

                    //global loading
                    this.utilityService.addCompletedLoadingCalls(1);
                }
            )
    }

    //get products base on cat id
    getProducts(categoryId: number): Observable<ProductModel[]> {
        return this.http.get('api/products/' + categoryId);

    }

    getActiveProducts(categoryId: number, page: number = -1): Observable<ProductModel[]> {

        var headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        // Using the global product filters instead of inline version.
        let programCode = sessionStorage.getItem('program_code');


        //page set to -1 to turn off paging
        return this.http.post('api/products/page', {
            programCode: programCode, categoryId: categoryId, page: page
        }, { headers: headers });
    }
    //use to setup the menu categories that become links
    getMenuBarNavigation(rootCats: CategoryModel[]) {

        if (this.menuCategories) {
            this._menuCats$.next(this.menuCategories);
        }

        this.menuCategories = [];

        rootCats.forEach(cat => {

            let children = this.categories.filter(c => c.parentId === cat.id);
            this.menuCategories.push({ rootCat: cat, children: children });

        });

        if (this.menuCategories) {
            let menuCategories = this.menuCategories;
            this.menuCategories = menuCategories.sort((a, b) => {
                return (a.rootCat.displayOrder > b.rootCat.displayOrder) ? 1 : -1;
            });
        }

        this._menuCats$.next(this.menuCategories);

    }


    getMenuCats$() {
        return this._menuCats$.asObservable();
    }


    //get the type ahead product names 
    //this method needs to improve to get by search string
    //TODO : it always return the same number of items
    getTypeaheadProductNames(queryString: string): Observable<string[]> {


        var headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json')

        return this.http.post('api/products/typeahead', { productSearch: queryString },
            { headers: headers });

    }

    //search products

    productSearch(queryString: string): Observable<ProductModel[]> {

        var headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json')

        let searchCriteria = new FilteredProductModel();

        searchCriteria.productSearch = queryString;
        searchCriteria.programCode = sessionStorage.getItem('program_code') || "";

        //a better api should be built to get product by name
        return this.http.post('api/products/page', searchCriteria,
            { headers: headers });
    }

    //gets the highlighted producted
    getProductHighlights(): Observable<ProductModel[]> {
        return this.http.get('api/products/highlights');
    }

    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {

            const body = error.json() || '';

            var err = "";
            body.then(er => {
                err = er.error || JSON.stringify(body);
            })

            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return throwError(() => new Error(errMsg));
    }
}