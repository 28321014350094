import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProductModel } from 'src/core/models/product';
import { SettingsOptions } from 'src/core/models/settings';
import { CatalogService } from 'src/core/services/catalog.service';
import { StyleService } from 'src/core/services/style.service';
import { UtilityService } from 'src/core/services/utility.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent {

  queryString: string;

  products: ProductModel[];
  brands: Set<any> = new Set<any>();

  //current page fot pagination
  currentPage: number = 1;
  //max number of pages show in pagination
  pageLinksMaxSize: number = 6;
  showFilter: boolean = false; //uses to show the filter
  showFilterText: string = "Show Filters"

  // filters
  selectedBrand: any = "any";
  selectedPoints: any = "any";
  sortBy: any = "startingAt-des";


  loading: boolean = false;
  subscriptions: Subscription[];

  constructor(private route: ActivatedRoute, private router: Router,
    private catalogService: CatalogService,
    private styleService: StyleService, private utilityService: UtilityService) {
      this.subscriptions = [];
  }

  ngOnInit() {
    this.setup();
    

  }

  ngAfterViewInit() { }


  setup() {

    /**
     * whenever the route change with the  search query
     * this route subscribe picks up and refreshes the page. 
     * The initial subscribe value will hold the last route
    **/
    let sub = this.route.queryParams.subscribe(params => {
   
      this.queryString = params["s"];

      this.brands = new Set();

      this.loadProducts();
      // reset currentpage whenever the route changes

      this.currentPage = 1;

      this.utilityService.filterButton$.next(false);
  
    });
    
    
    this.subscriptions.push(sub);

    this.settingStyles();

    //listening to the filter button click
    sub = this.utilityService.filterButton$.subscribe(x => {
      this.showFilter = x;

      this.setFilterButtonText(this.showFilter);
    });

    this.subscriptions.push(sub);
  }


  //load products
  loadProducts() {
   

    if (this.queryString == undefined) {
      this.router.navigate(["/"]);

    } else {

      //getting the products
      this.catalogService.productSearch(this.queryString).subscribe(products => {

        this.products = products;

        this.clearFilter();
        this.loadFilters();
      });
    }
  }



  //loading the filter area
  //by going through the products that were loaded
  loadFilters() {
   
    this.products.forEach(product => {
      if (product) {
        this.brands.add(product.brand);
      }

    });
  }


  clearFilter() {
    this.selectedBrand = "any";
    this.selectedPoints = "any";
    this.sortBy = "startingAt-des";
  }

  //toggling the filter area
  //might be remove if the button on this page itself is not used
  toggleFilter() {
    this.showFilter = !this.showFilter;

    this.setFilterButtonText(this.showFilter);

    //letting other components know that the filter has open/close
    this.utilityService.filterButton$.next(this.showFilter);
  }

  setFilterButtonText(val) {
    if (val) {
      this.showFilterText = "Hide Filters"
    } else {
      this.showFilterText = "Show Filters"
    }
  }


  settingStyles() {
    //setting classes dynamically to fit theme/ color scheme
    this.styleService.setColorStyle("#fnbo-sr-pagination  .ngx-pagination .current", "background", SettingsOptions.COLOR_PRIMARY);
    this.styleService.setColorStyle(".fnbo-sr-filter-items * .mdc-text-field", "border-color", SettingsOptions.COLOR_PRIMARY);
    this.styleService.setColorStyle(".fnbo-sr-filter-items * .mdc-floating-label", "color", SettingsOptions.COLOR_PRIMARY);
    this.styleService.setColorStyle(".fnbo-sr-filter-items * .mat-mdc-select-value", "color", SettingsOptions.COLOR_PRIMARY);
    this.styleService.setColorStyle(".fnbo-sr-filter-items * .mat-mdc-select-arrow", "color", SettingsOptions.COLOR_PRIMARY);

  }

  pageChanged(page){
    this.currentPage  = page;

    //use to travers user to the top of the screen
    const matScrollContent = document.querySelector("mat-drawer-content");
    if(matScrollContent){
      matScrollContent.scrollTop = 0;
    }

  }

  
  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }

}
