import { Injectable } from "@angular/core";
import { ContactModel } from "../models/contact";
import { catchError, throwError } from "rxjs";
import { HttpHeaders } from "@angular/common/http";
import { HttpWrapperService } from "./http-wrapper.service";

@Injectable({
    providedIn: 'root'
})
export class ContactService {


    constructor(private http: HttpWrapperService,) {

    }



    // Public service methods

    postFormContact(data: ContactModel) {
       return this.submitContactForm(data);
    }

    submitContactForm(data: ContactModel) {

        var headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json')

       let results =  this.http.post('/api/ContactForm', data, { headers: headers })
            .pipe(catchError(error => this.handleError(error)));
        return results;
    }



    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {

            const body = error.json() || '';

            var err = "";
            body.then(er => {
                err = er.error || JSON.stringify(body);
            })

            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return throwError(() => new Error(errMsg));
    }
}