import { Component } from '@angular/core';

@Component({
  selector: 'universal-faq',
  templateUrl: './universal-faq.component.html',
  styleUrls: ['./universal-faq.component.scss']
})
export class UniversalFaqComponent {
  
  selected : any = "pnd";

  constructor() {

  }

  ngOnInit() {


  }

}
