<div id="fnbo-search-rslt-content" class="fnbo-center-content fnbo-content">
    <div id="fnbo-sr-filter-btn-area">
        <button class="fnbo-primary-border fnbo-primary-color" mat-button (click)="toggleFilter()">
            <mat-icon aria-hidden="false" aria-label="shopping cart">tune</mat-icon>

            {{showFilterText}}</button>
    </div>
    
    <div id="fnbo-sr-filter" class="fnbo-primary-border" *ngIf="showFilter">
        
        <!-- Filter by brands -->
        <div class="fnbo-sr-filter-items">
           
            <mat-form-field appearance="fill">
                <mat-label>Brands</mat-label>
                <mat-select panelClass="fnbo-sr-mat-panel" [(ngModel)]="selectedBrand">
                    <mat-option value="any">
                        -- Any --
                    </mat-option>
                    <mat-option *ngFor="let brand of brands" [value]="brand">
                        <span [innerHTML]="brand"></span>
                       
                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <!-- Filter by points -->
        <div class="fnbo-sr-filter-items">
          
            <mat-form-field appearance="fill">
                <mat-label>Point</mat-label>
                <mat-select panelClass="fnbo-sr-mat-panel"  [(ngModel)]="selectedPoints">
                    <mat-option value="any">
                        -- Any --
                    </mat-option>
                    <mat-option value="0-2500">
                        0 - 2500
                    </mat-option>
                    <mat-option value="2500-5000">
                        2500 - 5000
                    </mat-option>

                    <mat-option value="5000-7500">
                        5000 - 7500
                    </mat-option>

                    <mat-option value="7500-over">
                    7500 - over
                    </mat-option>
                    
                </mat-select>
            </mat-form-field>

        </div>
        <!-- sort -->
        <div class="fnbo-sr-filter-items">
          
            <mat-form-field appearance="fill">
                <mat-label>Sort by:</mat-label>
                <mat-select  [(ngModel)]="sortBy">
                    
                     <!-- using the property to sort by and the order  -->
                    <mat-option value="startingAt-des">
                        Low to High
                    </mat-option>
                    <!-- using the property to sort by and the order  -->
                    <mat-option value="startingAt-asc">
                        High to Low
                    </mat-option>
                   
                </mat-select>
            </mat-form-field>

        </div>

        <div class="fnbo-sr-filter-clear fnbo-primary-border">
    
            <button class="fnbo-primary-border fnbo-primary-color" mat-button (click)="clearFilter()">
                <mat-icon aria-hidden="false" aria-label="shopping cart">tune</mat-icon>

                Clear Filter</button>
            
        </div>
    </div>


    <div id="fnbo-sr-title-area" class="fnbo-display-flex fnbo-primary-border">
        <h2 class="fnbo-primary-color"> Search Results </h2>

        <div class="fnbo-flex-align-right">
            <a class="fnbo-primary-color" [routerLink]="'/'">

                <mat-icon class="fnbo-primary-color">arrow_back</mat-icon>
                <p class="fnbo">Back Home</p>
            </a>
        </div>
    </div>

    <ng-container *ngIf="products">

        <!-- main list of project -->
        <diV id="fnbo-sr-list">
            <div class="fnbo-sr"
                *ngFor="let product of products | productFilter : selectedBrand : '' : selectedPoints | productSort : sortBy | paginate:{itemsPerPage: 12, currentPage: currentPage, id : 'fnbo-sr-pagination'}">

                <app-single-product [product]="product" [categoryName]="'Search Results'"></app-single-product>

            </div>
        </diV>
        <div *ngIf="products.length > 1" class="fnbo-display-flex">

            <pagination-controls id="fnbo-sr-pagination" (pageChange)="pageChanged($event)"
                [maxSize]="pageLinksMaxSize"></pagination-controls>
        </div>

    </ng-container>


    <ng-container *ngIf="!products || products.length < 1">

        <p>No products</p>
    </ng-container>

</div>