import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription, catchError, debounceTime, lastValueFrom } from 'rxjs';
import { TermsConditionsDialogComponent } from 'src/core/components/terms-conditions-dialog/terms-conditions-dialog.component';
import { AccountModel } from 'src/core/models/account';
import { ContactModel } from 'src/core/models/contact';
import { FormsPatterns } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { ContactService } from 'src/core/services/contact.service';
@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {

  accountInfo: AccountModel;
  contactInfo: ContactModel;
  contactUsForm: FormGroup;
  subscriptions: Subscription[];
  disableSubmit: boolean = false;

  contactFormError: any;
  /**
   *
   */
  constructor(private accountService: AccountService,
    private contactService: ContactService, public dialog: MatDialog,
    private router: Router) {
    this.subscriptions = [];

    this.contactUsForm = new FormGroup({
      firstName: new FormControl('', [Validators.required, Validators.minLength(1)]),
      lastName: new FormControl('', [Validators.required, Validators.minLength(1)]),
      phoneNumber: new FormControl('', [Validators.required, Validators.minLength(7), Validators.pattern(FormsPatterns.PHONENUMBER.toString())]),
      alterativePhoneNumber: new FormControl('', [Validators.minLength(7), Validators.pattern(FormsPatterns.PHONENUMBER.toString())]),
      email: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.email]),
      subject: new FormControl('', [Validators.required, Validators.minLength(1)]),
      message: new FormControl('', [Validators.required, Validators.minLength(1)])
    });
  }

  ngOnInit() {
    let sub = this.accountService.accountInfoItem$.subscribe(
      account => {
        if (account) {

          this.accountInfo = account;
          this.setupForm();
        }
      }
    );

    this.subscriptions.push(sub);
  }


  /**
   * sets up the form
   */
  private setupForm() {
    this.contactUsForm.controls.firstName.setValue(this.accountService.accountInfo.firstName);
    this.contactUsForm.controls.lastName.setValue(this.accountService.accountInfo.lastName);
    this.contactUsForm.controls.phoneNumber.setValue
      (this.formatPhoneNumber(this.accountService.accountInfo.phone) || '');
    this.contactUsForm.controls.alterativePhoneNumber.setValue
      (this.formatPhoneNumber(this.accountService.accountInfo.alternatePhone) || '');
    this.contactUsForm.controls.email.setValue(this.accountService.accountInfo.email || '');

    let sub = this.contactUsForm.controls.phoneNumber.valueChanges.pipe(debounceTime(500))
      .subscribe(number => {
        if (number) {
          this.contactUsForm.controls.phoneNumber.setValue(this.formatPhoneNumber(number));

        }
      });

    this.subscriptions.push(sub);

    sub = this.contactUsForm.controls.alterativePhoneNumber.valueChanges.pipe(debounceTime(500))
      .subscribe(number => {
        if (number) {
          this.contactUsForm.controls.alterativePhoneNumber.setValue(this.formatPhoneNumber(number));

        }
      });

    this.subscriptions.push(sub);
  }


  //set up the contact model
  private setupContactModel() {
    if (this.accountInfo) {
      this.contactInfo = new ContactModel();
      this.contactInfo.name = this.contactUsForm.controls.firstName.getRawValue() + ' ' + this.contactUsForm.controls.lastName.getRawValue();
      this.contactInfo.phone = this.stripPhonenumber(this.contactUsForm.controls.phoneNumber.getRawValue());

      this.contactInfo.altPhone = this.stripPhonenumber(this.contactUsForm.controls.alterativePhoneNumber.getRawValue());
      this.contactInfo.firstName = this.contactUsForm.controls.firstName.getRawValue() || '';
      this.contactInfo.lastName = this.contactUsForm.controls.lastName.getRawValue() || '';
      this.contactInfo.email = this.contactUsForm.controls.email.getRawValue() || '';
      this.contactInfo.accountId = this.accountInfo.id.toString() || '';

      this.contactInfo.subject = this.contactUsForm.controls.subject.getRawValue() || '';
      this.contactInfo.message = this.contactUsForm.controls.message.getRawValue() || '';
    }
  }

  //submits the contact us request
  async submit(): Promise<void> {
  
    this.disableSubmit = true;
    this.contactFormError = undefined;

    if (!this.contactUsForm) {
      this.disableSubmit = false;
      return;
    } else if (this.contactUsForm.valid) {

      this.setupContactModel();
      this.contactService.postFormContact(this.contactInfo).pipe(catchError(x => {
        this.disableSubmit = false;
        this.contactFormError = "Something went wrong. Please try again later."
        return x;
      })).subscribe(x => {

        this.disableSubmit = false;

        alert('Feedback Submitted.  Thank you');

      });

    } else {

      Object.keys(this.contactUsForm.controls).forEach(key => {
        if (key) {
          this.contactUsForm.get(key)!.markAsDirty();
        }
      });
      this.disableSubmit = false;
    }
  }

  /**
   * unformat the phone number for storing
   * @param phoneNumber 
   * @returns string 
   */
  stripPhonenumber(phoneNumber) {
    return ('' + phoneNumber).replace(/\D/g, '');
  }

  /**
   *  formats the phone number just for display
   * @param phoneNumber 
   * @returns string
   */
  formatPhoneNumber(phoneNumber: string) {
    phoneNumber = ('' + phoneNumber).replace(/\D/g, '');
    var match = phoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
  }



  //shows the terms and conditions
  showRedemptionTNC() {

    //open dialog
    const dialogRef = this.dialog.open(TermsConditionsDialogComponent, {
      maxWidth: '760px',
      panelClass: 'fnbo-terms-condition-dialog',
      backdropClass: '',
      data: {
        title: 'Redemption Terms & Conditions',
        showDefault: true
      }
    });
  }

  //get the controls
  get contactUsFormControls() {
    return this.contactUsForm.controls;
  }


  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }
}