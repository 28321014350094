import { Component, Input } from '@angular/core';
import { OrderHistoryModel } from 'src/core/models/order';

@Component({
  selector: 'app-single-order-history',
  templateUrl: './single-order-history.component.html',
  styleUrls: ['./single-order-history.component.scss']
})
export class SingleOrderHistoryComponent {

  @Input() orderHistory : OrderHistoryModel;
  /**
   *
   */
  constructor() {  
  }

  ngOnInit(){

  }
}
