<div>
    <div>
        <app-home-carousel></app-home-carousel>

    </div>

    
    <div id="fnbo-home-brand-msg">
        <app-brand-msg></app-brand-msg>
    </div>
    <div *ngIf="showDefaultHighlightArea" id="fnbo-home-product-highlights">
        <app-product-highlights></app-product-highlights>
    </div>

    
    <ng-container *ngIf="showUniversalCustArea">
        <div>
            <app-universal-highlights></app-universal-highlights>
        </div>
    </ng-container>

</div>
