<div class="fnbo-space-below">
    <div id="fnbo-oa-header" class="fnbo-display-flex">
        <p class="fnbo-cart-headings">Shipping Address</p>
        <a id="fnbo-oa-edit-link" class="fnbo-flex-align-right" [ngClass]="editClasses || ''" [routerLink]="'/cart'">Edit</a>
    </div>
    <div id="fnbo-oa-summary">
        <p>{{orderInfo.fullName}}</p>
        <p  *ngIf="orderInfo.companyName">{{orderInfo.companyName}}</p>
        <p>{{orderInfo.shippingAddress1}} {{orderInfo.shippingAddress2}}</p>
        <p >{{orderInfo.shippingCity}}, {{orderInfo.shippingState}}, {{orderInfo.shippingCountry}}, {{orderInfo.shippingPostalCode}}</p>
        <p>({{orderInfo.phoneAreaCode}}) {{orderInfo.phonePrefix}}-{{orderInfo.phoneLine}}</p>
        <p>{{orderInfo.emailAddress}}</p>
    </div>
</div>