import { Component, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, map } from 'rxjs';
import { CartItemModel, CartSummaryModel } from 'src/core/models/cart';
import { OrderInfoModel } from 'src/core/models/order';
import { PaymentAddressModel, PaymentFormRequestModel } from 'src/core/models/payment';
import { StyleSettings } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { CartService } from 'src/core/services/cart.service';
import { PaymentService } from 'src/core/services/payment.service';


@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.scss']
})
export class OrderReviewComponent {

  itemCount: number = 0;
  totalPoints: number = 0;
  partialPayPointsTotal: number = 0;

  cartItems: CartItemModel[];
  cartSummary: CartSummaryModel;
  showTablet: boolean = false;
  isPaperCheck: boolean = false;
  addressConfirmSelected: boolean = false;
  addressConfirmError: boolean = false;
  isMagazineOrGiftCard: boolean = true;

  loadingCart: boolean = true;

  //use to go back
  previousUrl: string = "/";
  username: string;

  //holds the subcriptions
  subscriptions: Subscription[];

  disableComplete : boolean = false;
  processing : boolean = false;

  constructor(private router: Router,
    private route: ActivatedRoute, private cartService: CartService,
    private paymentService: PaymentService, private accountService: AccountService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    if (window.innerWidth <= StyleSettings.MAXTABLETWIDTH) {
      this.showTablet = true;
    } else {
      this.showTablet = false;
    }
    this.setup();
  }


  ngAfterViewInit(): void {

    // Only get a payment token when we are charging a card
    if (this.cartService.cartSummary.cashTotal > 0) {
      this.getPaymentToken();
    }
  }

  setup() {

    this.username = sessionStorage.getItem("csr_name")!;
    //getting the query params passed through
    //the route service ; this will not show in the 
    //url area

    let currentStateSubscription = this.route.paramMap.pipe(map(() => window.history.state)).subscribe(x => {
      if (x) {

        //pervious link to navigate to
        if (x.previousUrl) {
          this.previousUrl = x.previousUrl;
        }

      }
    });

    let cartSub = this.cartService.$cart.subscribe(x => {
      this.getCartData();
    });

    // Subscribe to token changes
    let subscriptionToken = this.paymentService.tokenInfoItem$
      .subscribe(token => {

        if (token !== undefined && token.length > 0) {
          // Only get a payment token when we are charging a card
          if (this.cartService.cartSummary.cashTotal > 0) {
            this.setTokenForPaymentFormDisplay(token);
          }
        }
      }
      );

    let partialPaySub = this.cartService.$partialPayPoints.subscribe(x => {
      if (x) {
        this.partialPayPointsTotal = x;
      }
    });

    //adding subscription for ngdestory

    this.subscriptions.push(cartSub);
    this.subscriptions.push(subscriptionToken);
    this.subscriptions.push(currentStateSubscription);
    this.subscriptions.push(partialPaySub);

    //checking for paper check
    this.checkCartProductTypes();

    //temporary
    this.loadingCart = false;
  }

  //TODO: there has to be a better way of doing this
  //checking for different product types
  checkCartProductTypes() {
    this.cartItems.forEach(element => {
      if (element.imageUrl) {
        if (element.brand.toLowerCase() == "fbo_cashback" && element.name.toLowerCase().includes("paper check")) {
          this.isPaperCheck = true;
        }

        if (element.imageUrl.toLowerCase().includes("awardcenter")) {
          this.isMagazineOrGiftCard = false;
        }
      }
    });

  }


  getCartData() {
    this.cartSummary = this.cartService.cartSummary;
    this.cartItems = this.cartService.getFullCartList();
    this.itemCount = this.cartSummary.itemCount;
    this.totalPoints = this.cartSummary.pointsTotal;

    if (!this.cartSummary || !this.cartItems || this.itemCount == 0) {
      this.router.navigate(["/"]);
    } else if (!this.accountService.orderInfo) {
      //if there is no order info then, there isn't an order to review
      this.router.navigate(["/cart"]);
    }
  }

  /**
   * remove an item from the cart
   * @param cartItem 
   */
  removeItem(cartItem: CartItemModel) {
    this.loadingCart = true;

    this.cartService.removeItem(cartItem.itemId)

    this.getCartData(); // reloading the cart

    this.loadingCart = false;
  }


  //go back to the previous page
  goBack() {
    this.router.navigate(["/highlights"]);
  }

  async completeOrder() {
    this.disableComplete = true;

    if (this.accountService.orderInfo) {
      this.processing = true;
      
      this.accountService.clearRedemtionResult();
      await this.accountService.doRedemption();
      
      this.processing = false;
      
      this.router.navigate(['./checkout/order-results']);
    } else {
      this.router.navigate(['./cart']);
      //TODO throw error
    }
    this.disableComplete = false;

  }

  // Given a token, set its value to a form and submit it to Authorize.Net
  // to return a payment form in the given iframe.
  setTokenForPaymentFormDisplay(token: string) {
    if (token) {

      let iFramePayment = window.document.getElementById('payment_information') as HTMLIFrameElement;

      let iFrameDocument = iFramePayment?.contentDocument || iFramePayment?.contentWindow?.document;
      let tokenElement = iFrameDocument?.getElementById('token') as HTMLInputElement;

      if (tokenElement) {
        tokenElement.value = token;
      }
      else {
        return;
      }

      let paymentFormSubmit = iFramePayment.contentDocument?.getElementById('send_token') as HTMLFormElement;
      paymentFormSubmit.submit();
    }
  }

  getPaymentToken() {

    let paymentParms: PaymentFormRequestModel = new PaymentFormRequestModel();

    if (this.cartSummary !== undefined && this.accountService.orderInfo !== undefined) {
      paymentParms.amount = this.cartSummary.cashTotal;

      // Set address info if marked as the same from order information screen
      if (this.accountService.orderInfo.paymentSameAddress) {
        paymentParms.firstName = this.accountService.accountInfo.firstName;
        paymentParms.lastName = this.accountService.accountInfo.lastName;
        paymentParms.address = this.accountService.orderInfo.shippingAddress1;
        paymentParms.city = this.accountService.orderInfo.shippingCity;
        paymentParms.state = this.accountService.orderInfo.shippingState;
        paymentParms.zip = this.accountService.orderInfo.shippingPostalCode;
        paymentParms.country = this.accountService.orderInfo.shippingCountry;
        paymentParms.email = this.accountService.orderInfo.emailAddress;
        paymentParms.phone = this.accountService.orderInfo.phoneAreaCode +
          this.accountService.orderInfo.phonePrefix +
          this.accountService.orderInfo.phoneLine;
      }
      // Shipping address info
      paymentParms.PaymentAddress = new PaymentAddressModel();

      paymentParms.PaymentAddress.firstName = this.accountService.accountInfo.firstName;
      paymentParms.PaymentAddress.lastName = this.accountService.accountInfo.lastName;
      paymentParms.PaymentAddress.address = this.accountService.orderInfo.shippingAddress1;
      paymentParms.PaymentAddress.city = this.accountService.orderInfo.shippingCity;
      paymentParms.PaymentAddress.state = this.accountService.orderInfo.shippingState;
      paymentParms.PaymentAddress.zip = this.accountService.orderInfo.shippingPostalCode;
      paymentParms.PaymentAddress.country = this.accountService.orderInfo.shippingCountry;

      this.paymentService.getPaymentToken(paymentParms);
    }
  }

  //controls what set of banners that shows
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= StyleSettings.MAXTABLETWIDTH) {
      this.showTablet = true;
    } else {
      this.showTablet = false;
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }

}
