import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TermsConditionsDialogComponent } from 'src/core/components/terms-conditions-dialog/terms-conditions-dialog.component';
import { AccountModel } from 'src/core/models/account';
import { CartItemModel } from 'src/core/models/cart';
import { ItemModel, ProductModel } from 'src/core/models/product';
import { ProductOptions, SettingsOptions, UNIVERSALPROGRAMS } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { CartService } from 'src/core/services/cart.service';
import { SettingsService } from 'src/core/services/settings.service';
import { BankingInfoComponent } from '../custom-reward/custom-reward.component';
import { Observable, Subscription, firstValueFrom, lastValueFrom, map } from 'rxjs';
import { FnboProductCustomLogicService } from '../fnbo-product-custom-logic-service/fnbo-product-custom-logic.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDetailComponent {
  product: ProductModel;
  itemOptions: ItemModel[];

  //for the quantity
  quantity: any = 1;
  totalPoints: any = 0;
  maxPointsToShow: number = -1;
  showQuantity: boolean = true;

  //for the entire quantity and add to cart area
  showAddActions: boolean = true;
  showDenomination: boolean = true;
  showPoints: boolean = true;
  showMethodError: boolean = false;

  //selected Denomination and stores the value
  selectedDenomination: any = 'n/a';

  //default desciption length
  descriptionSumLength: number = 275;
  descriptionDefLength: number = 275;
  showMoreVal: boolean = false;
  showMoreText: string = "Show more";

  //default values
  hasPriceOptions: boolean = false;
  memberCostDenomination: string = "Points";
  startingMemberCost: string = "0";
  endingMemberCost: string = "0";

  //if the product is only view only
  viewOnlyMode: boolean = false;

  onlineOnly: boolean = false; //use to show the online only text
  cashbackOnly: boolean = false; // use for cashback
  accountInfo: AccountModel;
  canRedeem: boolean = true;

  //cash back functions 

  //use to filter the  cashback auto complete
  cashBackFromText: string = "";
  isCashBackAlreadyAdded: boolean = false;
  minimumCashBackAmount: number = 2500;
  minimumPointsToRedeem: number = 1000;
  isLessThanMinimumPoints: boolean = false;
  cashBackProduct: boolean = false;

  orderItemsBy: number = -1;


  //check to see if the specific magazine was added
  isMagazineAdded: boolean = false;

  //auto-redeem
  isAutoRedeem: boolean = false;

  //use to go back to shopping
  previousUrl: string = "/";

  //universal boolean
  isUniversal : boolean = false;

  subscriptions: Subscription[];

  @ViewChild(BankingInfoComponent) bankingInfoComponent: BankingInfoComponent

  /**
   *
   */
  constructor(private router: Router, private route: ActivatedRoute, public dialog: MatDialog,
    private accountService: AccountService, private cartService: CartService, private settingsService: SettingsService,
    private _fnboCustomLogicService: FnboProductCustomLogicService) {
    this.accountInfo = new AccountModel();
    this.subscriptions = [];
  }

  ngOnInit() {
    //getting the query params passed through
    //the route service ; this will not show in the 
    //url area

    let currentState$: Observable<any> = this.route.paramMap.pipe(map(() => window.history.state));
    currentState$.subscribe(x => {
      if (x) {

        //pervious link to navigate to
        if (x.previousUrl) {
          this.previousUrl = x.previousUrl;
        }
        if (x.productdetails) {

          this.product = x.productdetails;
          this.itemOptions = this.product.items;
          this.setup();
        }
      }
    });


    this.isCashBackAlreadyAdded = this.cartService.hasCashBackProduct();
  }

  //overall page setup
  async setup() {

    this.accountInfo = await firstValueFrom(this.accountService.accountInfo$);

    this.viewOnlyMode = (this.product.cashbackPaymentType == "VIEWONLY");

    this.isUniversal = UNIVERSALPROGRAMS.includes(this.accountService.accountInfo.programCode);
    
    if ( this.isUniversal|| !this.product.partialPayIndicator) {
      this.maxPointsToShow = (await firstValueFrom(this.accountService.accountInfoItem$)).points;
    }

    this.isCashBackAlreadyAdded = this.cartService.hasCashBackProduct();

    this.checkForCashBack();
    this.setupCost();
    this.checkForOnlineOnly();
    this.setupVisibleAreas();
    this._fnboCustomLogicService.applyCustomFCARules(this.product);

  }

  //setup the cost area for a product
  setupCost() {

    //filter if the product is cashback and program is cashback based on available points
    if (this.cashbackOnly && this.product.brand == 'FBO_Cashback') {
      let cashBackPointsAvailiable = Math.floor(this.accountInfo.points / 25) * 25;
      this.itemOptions = this.itemOptions.filter(x => x.memberCost <= cashBackPointsAvailiable);
      this.orderItemsBy = -1;
    }

    if (UNIVERSALPROGRAMS.includes(this.accountService.accountInfo.programCode)) {
      this.orderItemsBy = -1;
    }

    if (this.itemOptions && this.itemOptions.length > 0) {

      //getting the type of value for the cost
      this.memberCostDenomination = this.itemOptions[0].memberCostDenomination;
      ////starting cost
      this.startingMemberCost = this.itemOptions[0].memberCost.toString();
      //getting the last element of the items list
      this.endingMemberCost =
        this.itemOptions[this.itemOptions.length - 1].memberCost.toString();
      //if they are not the same, then we have options
      this.hasPriceOptions = this.endingMemberCost !== this.startingMemberCost;


      if (this.itemOptions.length > 1) {
        this.hasPriceOptions = true;

      } else if (this.itemOptions.length == 1) {
        //if there is one item; automatically set the denomination
        this.selectedDenomination = this.itemOptions[0];

        //calculating the totalpoints to display
        this.totalPoints = Number(this.selectedDenomination.memberCost) * this.quantity;
      }
    }
  }

  modifyAmount(amount) {

    if (amount < 0 && this.quantity == 1) {
      return
    }

    this.quantity = this.quantity + amount;

    //calculating the totalpoints to display
    this.totalPoints = Number(this.selectedDenomination.memberCost) * this.quantity;
  }

  //controls the description
  showMore() {

    this.showMoreVal = !this.showMoreVal;

    if (this.showMoreVal) {
      this.descriptionSumLength = this.product.description.length;
      this.showMoreText = "Show less";
    } else {
      this.descriptionSumLength = this.descriptionDefLength;
      this.showMoreText = "Show more";
    }
  }


  /**
   * checking for cashback only
   * 
   */
  checkForCashBack() {

    const programs = ['261', '281', '286'];
    let sub = this.settingsService.$programSettings.subscribe(
      settings => {

        if (settings) {

          let cashbackOnlySettings = settings.filter(d => d.key === SettingsOptions.CASHBACK_ONLY_PRGM.toString());

          if ((cashbackOnlySettings && cashbackOnlySettings.length > 0) || programs.includes(this.accountInfo.programCode)) {

            if ((Math.floor(this.accountInfo.points / 25) * 25) < this.minimumCashBackAmount) {
              this.isLessThanMinimumPoints = true;
              this.showAddActions = false;
            }

            this.cashbackOnly = true;


          }

        }
      });

    this.subscriptions.push(sub);

  }
  /**
   * use to display a simple online only text
   */
  checkForOnlineOnly() {

    //specific hard coded products that are online only
    const onlineOnlyProducts = ['Chrysler Collection Gift Card', 'FIAT Gift Card',
      'Dodge Life Gift Card', 'Ram Outfitter Gift Card', 'Jeep Gear Gift Card'];

    //programs that are online only
    const onlineOnlyPrograms = ['119', '267', '258', '249'];

    //checking if the product is online only
    if (onlineOnlyProducts.includes(this.product.name) && onlineOnlyPrograms.includes(this.accountInfo.programCode)) {
      this.onlineOnly = true;
    }
  }

  //setting up areas that should be visible per product
  setupVisibleAreas() {
    const productBrands = ['synapse', 'fbo_cashback'];

    if (productBrands.includes(this.product.brand.toLowerCase())) {
      this.showQuantity = false;
    } else {
      this.showQuantity = true;
    }

    if (productBrands[1] === this.product.brand.toLowerCase()) {
      this.cashBackProduct = true;
    }

    //if auto redeem the auto-redeem component will be visible
    this.isAutoRedeem = this.product.cashbackPaymentType == 'AUTOREDEEM' ? true : false;

    if (this.isAutoRedeem ||
      this.product.cashbackPaymentType == 'FCA-R' ||
      this.product.cashbackPaymentType == 'VIEWONLY') {

      this.showAddActions = false;
      this.showDenomination = false;
      this.showPoints = false;

    } else if (this.accountInfo.canRedeem == false) {
      this.showAddActions = false;
      this.showDenomination = false;
    }

    //if the account have less than the minimumPointsToRedeem 
    //user cannot redeem anything
    if (this.accountInfo.points < this.minimumPointsToRedeem) {
      this.canRedeem = false;
      this.showAddActions = false;
      this.showDenomination = false;
    }

    if (this.maxPointsToShow < (Number(this.startingMemberCost) || 0) && !this.product.partialPayIndicator) {
      this.canRedeem = false;
      this.showAddActions = false;
      this.showDenomination = false;
      this.minimumPointsToRedeem = Number(this.startingMemberCost) || this.minimumPointsToRedeem;
    }

  }

  /**
   * 
   * @param $event 
   * check for the denomination change
   */
  onChangeDenomination(item) {
    this.showMethodError = false;
    this.totalPoints = this.selectedDenomination.memberCost * this.quantity;
  }

  /**
   * Open a dialog to show the terms and condition for Redepemtion
   */

  showRedemptionTNC() {


    if (this.product) {

      //open dialog
      const dialogRef = this.dialog.open(TermsConditionsDialogComponent, {
        maxWidth: '760px',
        panelClass: 'fnbo-terms-condition-dialog',
        backdropClass: '',
        data: {
          products: [this.product],
          title: 'Redemption Terms & Conditions',
          showDefault: false
        }
      });


    }
  }

  /**
   * setting the cashbak value for th dropdown
   * @param value 
   * @returns 
   */
  setCashBackValue(value) {

    if (!value) {
      this.cashBackFromText = "";
      return false;
    }

    //getting the specific denomination base on the selected value
    this.selectedDenomination = this.itemOptions.find(x => x.memberCost
      === Number(value));

    if (this.selectedDenomination) {
      //calculating the totalpoints to display
      this.totalPoints = Number(this.selectedDenomination.memberCost) * this.quantity;

      this.cashBackFromText = this.selectedDenomination.description;

      //check if cash back was already added; only one allowed
      this.isCashBackAlreadyAdded = this.cartService.hasCashBackProduct();

      return true;
    }

    return false;
  }

  validateBeforeAdd() {

    //selected denomination
    if (!this.selectedDenomination || this.selectedDenomination == 'n/a') {
      this.showMethodError = true;
      return false;
    } else if (!this.selectedDenomination?.memberCost ||
      (this.itemOptions.findIndex(x => x.memberCost == this.selectedDenomination.memberCost) == -1)) {

      return false;
    }

    //cannot add two cashback products
    if (this.product.brand === ProductOptions.CASHBACK.toString() &&
      this.cartService.cashBackProductExist && this.cashbackOnly) {
      //cannot add two cashback items
      this.isCashBackAlreadyAdded = this.cartService.hasCashBackProduct();
      return false;
    }

    //TODO : MAGAZINE IS REMOVED; SO SHOULD I REMOVE THIS
    if (this.product.brand.toLowerCase() == 'synapse') {
      let result = this.cartService.isProductInCart(this.selectedDenomination.inventoryItemId);

      if (result) {
        //if the magazine was added then don't add another
        this.isMagazineAdded = true;
        return false;
      }
    }

    return true;

  }

  //add the items to the cart
  addToCart() {

    //validate form before adding
    if (!this.validateBeforeAdd()) {
      return;
    }
    //use the selectedDenomin

    let cartItem = new CartItemModel();
    cartItem.setCartItemModel(this.selectedDenomination, this.product);

    cartItem.quantity = this.quantity;
    cartItem.pointsExtended = this.quantity * this.selectedDenomination.memberCost;

    //if the product requires a custom input like bank info
    //we check for it in another component; if the product don't
    //need bank information it will not fail
    if (this.bankingInfoComponent.isValidForm()) {

      cartItem.bankAccountNumber = this.bankingInfoComponent.bankAccountNumber;
      cartItem.bankRoutingNumber = this.bankingInfoComponent.bankRoutingNumber;
      cartItem.recipientInfo = this.bankingInfoComponent.recipientInfo;
    } else {

      return;
    }

    if (cartItem.canPartialPay) {
      cartItem.partialPayCents = 0;
      cartItem.partialPayPoints = 0;
    }

    cartItem.actualCost = this.selectedDenomination.vendorCost;
    cartItem.faceValue = this.selectedDenomination.faceValue;

    this.cartService.addItem(cartItem);
  }

  //go back to the previous page
  goBack() {
    this.router.navigate([this.previousUrl]);
  }

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }

}