import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, throwError } from 'rxjs';
import { HttpWrapperService } from './http-wrapper.service';
import { HttpHeaders } from '@angular/common/http';
import { PaymentFormRequestModel, PaymentFormResponseModel } from '../models/payment';

@Injectable()
export class PaymentService {
    tokenResponse: PaymentFormResponseModel;
    token: string = "";

    // Rx Observable (Token change detection)
    private _tokenSource = new BehaviorSubject<string>(this.token);
    tokenInfoItem$ = this._tokenSource.asObservable();

    constructor(private http: HttpWrapperService) {
      
    }

    tokenInfoChange() {
        this._tokenSource.next(this.token);
        this.token = "";
    }

    resetToken() {
        this.token = "";
        this.tokenInfoChange();
    }
    // Public service methods

    getPaymentToken(data: PaymentFormRequestModel) {
       
        var headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json');
    
        this.http.post('api/payment/token', data, {headers: headers})
           .pipe(catchError(error => this.handleError(error)))
            .subscribe(
            result => {
                this.tokenResponse = result,
                    this.token = result.token
                this.onGetTokenSuccess()
            })
    }

    onGetTokenSuccess() {
        if (this.token !== undefined) {
            this.tokenInfoChange();
        }
    }

    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
    
          const body = error.json() || '';
    
          var err = "";
          body.then(er => {
            err = er.error || JSON.stringify(body);
          })
    
          errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
          errMsg = error.message ? error.message : error.toString();
        }
    
        return throwError(() => new Error(errMsg));
      }
}
