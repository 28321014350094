export class CustomError {
    level : Errorlevel;
    message : string;
    url : string;
}


export enum Errorlevel {
    ALL = 6,
    DEBUG = 5,
    INFO = 4,
    WARN = 3,
    ERROR = 2,
    FATAL = 1,
    OFF = 0
}