import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountModel } from 'src/core/models/account';
import { SettingsOptions } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { SettingsService } from 'src/core/services/settings.service';
import { UtilityService } from 'src/core/services/utility.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  subscriptions: Subscription[];
  accountInfo: AccountModel;
  showUniversalCustArea: boolean = false;
  showDefaultHighlightArea: boolean = false;

  constructor(private settings: SettingsService, private accountService: AccountService, private utilityService: UtilityService) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    let sub = this.accountService.accountInfo$.subscribe(accountInfo => {
      if (accountInfo) {

        this.accountInfo = accountInfo;
      
      }
    });

    this.manageHighlightsArea();

    this.subscriptions.push(sub);
  }


  manageHighlightsArea() {

    this.utilityService.addLoadingCallsCount(1);
    let sub = this.settings.$programSettings.subscribe(settings => {


      if (settings) {
        
        this.showUniversalCustArea = this.settings.getProgramSetting(SettingsOptions.CUSTOM_HIGHLIGHT_LAYOUT).toLowerCase()
          == "universal" ? true : false;

        this.showDefaultHighlightArea = !this.showUniversalCustArea;
      }

          //global loading
    this.utilityService.addCompletedLoadingCalls(1);
    });

    this.subscriptions.push(sub);
  }

  //check

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }

}
