<div *ngIf="cartItem && !showSummary" class="fnbo-cart-item">

    <div>
        <div class="row">

            <div class="col-lg-5 col-md-4"> <!-- product image area -->
                <div class="fnbo-product-img-area">

                    <img class="fnbo-single-product-card-img" mat-card-lg-image [src]="cartItem.imageUrl"
                        alt="Image of {{cartItem.name}}">
                </div>
                <!-- product image area ends -->
            </div>
            <div class="col-lg-7 col-md-8">
                <div class="fnbo-display-flex">

                    <p class="fnbo-cart-item-title" [innerHTML]="cartItem.name">

                    </p>
                    <button mat-icon-button class="fnbo-cart-delete-btn fnbo-flex-align-right"
                        (click)="removeFromCart()">
                        <mat-icon class="fnbo-primary-color">delete_forever</mat-icon>
                    </button>
                </div>
                <p class="fnbo-cart-total-points">
                    {{cartItem.pointsExtended.toString() | commaFormat}} points

                </p>

                <div>

                    <div class="fnbo-display-flex fnbo-product-cart-item-action">

                        <p class="fnbo-primary-color">
                            Quantity

                        </p>

                        <!-- quantity area  -->
                        <div  class="fnbo-cart-item-quantity fnbo-primary-border">
                            <div class="fnbo-cart-item-quantity-sub fnbo-primary-border" (click)="modifyAmount(-1)">
                                <mat-icon class="fnbo-primary-color" aria-hidden="false"
                                    aria-label="remove">remove</mat-icon>
                            </div>
                            <div class="fnbo-cart-item-quantity-amt fnbo-primary-border fnbo-primary-color">

                                {{cartItem.quantity}}
                            </div>

                            <div class="fnbo-cart-item-quantity-add fnbo-primary-border" (click)="modifyAmount(1)">
                                <mat-icon class="fnbo-primary-color" aria-hidden="false" aria-label="add">add</mat-icon>
                            </div>

                        </div>
                        <!-- quantity area  ends-->

                    </div>
                </div>

            </div>

        </div>

    </div>
</div>


<div *ngIf="cartItem && showSummary" class="fnbo-cart-item">
     <div class="row">
        <div class="col-lg-2 col-md-2 col-2">
            <div class="fnbo-cart-item-quantity-amt fnbo-primary-border">

                {{cartItem.quantity}}
            </div>
        </div>
        <div class="col-lg-8 col-md-8 col-8">
            <p class="fnbo-cart-item-title fnbo-space-below-0" [innerHTML]="cartItem.name">
            </p>
            <p *ngIf="cartItem.selectedShippingOptionDescription || shippingClasses" class="fnbo-text-secondary fnbo-space-below-0">
                {{cartItem.selectedShippingOptionDescription | shippingOption : shippingClasses : cartItem.shipmentChoicesId}}
            </p>
        </div>
        <div class="col-lg-2 col-md-2 col-2">
            <p class="fnbo-cart-total-points fnbo-text-right">
                {{cartItem.pointsExtended.toString() | commaFormat}}

            </p>
        </div>
    </div> 
</div>