<div class="fnbo-timeout-dialog modal-sm" role="document">
    <div class="modal-content">
    
        <div *ngIf="data && data.header" class="fnbo-timeout-dialog-header">

            <h1 class="fnbo-primary-color">{{data.header}}</h1>
        </div>
        <div *ngIf="data && data.message" class="fnbo-timeout-dialog-body fnbo-primary-border">
            <div class="fnbo-timeout-dialog-content fnbo-text">
                <p>{{data.message}}</p>
            </div>
        </div>
    </div>
</div>