import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProductModel } from 'src/core/models/product';

@Component({
  selector: 'universal-single-info',
  templateUrl: './uni-single-info.component.html',
  styleUrls: ['./uni-single-info.component.scss']
})
export class UniSingleInfoComponent {

  @Input()
  infImgSrc: string;
  @Input()
  infoImgLink: string;

  @Input()
  infoImgAlt :string = "Information Image";



  constructor() { }

  ngOnInit() {

  }


}
