import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ProductModel } from 'src/core/models/product';

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.scss']
})
export class SingleProductComponent {

  @Input()
  product: ProductModel;

  @Input()
  categoryName: string;

  categoryClass: string;

  
  //default values
  memberCostDenomination: string = "Points";
  startingMemberCost: string = "0";
  endingMemberCost: string = "0";
  hasPriceOptions: boolean = false;


  constructor(private router: Router) { }

  ngOnInit() {

    //setting custom class based on the category to modify the
    //display of the product
    if (this.categoryName && this.categoryName.length > 1) {

      this.categoryClass = "fnbo-" + this.categoryName.replace(" ", "-").toLowerCase();

    }

    this.setup();

  }

  
  setup() {
    
    if (this.product && this.product.items) {

      //getting the type of value for the cost
      this.memberCostDenomination = this.product.items[0].memberCostDenomination;
      ////starting cost
      this.startingMemberCost = this.product.items[0].memberCost.toString();
      //getting the last element of the items list
      this.endingMemberCost =
       this.product.items[this.product.items.length - 1].memberCost.toString();
      //if they are not the same, then we have options
      this.hasPriceOptions = this.endingMemberCost !== this.startingMemberCost;

    }
  }

  goToDetails() {

    this.router.navigate(['/product-details'], {
      state: { productdetails: this.product, previousUrl : this.router.url}
    });
  }

}
