<div class="fnbo-logout-dialog modal-sm" role="document">
    <div class="modal-content">
        <div class="fnbo-display-flex">
            <div class="fnbo-flex-align-right">
                <button id="fnbo-logout-dg-close" mat-icon-button class="" (click)="closeDialog()">
                    <mat-icon class="">close</mat-icon>
                </button>
            </div>
        </div>
        <div class="fnbo-logout-dialog-header">

            <h1 class="fnbo-primary-color">Confirm Logout</h1>
        </div>
        <div class="fnbo-logout-dialog-body fnbo-primary-border">
            <div class="fnbo-logout-dialog-content fnbo-text">
                <p>Are you sure you want to logout?</p>
            </div>
        </div>
        <div class="fnbo-logout-dialog-footer">
            <button class="fnbo-primary-bg" mat-button (click)="logout()">
                Yes </button>
            <button class="fnbo-primary-color " mat-button (click)="closeDialog()">
                No</button>
        </div>
    </div>
</div>