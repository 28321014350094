

import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountModel } from 'src/core/models/account';
import { CategoryModel } from 'src/core/models/category';
import { ProductModel } from 'src/core/models/product';
import { SettingsOptions } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { CatalogService } from 'src/core/services/catalog.service';
import { StyleService } from 'src/core/services/style.service';
import { UtilityService } from 'src/core/services/utility.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent {


  categoryCode: any = '';
  rootCategory: CategoryModel;

  products: ProductModel[];
  brands: Set<any> = new Set<any>();

  //current page fot pagination
  currentPage: number = 1;
  //max number of pages show in pagination
  pageLinksMaxSize: number = 6;
  itemsPerPage: number = 12;
  showFilter: boolean = false; //uses to show the filter
  showFilterText: string = "Show Filters"

  // filters
  selectedBrand: any = "any";
  selectedPoints: any = "any";
  sortBy: any = "startingAt-des";


  loading: boolean = true;
  loadingColor : any = "#000";


  subscriptions: Subscription[];
  accountInfo: AccountModel;

  constructor(private route: ActivatedRoute, private router: Router,
    private catalogService: CatalogService, private accountService: AccountService,
    private styleService: StyleService, private utilityService: UtilityService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    // Subscribe to account
    let subscriptionAccount = this.accountService.accountInfoItem$
      .subscribe(accountInfo => {
        if (accountInfo) {
          this.accountInfo = accountInfo;

          this.setup();

        }
      });

    this.subscriptions.push(subscriptionAccount);
  }


  setup() {
    /**
     * whenever the route change with the category code
     * this route subscribe picks up and refreshes the page. 
     * The initial subscribe value will hold the last route
    **/
    let paramSub = this.route.paramMap.subscribe((param: any) => {

      //use to force the content to the top when 
      //the route params changes
     const matScrollContent = document.querySelector("mat-drawer-content");
     if(matScrollContent){
       matScrollContent.scrollTop = 0;
     }

      this.loading = true;
      this.products = [];

      this.categoryCode = this.route.snapshot.paramMap.get("categoryCode");



      this.brands = new Set();

      if (this.categoryCode == "highlights") {

        this.loadHighlightedProducts();

        this.rootCategory = new CategoryModel();
        this.rootCategory.name = "Highlights";

      } else {

        this.getRootCategory();
        this.loadProducts();
      }
      // reset currentpage whenever the route changes

      this.currentPage = 1;

      this.utilityService.filterButton$.next(false);

     // this.loading = false;

    });

    this.subscriptions.push(paramSub);

    this.settingStyles();

    //listening to the filter button click
    let utilitySub = this.utilityService.filterButton$.subscribe(x => {
      this.showFilter = x;

      this.setFilterButtonText(this.showFilter);
    });

    this.subscriptions.push(utilitySub)
  }

  // only use after the first root category check or retrival
  //or ensure the the root catergoies is not empty
  getRootCategory() {

    if (this.categoryCode) {

      if (this.catalogService.categories) {
        this.rootCategory = this.catalogService.categories.find(x => x.code == this.categoryCode)!;

        if (this.rootCategory == undefined) {
          this.router.navigate(["/"]);
        }

      }
    }
  }



  //load products
  loadProducts() {


    if (this.rootCategory == undefined) {
      this.router.navigate(["/"]);

    } else {

      //getting the products
      let productSubscription = this.catalogService
        .getActiveProducts(this.rootCategory.id).subscribe(products => {

          this.products = products;
          this.clearFilter();
          this.loadFilters();
          this.loading = false;
        });

      this.subscriptions.push(productSubscription);
    }

  }

  loadHighlightedProducts() {
    let productSubscription = this.catalogService.getProductHighlights().subscribe(products => {
      this.products = products;
      this.clearFilter();
      this.loadFilters();

    });

    this.subscriptions.push(productSubscription);

  }

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }


  //loading the filter area
  //by going through the products that were loaded
  loadFilters() {
    this.loading = true;
    this.products.forEach(product => {
      if (product) {
        this.brands.add(product.brand);
      }

    });
    this.loading = false;
  }


  clearFilter() {
    this.selectedBrand = "any";
    this.selectedPoints = "any";
    this.sortBy = "startingAt-des";
  }

  //toggling the filter area
  //might be remove if the button on this page itself is not used
  toggleFilter() {
    this.showFilter = !this.showFilter;

    this.setFilterButtonText(this.showFilter);

    //letting other components know that the filter has open/close
    this.utilityService.filterButton$.next(this.showFilter);
  }

  setFilterButtonText(val) {
    if (val) {
      this.showFilterText = "Hide Filters"
    } else {
      this.showFilterText = "Show Filters"
    }
  }


  settingStyles() {
    //setting classes dynamically to fit theme/ color scheme
    this.styleService.setColorStyle("#fnbo-products-pagination  .ngx-pagination .current", "background", SettingsOptions.COLOR_PRIMARY);
    this.styleService.setColorStyle(".fnbo-products-filter-items * .mdc-text-field", "border-color", SettingsOptions.COLOR_PRIMARY);
    this.styleService.setColorStyle(".fnbo-products-filter-items * .mdc-floating-label", "color", SettingsOptions.COLOR_PRIMARY);
    this.styleService.setColorStyle(".fnbo-products-filter-items * .mat-mdc-select-value", "color", SettingsOptions.COLOR_PRIMARY);
    this.styleService.setColorStyle(".fnbo-products-filter-items * .mat-mdc-select-arrow", "color", SettingsOptions.COLOR_PRIMARY);
   
    this.loadingColor = this.styleService.primaryColor;
  }

  pointsFilterChange(e){
    this.currentPage =1;
  }

  pageChanged(page){
    this.currentPage  = page;

    //use to traverse user to the top of the screen
    const matScrollContent = document.querySelector("mat-drawer-content");
    if(matScrollContent){
      matScrollContent.scrollTop = 0;
    }

  }
}
