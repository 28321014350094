<div id="fnbo-account-info-sect" >
    <div id="fnbo-account-username" class="fnbo-primary-border mobile-hidden"  (click)="showLogoutConfirmation()">
        <span class="fnbo-primary-color" >{{username}}</span>
        <span>Logout</span>
    </div>

    <div>
        <button id="fnbo-shopping-cart" mat-icon-button class="" (click)="goToCart()">
            <mat-icon matBadge="{{cartItemCount}}" [matBadgeHidden]="hideCartBadge"  class="fnbo-primary-color">shopping_cart</mat-icon>
          
          </button>
       
    </div>

    <div id="fnbo-account-points-info" class="fnbo-primary-color">
        <span>My Points</span>
        <span id="fnbo-account-points">{{points | commaFormat}}</span>
    </div>
</div>
