import { Component } from '@angular/core';
import { UtilityService } from 'src/core/services/utility.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {

  /**
   *
   */
  constructor(private  utilityService: UtilityService) {
    
  }

  ngOnInit(){
    this.utilityService.hideGlobalLoadingScreen();
  }

}
