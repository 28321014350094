<!-- if categoryClass is defined then it will show -->
<div *ngIf="infImgSrc" class="fnbo-uni-single-info">

    <a *ngIf="infoImgLink && infoImgLink.length > 0" href="{{infoImgLink}}" target="_blank">
        <ng-container *ngTemplateOutlet="infoImTemp;"></ng-container>
    </a>

    <ng-container *ngIf="!infoImgLink || infoImgLink.length < 1">
        <ng-container *ngTemplateOutlet="infoImTemp;"></ng-container>
    </ng-container>

</div>

<ng-template #infoImTemp>
    <div class="fnbo-uni-single-info-img-area">
        <img class="fnbo-uni-single-info-img" mat-card-md-image [src]="infImgSrc" alt="{{infoImgAlt}}">
    </div>
</ng-template>