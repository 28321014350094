<div id="fnbo-account-username" class="fnbo-primary-border">
  <span class="fnbo-primary-color">Welcome, {{username}}</span>

</div>

<span class="fnbo-menu-horizontal-line fnbo-primary-bg"></span>
<!-- side menu -->

<mat-accordion>
  <div class="fnbo-side-menu-main-links">
    <a class="" mat-button routerLink="/">Home</a>
  </div>
  <ng-container *ngFor="let cat of menuCats">
<ng-container *ngIf="cat.rootCat.id != NONVISIBLE ">


    <mat-expansion-panel *ngIf="cat.children.length > 1" class="fnbo-side-menu-expanel" (opened)="panelOpenState = true"
      (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title class="fnbo-side-menu-main-links">
          {{cat.rootCat.name}}
        </mat-panel-title>

      </mat-expansion-panel-header>
      
      <a *ngFor="let children of cat.children" class="fnbo-side-menu-sub-links" mat-button
        routerLink="products/{{children.code}}">{{children.name}}</a>


      <a class="fnbo-side-menu-sub-links" mat-button routerLink="products/{{cat.rootCat.code}}">View All</a>


    </mat-expansion-panel>


    <div *ngIf="cat.children.length < 1" class="fnbo-side-menu-main-links">
      <ng-container *ngIf="cat.rootCat.id == TRAVELID && cat.rootCat.id != NONVISIBLE ">

        <a mat-button (click)="gotoTravel()">{{cat.rootCat.name}}</a>
      </ng-container>

      <ng-container *ngIf="cat.rootCat.id !== TRAVELID && cat.rootCat.id != NONVISIBLE ">
        <a class="" mat-button routerLink="products/{{cat.rootCat.code}}"> {{cat.rootCat.name}}</a>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
</mat-accordion>

<span class="fnbo-menu-horizontal-line fnbo-primary-bg"></span>
<!-- secondary menu -->
<div class="fnbo-primary-color fnbo-side-menu-sec-links">
  <a mat-button routerLink="faq"> FAQ </a>
  <a mat-button routerLink="contact-us">Contact Us</a>
  <a (click)="logout()" mat-button>Logout</a>
</div>
