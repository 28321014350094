import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Subscription, debounceTime } from 'rxjs';
import { AccountModel } from 'src/core/models/account';
import { OrderInfoModel } from 'src/core/models/checkout';
import { FormsPatterns } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { OrderService } from 'src/core/services/order.service';
import { UtilityService } from 'src/core/services/utility.service';

@Component({
  selector: 'app-order-address',
  templateUrl: './order-address.component.html',
  styleUrls: ['./order-address.component.scss']
})
export class OrderAddressComponent {


  accountInfo: AccountModel;
  orderInfo: OrderInfoModel;
  subscriptions: Subscription[];
  orderAddressForm: FormGroup;

  billingSelected: boolean = true;
  @Input() showForm: boolean = true;
  @Input() disableForm: boolean = false;
  @Input() setDisabledAddressSummary: boolean = false;



  //from older application
  USCOUNTRY = ['USA', 'US', 'UNITED STATES', 'UNITED STATES OF AMERICA'];

  /**
   *
   */
  constructor(private accountService: AccountService, public dialog: MatDialog,
    private utilityService: UtilityService, private orderService: OrderService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    this.orderAddressForm = new FormGroup({
      fullname: new FormControl('', [Validators.required, Validators.minLength(1)]),
      company: new FormControl('', [Validators.minLength(1)]),
      phoneNumber: new FormControl({ value: "", disabled: this.disableForm }, [Validators.required, Validators.minLength(7), Validators.pattern(FormsPatterns.PHONENUMBER.toString())]),
      altNumber: new FormControl('', [Validators.minLength(7), Validators.pattern(FormsPatterns.PHONENUMBER.toString())]),
      email: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50), Validators.email]),
      city: new FormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      street: new FormControl('', [Validators.required, Validators.minLength(1)]),
      number: new FormControl('', [Validators.minLength(1)]),
      state: new FormControl('', [Validators.required, Validators.minLength(2), Validators.maxLength(2) , Validators.pattern(FormsPatterns.STATE.toString())]),
      zipCode: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern(FormsPatterns.ZIPCODE.toString())]),
      country: new FormControl({ value: "", disabled: false }, [Validators.required, Validators.minLength(1)])
    });

    if (!this.setDisabledAddressSummary) {
      let sub = this.accountService.accountInfoItem$.subscribe(
        account => {
          if (account) {

            this.accountInfo = account;

            if (this.USCOUNTRY.includes(this.accountInfo.shippingAddress.country.toUpperCase())) {
              if(!this.accountService.orderInfo){

                this.setupDefaultForm();
              }else{
                this.setupFormWithOrder();
              }
            }
          }
        }
      );
      this.subscriptions.push(sub);
    } else {
      //setting up the address form with the order address summary
      this.setupFormWithOrder();
      
    }

    if (this.disableForm) {
      this.orderAddressForm.disable();
    }

  }


  /**
   * sets up the form with default account information
   */
  private setupDefaultForm() {

    this.orderAddressForm.controls.fullname.setValue(this.accountInfo.firstName + ' ' + this.accountInfo.lastName );
    this.orderAddressForm.controls.email.setValue(this.accountInfo.email);
    this.orderAddressForm.controls.city.setValue(this.accountInfo.shippingAddress.city || '');
    this.orderAddressForm.controls.state.setValue(this.accountInfo.shippingAddress.state || '');
    this.orderAddressForm.controls.street.setValue(this.accountInfo.shippingAddress.street || '');
    this.orderAddressForm.controls.country.setValue(this.accountInfo.shippingAddress.country || '');
    this.orderAddressForm.controls.zipCode.setValue(this.utilityService.formatZipCode(this.accountInfo.shippingAddress.zipCode) || '');

    // US countries are disabled
    //from older application
    this.orderAddressForm.controls.country.disable();

    //formatting the phone numbers
    this.orderAddressForm.controls.phoneNumber.setValue
      (this.utilityService.formatPhoneNumber(this.accountInfo.phone) || '');
    this.orderAddressForm.controls.altNumber.setValue
      (this.utilityService.formatPhoneNumber(this.accountInfo.alternatePhone) || '');



    //modifying phone number on change
    let inputSub = this.orderAddressForm.controls.phoneNumber.valueChanges.pipe(debounceTime(500))
      .subscribe(number => {
        if (number) {
          this.orderAddressForm.controls.phoneNumber.setValue(this.utilityService.formatPhoneNumber(number));

        }
      });

    this.subscriptions.push(inputSub);

    //modifying alt phone number on change
    inputSub = this.orderAddressForm.controls.altNumber.valueChanges.pipe(debounceTime(500))
      .subscribe(number => {
        if (number) {
          this.orderAddressForm.controls.altNumber.setValue(this.utilityService.formatPhoneNumber(number));

        }
      });

    this.subscriptions.push(inputSub);

    inputSub = this.orderAddressForm.controls.zipCode.valueChanges.pipe(debounceTime(500))
      .subscribe(number => {
        if (number) {
          this.orderAddressForm.controls.zipCode.setValue(this.utilityService.formatZipCode(number));

        }
      });

    this.subscriptions.push(inputSub);

   
  }
//set up form with order inforamtion
  private setupFormWithOrder() {
    if (this.accountService.orderInfo) {


      this.orderAddressForm.controls.fullname.setValue(this.accountService.orderInfo.fullName || '');
      this.orderAddressForm.controls.email.setValue(this.accountService.orderInfo.emailAddress || '');
      this.orderAddressForm.controls.city.setValue(this.accountService.orderInfo.shippingCity || '');
      this.orderAddressForm.controls.state.setValue(this.accountService.orderInfo.shippingState || '');
      this.orderAddressForm.controls.street.setValue(this.accountService.orderInfo.shippingAddress1 || '');
      this.orderAddressForm.controls.number.setValue(this.accountService.orderInfo.shippingAddress2 || '');
      this.orderAddressForm.controls.country.setValue(this.accountService.orderInfo.shippingCountry || '');
      this.orderAddressForm.controls.zipCode.setValue(this.utilityService.formatZipCode(this.accountService.orderInfo.shippingPostalCode) || '');

      // US countries are disabled
      //from older application
      this.orderAddressForm.controls.country.disable();

      let phone = this.accountService.orderInfo.phoneAreaCode + this.accountService.orderInfo.phonePrefix +
        this.accountService.orderInfo.phoneLine;

      //formatting the phone numbers
      this.orderAddressForm.controls.phoneNumber.setValue
        (this.utilityService.formatPhoneNumber(phone) || '');


      phone = this.accountService.orderInfo.phoneAltAreaCode + this.accountService.orderInfo.phoneAltPrefix +
        this.accountService.orderInfo.phoneAltLine;

      this.orderAddressForm.controls.altNumber.setValue
        (this.utilityService.formatPhoneNumber(phone) || '');
    }
  }

  //set up the order model
  setupAddressModel() {
    if (this.accountInfo && this.orderAddressForm) {

      this.orderInfo = new OrderInfoModel();
      //seting basic information
      this.orderInfo.fullName = this.orderAddressForm.controls.fullname.getRawValue() ;
    
      this.orderInfo.paymentFirstName = this.accountService.accountInfo.firstName || '';
      this.orderInfo.paymentLastName = this.accountService.accountInfo.lastName || '';
      this.orderInfo.emailAddress = this.orderAddressForm.controls.email.getRawValue() || '';
      this.orderInfo.companyName = this.orderAddressForm.controls.company.getRawValue() || '';


      //setting phone number
      let phone = this.utilityService.stripPhonenumber(this.orderAddressForm.controls.phoneNumber.getRawValue());
      this.orderInfo.phoneAreaCode = phone.substring(0, 3);
      this.orderInfo.phonePrefix = phone.substring(3, 6);
      this.orderInfo.phoneLine = phone.substring(6);

      phone = this.utilityService.stripPhonenumber(this.orderAddressForm.controls.altNumber.getRawValue());

      this.orderInfo.phoneAltAreaCode = phone.substring(0, 3);
      this.orderInfo.phoneAltPrefix = phone.substring(3, 6);
      this.orderInfo.phoneAltLine = phone.substring(6);

      //end setting phone numbers

      //shipping address from input from the form 
      this.orderInfo.shippingAddress1 = this.orderAddressForm.controls.street.getRawValue() || '';
      this.orderInfo.shippingAddress2 = this.orderAddressForm.controls.number.getRawValue() || '';
      this.orderInfo.shippingCity = this.orderAddressForm.controls.city.getRawValue() || '';
      this.orderInfo.shippingState = this.orderAddressForm.controls.state.getRawValue() || '';
      this.orderInfo.shippingCountry = this.orderAddressForm.controls.country.getRawValue() || '';
      this.orderInfo.shippingPostalCode = this.orderAddressForm.controls.zipCode.getRawValue() || '';


      //billing address set to the account address if it is they are the same
      if (this.billingSelected) {

        this.orderInfo.billingAddress1 = this.orderInfo.shippingAddress1;
        this.orderInfo.billingAddress2 = this.orderInfo.shippingAddress2;
        this.orderInfo.billingCity =  this.orderInfo.shippingCity;
        this.orderInfo.billingState = this.orderInfo.shippingState;
        this.orderInfo.billingCountry =  this.orderInfo.shippingCountry;
        this.orderInfo.billingPostalCode = this.orderInfo.shippingPostalCode;
      } else {
        this.orderInfo.paymentSameAddress = false;
        this.orderInfo.billingAddress1 = '';
        this.orderInfo.billingAddress2 = '';
        this.orderInfo.billingCity = '';
        this.orderInfo.billingState = '';
        this.orderInfo.billingPostalCode = '';
        this.orderInfo.billingCountry = '';
      }

    }
  }


  //submits the contact us request
  saveForm(): boolean {

    this.orderAddressForm.controls.country.enable();

    if (!this.orderAddressForm) {
      return false;
    }

    Object.keys(this.orderAddressForm.controls).forEach(key => {
      if (key) {
        this.orderAddressForm.get(key)!.markAsDirty();
      }
    });

    if (this.orderAddressForm.valid) {

      this.setupAddressModel();

      if (this.orderInfo) {
        this.orderService.orderInfo = this.orderInfo;
        this.accountService.orderInfo = this.orderInfo;

        if (this.orderInfo) {

          sessionStorage.setItem('info', JSON.stringify(this.orderInfo));
          sessionStorage.setItem('orderInfo', JSON.stringify(this.orderInfo));
        }

        if (this.accountService.accountInfo)
          sessionStorage.setItem('accountInfo', JSON.stringify(this.accountService.accountInfo));

        return true;
      }

    }

    this.orderAddressForm.controls.country.disable();

    return false;

  }


  //get the controls
  get orderAddressFormControls() {
    return this.orderAddressForm.controls;
  }


  billingAddiSelectChange(value) {
    this.billingSelected = value;
  }

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }
}
