//Manages FAQ service calls
import { Injectable } from "@angular/core";
import { Observable, lastValueFrom, throwError } from "rxjs";
import { FaqCategory } from "../models/faq";
import { HttpWrapperService } from "./http-wrapper.service";


@Injectable()
export class FAQService {

    
    constructor(private http: HttpWrapperService) { }


    //get a list of Frequently Asked Question Category topics.
    getFAQs(): Observable<FaqCategory[]> {
        return this.http.get('api/FrequentlyAskedQuestions');

    }

    //return the HTML text as a string of the termsAndConditions html page in the assets folder.
    getDefaultTaCs(): Observable<any>{
        
        return this.http.get('app/assets/tandc/DefaultTermsAndConditions.html', {responseType: "text"});
    }

    //return the HTML text as a string of the termsAndConditions html page in the assets folder.
    async getTaCsFile(fileName : string): Promise<Observable<any>>{

        let filePath = `app/assets/tandc/${fileName}`;

        let results = await lastValueFrom(this.http.get(filePath, {responseType: "text"}));

        if(!results){

            results = await lastValueFrom(this.getDefaultTaCs());
        }

        return results;
    }


    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {

            const body = error.json() || '';

            var err = "";
            body.then(er => {
                err = er.error || JSON.stringify(body);
            })

            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return throwError(() => new Error(errMsg));
    }

}