import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatBadgeModule } from "@angular/material/badge";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatCardModule } from "@angular/material/card";
import { MatToolbarModule } from "@angular/material/toolbar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { ProductsComponent } from "./products.component";
import { SharedModule } from "src/core/shared.module";
import { NgxPaginationModule } from "ngx-pagination";
import {MatSelectModule} from '@angular/material/select';
import { ProductDetailComponent } from './product-detail/product-detail.component'; 
import {MatGridListModule} from '@angular/material/grid-list';
import { MatDialogModule } from "@angular/material/dialog";
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { AutoRedeemComponent } from './auto-redeem/auto-redeem.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { BankingInfoComponent } from './custom-reward/custom-reward.component';
import { FcaRedeemComponent } from './fca-redeem/fca-redeem.component'; 
import {MatTooltipModule} from '@angular/material/tooltip'; 
@NgModule({
    declarations: [
        ProductsComponent,
        ProductDetailComponent,
        AutoRedeemComponent,
        BankingInfoComponent,
        FcaRedeemComponent,
        
    ],
    imports: [
        CommonModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatIconModule,
        MatToolbarModule,
        MatButtonModule,
        MatListModule,
        MatBadgeModule,
        MatCardModule,
        SharedModule,
        NgxPaginationModule,
        MatSelectModule,
        MatDialogModule,
        MatGridListModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTooltipModule
    ],
    exports: [
       
    ]
})
export class ProductsModule { }
