import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CategoryModel, MenuCategory } from 'src/core/models/category';
import { SettingsOptions } from 'src/core/models/settings';
import { CatalogService } from 'src/core/services/catalog.service';
import { SettingsService } from 'src/core/services/settings.service';
import { SideNavService } from 'src/core/services/side-nav.service';
import { SearchDialogComponent } from '../search-dialog/search-dialog.component';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/core/services/utility.service';
import { AccountService } from 'src/core/services/account.service';

@Component({
  selector: 'app-top-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.scss']
})
export class MenuBarComponent implements OnInit {

  largeLogoUrl: any = "";
  smallLogoUrl: any = "";
  rootCategories: CategoryModel[];
  prevSearchQuery: string;

  menuCats: MenuCategory[];
  subscriptions: Subscription[];

  showFiller = false;
  sideNavOpen: any = false;

  TRAVELID = 550000000;
  NONVISIBLE = 1;

  constructor(private settings: SettingsService, public dialog: MatDialog,
    private catalogService: CatalogService, private sidenav: SideNavService,
    private router: Router, private utilityService: UtilityService, private accountService: AccountService) {
    this.subscriptions = [];
  }

  ngOnInit(): void{

    this.setup();
    this.sideNavOpen = false;

  }


  setup(): void {

    let sub = this.settings.$programSettings.subscribe(programSettings => {
      this.utilityService.addLoadingCallsCount(1);
      this.setLogos(programSettings);

      //global loading
      this.utilityService.addCompletedLoadingCalls(1);
    });

    this.subscriptions.push(sub);

    //getting the categories for the menu 
    sub = this.catalogService.getMenuCats$().subscribe(menuCats => {

      //global loading
      this.utilityService.addLoadingCallsCount(1);
      if (menuCats) {

        this.menuCats = menuCats.sort((a, b) => {
          return (a.rootCat.displayOrder > b.rootCat.displayOrder) ? 1 : -1;
        });

        //global loading
        this.utilityService.addCompletedLoadingCalls(1);
      }

    });

    this.subscriptions.push(sub);

    sub = this.router.events.subscribe((event) => {

      this.prevSearchQuery = "";
      this.dialog.closeAll();
    });


    this.subscriptions.push(sub);
    //after subscribing to the get the categories
    //getCategories() is called to populate the values
    this.catalogService.getCategories();
  }

  /**
   *  Setting the logos
   * @param settings 
   */
  setLogos(settings) {


    if (settings) {

      //getting the logos
      let largeLogo = settings.find(setting => setting.key === SettingsOptions.LARGE_LOGO.toString());
      let smallLogo = settings.find(setting => setting.key === SettingsOptions.SMALL_LOGO.toString());

      if (largeLogo) {
        this.largeLogoUrl = largeLogo.value;
      }

      if (smallLogo) {
        this.smallLogoUrl = smallLogo.value;
      }

      //if there isn't neither of the logos in the settings
      if (!smallLogo) {
        this.smallLogoUrl = "https://www.card.fnbo.com/content/dam/partner/logos/fnbo-simple-black.svg";
      }
      //if there isn't neither of the logos in the settings
      if (!largeLogo) {
        this.largeLogoUrl = "https://www.card.fnbo.com/content/dam/partner/logos/fnbo-simple-black.svg";
      }

    }
  }

  toggleSideNav() {
    this.sidenav.toggle();
    this.sideNavOpen = !this.sideNavOpen;
  }

  openSearchDialog() {
    //open dialog
    const dialogRef = this.dialog.open(SearchDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'fnbo-search-dialog',
      backdropClass: '',
      data: {
        searchQuery: this.prevSearchQuery
      }
    });


    let sub = dialogRef.afterClosed().subscribe(result => {

      this.prevSearchQuery = result;
    });

    this.subscriptions.push(sub);
  }

  gotoTravel() {
    this.accountService.navigateToTravel();
  }


  setDefaultImg(e) {
    this.largeLogoUrl = this.smallLogoUrl = "https://www.card.fnbo.com/content/dam/partner/logos/fnbo-simple-black.svg";
  }

  ngOnDestroy() {

    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }

}
