import { Component, OnInit } from '@angular/core';
import { SettingsOptions } from 'src/core/models/settings';
import { SettingsService } from 'src/core/services/settings.service';

@Component({
  selector: 'app-top-alert-msg',
  templateUrl: './top-alert-msg.component.html',
  styleUrls: ['./top-alert-msg.component.scss']
})
export class TopAlertMSGComponent implements OnInit {

  alertMessage: any = "Testing  <b>100</b>";

  showAlertMessage: any = false; //use to show the alert message

  constructor(private settings: SettingsService) { }

  ngOnInit(): void {

    //setup alert message area
    this.setup();

  }


  closeAlertMessage() {
    this.showAlertMessage = false;
  }

  //setup for banner
  setup() {
    this.showAlertMessage = false;

    //getting the program settings
    this.settings.$programSettings.subscribe(settings => {

      if (settings) {

        let alertMsg = settings.find(setting => setting.key === SettingsOptions.ALERT_MSG.toString());

        //setting up alert message
        if (alertMsg) {

         if (this.settings.isSettingActive(alertMsg)) {
          
            this.alertMessage = alertMsg.value;
            this.showAlertMessage = true;

          } else {

            this.showAlertMessage = false;

          }

        }
      }
    });

  }
}
