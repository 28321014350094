//basic service that acts as a middle man for the components/pages
import { Injectable } from "@angular/core";
import { OrderInfoModel } from "../models/checkout";
import { BehaviorSubject, catchError, throwError } from "rxjs";
import { OrderHistoryModel } from "../models/order";
import { HttpWrapperService } from "./http-wrapper.service";
import { Router } from "@angular/router";

@Injectable({
    providedIn : 'root'
})

export class OrderService{
    orders: OrderHistoryModel[];
    orderInfo : OrderInfoModel;

    // Rx Observable (Category change detection)
    private _ordersSource = new BehaviorSubject<OrderHistoryModel[]>(undefined!);
    ordersInfoItem$ = this._ordersSource.asObservable();

    constructor(private http: HttpWrapperService, private router: Router) {
    
    }

    // Public service methods
    getOrders() {
        this.http.get('api/redemption/ordershistory')
        .pipe(catchError(error => this.handleError(error)))
            .subscribe(
            ords => {
                this.orders = ords;
                this._ordersSource.next(this.orders);
                
            });
    }

    

    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
    
          const body = error.json() || '';
    
          var err = "";
          body.then(er => {
            err = er.error || JSON.stringify(body);
          })
    
          errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
          errMsg = error.message ? error.message : error.toString();
        }
    
        return throwError(() => new Error(errMsg));
      }
}