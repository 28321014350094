import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/core/services/account.service';
import { SettingsService } from 'src/core/services/settings.service';
import { StyleService } from 'src/core/services/style.service';

@Component({
  selector: 'app-goodbye',
  templateUrl: './goodbye.component.html',
  styleUrls: ['./goodbye.component.scss']
})
export class GoodbyeComponent {

  /**
   *
   */
  constructor(private accountService : AccountService, private router : Router,
    private settingService : SettingsService, private styleService : StyleService) {
    
    
  }

  ngOnInit(){
   
    this.logout();
  }


  logout(){
    sessionStorage.clear();
  }
}
