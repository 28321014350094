import { Component, HostListener } from '@angular/core';
import { Subscription, firstValueFrom } from 'rxjs';
import { AccountModel } from 'src/core/models/account';
import { ProductModel } from 'src/core/models/product';
import { SettingsOptions, StyleSettings } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { CatalogService } from 'src/core/services/catalog.service';
import { SettingsService } from 'src/core/services/settings.service';
import { UtilityService } from 'src/core/services/utility.service';

@Component({
  selector: 'app-universal-highlights',
  templateUrl: './universal-highlights-area.component.html',
  styleUrls: ['./universal-highlights-area.component.scss']
})
export class UniversalHighlightsComponent {

  infoImages: { img: string, link: string }[];
  products: ProductModel[];
  subscriptions: Subscription[];
  loading: boolean = false;
  accountInfo: AccountModel;



  constructor(private utilityService: UtilityService, private settings: SettingsService,
    private catalogService: CatalogService, private accountService: AccountService) {
    this.subscriptions = [];

  }


  ngOnInit() {
    this.setup();
  }

  async setup() {

    this.utilityService.addLoadingCallsCount(1);
    this.accountInfo = await firstValueFrom(this.accountService.accountInfoItem$);
    this.loadProducts();
    this.getInfoTilesImgs();

    //global loading
    this.utilityService.addCompletedLoadingCalls(1);

  }

  //load products
  async loadProducts() {
    //getting the products
    this.products = await firstValueFrom(this.catalogService.getProductHighlights());

  }



  getInfoTilesImgs() {
    this.infoImages = [];

    const infoTilesKeys = [SettingsOptions.HIGHLIGHT_INFO_IMG, SettingsOptions.HIGHLIGHT_INFO_IMG2,
    SettingsOptions.HIGHLIGHT_INFO_IMG3, SettingsOptions.HIGHLIGHT_INFO_IMG4]
   
    const infoTileLinksKeys = [SettingsOptions.HIGHLIGHT_INFO_LINK, SettingsOptions.HIGHLIGHT_INFO_LINK2,
    SettingsOptions.HIGHLIGHT_INFO_LINK3, SettingsOptions.HIGHLIGHT_INFO_LINK4]

    this.settings.$programSettings.subscribe(settings => {

      if (settings) {

        for (let i = 0; i < infoTilesKeys.length; i++) {
          let infoTile = this.settings.getProgramSetting(infoTilesKeys[i]);
          let infoTileLink = this.settings.getProgramSetting(infoTileLinksKeys[i]) || "";

          if (infoTile) {
            this.infoImages.push({img : infoTile , link : infoTileLink});
          }
        }
      }
    });

  }

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }
}
