<!-- if categoryClass is defined then it will show -->
<mat-card *ngIf="product" [ngClass]="categoryClass" class="fnbo-single-product-card fnbo-secondary-border" (click)="goToDetails()">

    <div class="fnbo-single-prod-img-area">
        <img class="fnbo-single-product-card-img" mat-card-md-image [src]="product.largeImage" alt="Image of {{product.name}}">
    </div>
    <mat-card-content class="fnbo-sp-description">
        <p class="fnbo-single-product-card-title" [innerHTML]="product.name"></p>
    </mat-card-content>
    <mat-card-actions class="fnbo-single-product-card-actions">
        <div>

            <p class="fnbo-single-product-card-price" *ngIf="startingMemberCost !==  '0'">
                <span>

                    {{startingMemberCost | commaFormat}}
                </span>
                <ng-container *ngIf="hasPriceOptions">

                    <span> - </span>
                    <span> {{endingMemberCost| commaFormat}} </span>


                </ng-container>

                <span>
                    {{memberCostDenomination}}
                </span>

            </p>
            <button class="fnbo-primary-border fnbo-primary-color" mat-button (click)="goToDetails()">
                <mat-icon aria-hidden="false" aria-label="shopping cart">shopping_cart</mat-icon>

                Add to cart</button>

        </div>
    </mat-card-actions>
</mat-card>