import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AccountModel } from 'src/core/models/account';
import { ProductModel } from 'src/core/models/product';
import { AccountService } from 'src/core/services/account.service';
import { CatalogService } from 'src/core/services/catalog.service';
import { UtilityService } from 'src/core/services/utility.service';

@Component({
  selector: 'app-product-highlights',
  templateUrl: './product-highlights.component.html',
  styleUrls: ['./product-highlights.component.scss']
})
export class ProductHighlightsComponent {

  products: ProductModel[];
  subscriptions: Subscription[];
  loading: boolean = false;
  accountInfo: AccountModel;

  initialColumns: string;

  MAX_COL_PER_ITEM = 4; 

  constructor(private utilityService: UtilityService,
    private catalogService: CatalogService, private accountService: AccountService) {
    this.subscriptions = [];
  }


  ngOnInit() {

    this.setup();

  }

  setup() {

    // Subscribe to account
    let subscriptionAccount = this.accountService.accountInfoItem$
      .subscribe(accountInfo => {
        if (accountInfo) {

          this.accountInfo = accountInfo;

          this.loadProducts();

        }
      });

    this.subscriptions.push(subscriptionAccount);
  }




  //load products
  loadProducts() {

    //getting the products

    this.utilityService.addLoadingCallsCount(1);
    let productSubscription = this.catalogService.getProductHighlights().subscribe(products => {
      if (products) {
        this.products = products;

        if (this.products.length < this.MAX_COL_PER_ITEM) {
          this.initialColumns = "auto ".repeat(this.products.length);
        } 
        else {
          this.initialColumns = "auto ".repeat(this.MAX_COL_PER_ITEM);
        }
      }
      //global loading
      this.utilityService.addCompletedLoadingCalls(1);
    });

    this.subscriptions.push(productSubscription);

  }




  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }


}
