<div id="fnbo-uni-primary-highlight">

    <div class="fnbo-center-content">
        <ng-container *ngIf="products">

            <!-- main list of project -->
            <diV id="fnbo-uniprd-highlights-list">
                <!-- slicing cuts off at the 8th item in the list -->
                <div class="fnbo-uniprd-highlight" *ngFor="let product of products | slice:0:8">

                    <!-- <app-single-product [product]="product"></app-single-product> -->
                    <universal-single-highlight [product]="product"></universal-single-highlight>

                </div>
            </diV>

        </ng-container>

    </div>
</div>


<div *ngIf="infoImages && infoImages.length > 0" id="fnbo-uni-secondary-highlight" class="fnbo-space-above" >
    <div class="fnbo-center-content">

        <div id="fnbo-uni-highlights-header">
            <h3 class="fnbo-primary-color">HIGHLIGHTS</h3>
        </div>


        <div id="fnbo-uni-info-tiles" class="fnbo-space-above">
            <div *ngFor="let info of infoImages; let i =index">
                <universal-single-info [infImgSrc]="info.img" [infoImgLink]="info.link"></universal-single-info>
            </div>
           
        </div>
    </div>

</div>