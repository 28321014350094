<div *ngIf="product" id="fnbo-product-detail-page" class="fnbo-content">

    <div class="fnbo-display-flex">
        <div class="fnbo-flex-align-right">
            <button mat-icon-button class="" (click)="goBack()">
                <mat-icon class="">close</mat-icon>
            </button>
        </div>

    </div>

    <div class="fnbo-center-content">


        <div id="fnbo-product-detail" class="row">

            <div class="col-sm-12 col-lg-5 col-md-5">
                <!-- product image area -->
                <div class="fnbo-product-img-area">

                    <img class="fnbo-single-product-card-img" mat-card-lg-image [src]="product.largeImage"
                        alt="Image of {{product.name}}">
                </div>
                <!-- product image area ends -->
            </div>

            <div class="col-sm-12 col-lg-7 col-md-7">

                <!-- product description area -->
                <div class="fnbo-product-description-area">
                    <div>

                        <!-- product name -->
                        <p class="fnbo-single-product-card-title" [innerHTML]="product.name"></p>


                    </div>

                    <div id="fnbo-detail-points" *ngIf="showPoints">
                        <!-- shows the range or single cost of the products
                     bold -->
                        <p class="fnbo-primary-color">
                            <!-- if a value amount is selected; that points equivalent is shown -->
                            <span *ngIf="selectedDenomination == 'n/a'">

                                {{startingMemberCost | commaFormat}}
                            </span>
                            <span *ngIf="selectedDenomination !== 'n/a'">

                                {{ totalPoints| commaFormat}}
                            </span>
                            <ng-container *ngIf="hasPriceOptions && selectedDenomination == 'n/a'">

                                <span> - </span>
                                <span> {{endingMemberCost| commaFormat}} </span>

                            </ng-container>

                            <span>
                                {{memberCostDenomination}}
                            </span>
                        </p>
                        <!-- range ends -->
                    </div>

                    <!-- Only show if for online use only -->
                    <div>
                        <!-- set for certain programs to show online only -->
                        <p *ngIf="onlineOnly"> FOR ONLINE USE ONLY</p>
                    </div>


                    <!-- description area -->
                    <div>
                        <!-- product details and name; using a pipe
                    to toggle the description -->

                        <p class="fnbo-detail-description" innerHTML="{{
                        (product.description.length > descriptionSumLength)?
                         (product.description | slice:0:descriptionSumLength):(product.description)
                      }}">
                        </p>
                        <p id="fnbo-prd-detail-show" *ngIf="product.description.length >= descriptionSumLength"
                            (click)="showMore()" class="fnbo-primary-color">...
                            {{showMoreText}}
                        </p>

                        <!-- product details end -->
                    </div>


                    <div id="fnbo-detail-quantity-area">
                        <ng-container *ngIf="canRedeem">

                            <!-- area that holds the denomination options dropdwon -->
                            <div id="fnbo-prd-detail-denomination" class="fnbo-space-below" *ngIf="showDenomination">

                                <!-- select area for choosing a denomination -->

                                <p class="fnbo-detail-sm-text" *ngIf="hasPriceOptions 
                        || (selectedDenomination?.inventoryItemId && 
                        selectedDenomination.description.length > 0 && product?.isStartingAt == false)">Item *</p>
                                <select *ngIf="hasPriceOptions"
                                    class="form-select fnbo-primary-border fnbo-primary-color"
                                    id="fnbo-prd-detail-den-select" [(ngModel)]="selectedDenomination"
                                    aria-label="Denomination" (ngModelChange)="onChangeDenomination($event)">
                                    <option value="n/a" selected disabled>Please select one option </option>
                                    <option *ngFor="let item of itemOptions | filterBypointsMax : maxPointsToShow | customProductItemSort : orderItemsBy" [ngValue]="item">
                                        {{item.description | commaFormat}}  
                                        <span *ngIf="isUniversal && item.memberCost && item.memberCost > 0"> ({{item.memberCost.toString() | commaFormat}} points) </span> {{item.isOnSale == false ? '' : ' *(Sale)'}}
                                    </option>
                                </select>

                                <p class="fnbo-detail-sm-text font-bold-5" *ngIf="selectedDenomination?.inventoryItemId && 
                        selectedDenomination.description.length > 0 && product?.isStartingAt == false">
                                    {{selectedDenomination.vendorCost | currency:'USD':"symbol":'1.2-2'}} </p>

                                <!-- select area for choosing a denomination ends -->
                            </div>

                            <!-- error message -->
                            <div class="fnbo-error-msg fnbo-text-sm">
                                <p *ngIf="showMethodError">*Please, select an option above.</p>
                            </div>
                            <!-- error message ends -->

                            <!-- custom reward with banking info -->
                            <div>
                                <app-custom-reward [type]="product.cashbackPaymentType"></app-custom-reward>
                            </div>
                            <!-- custom reward with banking info ends-->


                            <!-- terms and condition for specific products  -->
                            <div id="fnbo-dpg-tnc" *ngIf="product.cashbackPaymentType ==='VIEWONLY'"
                                class="fnbo-space-below" (click)="showRedemptionTNC()">
                                <span>
                                    View Redemption Terms & Conditions.
                                </span>
                            </div>

                            <!-- lower action area; add to cart area  -->
                            <div *ngIf="showAddActions">


                                <div *ngIf="showQuantity" class="fnbo-space-below">
                                    <p class="fnbo-detail-sm-text">
                                        Quantity
                                    </p>
                                </div>


                                <div id="fnbo-product-detail-action" class="fnbo-display-flex">

                                    <!-- quantity area  -->
                                    <div *ngIf="showQuantity" id="fnbo-detail-quantity" class="fnbo-primary-border">
                                        <div id="fnbo-detail-quantity-sub" class="fnbo-primary-border"
                                            (click)="modifyAmount(-1)">
                                            <mat-icon class="fnbo-primary-color" aria-hidden="false"
                                                aria-label="remove">remove</mat-icon>
                                        </div>
                                        <div id="fnbo-detail-quantity-amt"
                                            class="fnbo-primary-border fnbo-primary-color">

                                            {{quantity}}
                                        </div>

                                        <div id="fnbo-detail-quantity-add" class="fnbo-primary-border"
                                            (click)="modifyAmount(1)">
                                            <mat-icon class="fnbo-primary-color" aria-hidden="false"
                                                aria-label="add">add</mat-icon>
                                        </div>

                                    </div>

                                    <!-- add to cart button -->
                                    <!-- Flex right if the quantity option is available -->
                                    <div id="fnbo-detail-add-btn" class=""
                                        [ngClass]="showQuantity ? 'fnbo-flex-align-right': ''">
                                        <button class="fnbo-primary-color fnbo-primary-border" mat-button
                                            (click)="addToCart()">
                                            <mat-icon aria-hidden="false"
                                                aria-label="shopping cart">shopping_cart</mat-icon>

                                            Add to cart</button>
                                    </div>
                                </div>
                            </div>


                            <!-- auto redeem function -->
                            <div *ngIf="isAutoRedeem">
                                <app-auto-redeem [product]="product" [previousUrl]="previousUrl"></app-auto-redeem>
                            </div>
                            <!-- auto redeem function ends -->

                            <!-- FCA-R -->
                            <div *ngIf="product.cashbackPaymentType === 'FCA-R'  && product.csrOnly === true">
                                <app-fca-redeem [product]="product"></app-fca-redeem>
                            </div>
                            <!-- FCA-R -->


                            <!-- not FCA-R & not view only items -->

                            <div *ngIf="product.cashbackPaymentType !== 'FCA-R' && !viewOnlyMode">
                                <p class="fnbo-small-note">Note: Redemption amounts include standard shipping, handling
                                    and
                                    applicable taxes.</p>
                            </div>
                            <!-- not FCA-R & not view only items ended -->
                        </ng-container>
                        <!-- error messages -->
                        <section>

                            <div *ngIf="!viewOnlyMode">

                                <p *ngIf="isMagazineAdded" class="text-small fnbo-detail-error-message">This item has
                                    already been added to the cart.</p>


                                <p *ngIf="isCashBackAlreadyAdded && cashbackOnly && !isAutoRedeem && cashBackProduct"
                                    class="text-small fnbo-detail-error-message">
                                    Only one Cash Back option can be redeemed at a time. You have already selected one,
                                    please remove it from the <a routerLink="/cart">cart</a> to
                                    select a different option.
                                </p>


                                <p *ngIf="cashbackOnly && isLessThanMinimumPoints && cashBackProduct"
                                    class="text-small fnbo-detail-error-message">
                                    This option requires a minimum of 2500 points to redeem.
                                </p>

                                <p *ngIf="!canRedeem && !cashBackProduct" class="text-small fnbo-detail-error-message">
                                    Need a minimum of {{minimumPointsToRedeem}} points to do any redemption.
                                </p>
                            </div>

                        </section>
                        <!-- error message ended -->
                    </div>

                </div>

                <!-- product description area ends-->
            </div>
        </div>
    </div>

</div>

<div class="fnbo-loading-area" *ngIf="!product">
    <app-loading [loadingMsg]="'Loading Product Details'"></app-loading>
</div>