<div class="fnbo-contact-us-page">
    <div id="fnbo-contactUs-title-area" class="fnbo-display-flex fnbo-center-content 
        fnbo-primary-border fnbo-space-above">
        <h2 class="fnbo-primary-color"> Contact Us</h2>
        <div class="fnbo-flex-align-right fnbo-horizontal-center-items-flex">
            <a class="fnbo-primary-color" [routerLink]="'/'">

                <mat-icon class="fnbo-primary-color">arrow_back</mat-icon>
                <p class="fnbo">Back Home</p>
            </a>
        </div>
    </div>
    <div id="fnbo-contact-us-content" class="">
        <div class="fnbo-center-content">
            <div class="row">
                <div id="links" class="col-sm-12 col-lg-6 fnbo-contact-us-link-div">

                    <p class="fnbo-contactUs-des">
                        If you have any questions about your order, please send us a note here or
                        call us at <a class="fnbo-contact-us-bolding" href="tel:1-888-801-7987"
                            class="plain text-offblack">888-801-7987</a>.
                    </p>
                    <ul class="fnbo-contact-us-bolding fnbo-contactUs-des">
                        <li>Check out our <a class="fnbo-inline-link" [routerLink]="'/faq'">FAQ</a></li>
                        <li>Review our <a class="fnbo-inline-link" (click)="showRedemptionTNC()">Redemptions Terms &
                                Conditions</a></li>
                    </ul>

                    <p class="fnbo-contactUs-des">
                        If you still need help, we'd love to hear from you. Feel free to reach out to our Customer Care
                        team directly.
                    </p>

                </div>
                <div id="form" class="col-sm-12 col-lg-6  fnbo-contact-us-form-div">
                    <form [formGroup]="contactUsForm" novalidate >
                        <div class="row fnbo-space-below">
                            <!-- first name -->
                            <div class="col-lg-6 col-md-6 col-sm-12 p-0">


                                <label class="required"> First Name*

                                </label>
                                <input class="fnbo-contactUs-inpt fnbo-primary-border" type="text"
                                    [ngClass]="[(contactUsFormControls.firstName.dirty && !contactUsFormControls.firstName.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                                    required aria-describedby="help-firstName" formControlName="firstName"
                                    name="firstName">

                                <div *ngIf="contactUsFormControls.firstName.dirty && !contactUsFormControls.firstName.valid"
                                    id="help-firstName" class="fnbo-contactUs-error-text">
                                    First name required.
                                </div>
                            </div>
                            <!-- firstname done -->
                            <!-- last name -->
                            <div class="col-lg-6 col-md-6 col-sm-12 fnbo-contactUs-input-space">
                                <label class="required"> Last Name*

                                </label>
                                <input class="fnbo-contactUs-inpt fnbo-primary-border" type="text"
                                    [ngClass]="[(contactUsFormControls.lastName.dirty && !contactUsFormControls.lastName.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                                    required aria-describedby="help-lastName" formControlName="lastName"
                                    name="lastName">

                                <div *ngIf="contactUsFormControls.lastName.dirty && !contactUsFormControls.lastName.valid"
                                    id="help-lastName" class="fnbo-contactUs-error-text">
                                    Last name required.
                                </div>
                            </div>
                            <!-- last name done -->

                        </div>
                        <div class="row fnbo-space-below">

                            <!--  Phone Number -->
                            <div class="col-lg-6 col-md-6 col-sm-12 p-0">


                                <label class="required"> Phone Number*

                                </label>
                                <input class="fnbo-contactUs-inpt fnbo-primary-border" type="text"
                                    [ngClass]="[(contactUsFormControls.phoneNumber.dirty && !contactUsFormControls.phoneNumber.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                                    required aria-describedby="help-phoneNumber" formControlName="phoneNumber"
                                    name="phoneNumber">

                                <div *ngIf="contactUsFormControls.phoneNumber.dirty && !contactUsFormControls.phoneNumber.valid"
                                    id="help-phoneNumber" class="fnbo-contactUs-error-text">
                                    Phone number required.
                                </div>
                            </div>
                            <!--  Phone Number done -->
                            <!-- Alterative Phone Number -->
                            <div class="col-lg-6 col-md-6 col-sm-12 fnbo-contactUs-input-space">
                                <label> Alterative Phone Number

                                </label>
                                <input class="fnbo-contactUs-inpt fnbo-primary-border " type="text"
                                    [ngClass]="[(contactUsFormControls.alterativePhoneNumber.dirty && !contactUsFormControls.alterativePhoneNumber.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                                    aria-describedby="help-alterativePhoneNumber"
                                    formControlName="alterativePhoneNumber" name="alterativePhoneNumber">
                            </div>
                            <!-- Alterative Phone Number done -->

                        </div>

                        <div class="row fnbo-space-below">

                            <label class="required"> Subject*

                            </label>
                            <select formControlName="subject" name="subject" id=""
                                class="form-select fnbo-primary-border"
                                [ngClass]="[(contactUsFormControls.subject.dirty && !contactUsFormControls.subject.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                                required>
                             
                                <option value="Item Not Received">Item Not Received</option>
                                <option value="Item Damaged">Item Damaged</option>
                                <option value="Order Dispute">Order Dispute</option>
                                <option value="Other">Other</option>
                            </select>

                            <div *ngIf="contactUsFormControls.subject.dirty && !contactUsFormControls.subject.valid"
                                id="help-subject" class="fnbo-contactUs-error-text">
                                Subject required.
                            </div>
                        </div>
                        <div class="row fnbo-space-below">

                            <label class="required"> Email*

                            </label>
                            <input class="fnbo-contactUs-inpt fnbo-primary-border" type="text"
                                [ngClass]="[(contactUsFormControls.email.dirty && !contactUsFormControls.email.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                                required aria-describedby="help-email" formControlName="email" name="email">

                            <div *ngIf="contactUsFormControls.email.dirty && !contactUsFormControls.email.valid"
                                id="help-subject" class="fnbo-contactUs-error-text">
                                Email required.
                            </div>
                        </div>


                        <div class="row fnbo-space-below">
                            <label for="forms-message" class="required">Message*</label>
                            <textarea id="forms-message" rows="10" class="fnbo-primary-border"
                                [ngClass]="[(contactUsFormControls.message.dirty && !contactUsFormControls.message.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                                formControlName="message" name="message" required>
                            </textarea>
                            <div *ngIf="contactUsFormControls.message.dirty && !contactUsFormControls.message.valid"
                                class="fnbo-contactUs-error-text">
                                Message is required.
                            </div>
                        </div>


                        <div class="fnbo-contactUs-text">
                            <p>Please complete and submit the form and we'll respond within two business days.</p>
                        </div>

                        <div class="fnbo-small-note">
                            <p>* Required field</p>
                        </div>
                        <!-- add to cart button -->
                        <!-- Flex right if the quantity option is available -->
                        <div id="fnbo-contact-add-btn">
                            <button class="fnbo-primary-color fnbo-primary-border" mat-button  [disabled]="disableSubmit"  (click)=" submit()"
                               >
                                Submit</button>
                        </div>
                        <!-- error message -->
                    <div class="fnbo-error-msg fnbo-text-sm" *ngIf="contactFormError">
                        <p >{{contactFormError}}.</p>
                    </div>
                    <!-- error message ends -->
                    </form>


                </div>
            </div>
        </div>
    </div>
</div>