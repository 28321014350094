<div id="fnbo-ohistory-content" class="fnbo-center-content fnbo-content">

    <div id="fnbo-ohistory-title-area" class="fnbo-display-flex fnbo-primary-border">
        <h2 class="fnbo-primary-color"> Order History </h2>


        <div class="fnbo-flex-align-right fnbo-horizontal-center-items-flex">
            <a class="fnbo-primary-color" [routerLink]="'/'">

                <mat-icon class="fnbo-primary-color">arrow_back</mat-icon>
                <p class="">Back Home</p>
            </a>
        </div>
    </div>


    <div class="fnbo-space-above">
 
        <p class="">If you have questions about your order, please use our
            <a [routerLink]="['/contact-us']" class="fnbo-inline-link">
                <strong>contact page </strong>
            </a>
             or call the Rewards Service Center at
            <strong>
                <a href="tel:888-801-7987" class="plain">888-801-7987</a>.

            </strong>
        </p>
    </div>
    <div [hidden]="!orders && !dataSource">
        <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>

            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions" class="fnbo-ohistory-th-expand">&nbsp;</th>
                <td mat-cell *matCellDef="let row">
                    <button class="fnbo-ohistory-expand-btn" mat-icon-button aria-label="expand row" 
                        (click)="(expandedElement = expandedElement === row ? null : row); $event.stopPropagation()">
                        <mat-icon class="fnbo-primary-color" *ngIf="expandedElement !== row">add</mat-icon>
                        <mat-icon class="fnbo-primary-color" *ngIf="expandedElement === row">remove</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- order num Column -->
            <ng-container matColumnDef="orderNum">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fnbo-primary-color fnbo-ohistory-th-number" > Order # </th>
                <td mat-cell *matCellDef="let row" > {{row.orderNum}} </td>
            </ng-container>

            <!-- Order Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fnbo-primary-color fnbo-ohistory-th-date">Order Date </th>
                <td mat-cell *matCellDef="let row"> {{row.date | date: 'MM/dd/YYYY' }} </td>
            </ng-container>

            <!--  Points Redeemed  Column -->
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fnbo-primary-color fnbo-ohistory-th-points fnbo-ohistory-col-hide"> Points Redeemed </th>
                <td mat-cell *matCellDef="let row" class="fnbo-ohistory-col-hide"> {{row.amount | commaFormat }} </td>
            </ng-container>

            <!-- Fruit Column -->
            <ng-container class="" matColumnDef="totalPartialPay">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="fnbo-primary-color fnbo-ohistory-th-credit fnbo-ohistory-col-hide"> Credit Card Charge </th>
                <td mat-cell *matCellDef="let row" class="fnbo-ohistory-col-hide"> {{row.totalPartialPay | currency:'USD':"symbol":'1.2-2'}} </td>
            </ng-container>


          


            <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
            <ng-container matColumnDef="expandedDetail">
               
                <td mat-cell *matCellDef="let row" [attr.colspan]="columnsToDisplayWithExpand.length">
                    <div class="o-history-detail"
                        [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'">
                        <div>
                            <app-single-order-history [orderHistory]="row"></app-single-order-history>
                            
                        </div>
                    </div>
                </td>
            </ng-container>




            <tr mat-header-row *matHeaderRowDef="columnsToDisplayWithExpand"></tr>
            <tr mat-row *matRowDef="let row; columns: columnsToDisplayWithExpand;"
                [class.o-history-expanded-row]="expandedElement === row"
                (click)="expandedElement = expandedElement === row ? null : row">

            </tr>

            <tr mat-row *matRowDef="let element; columns: ['expandedDetail']" class="o-history-detail-row"></tr>

            <!-- Row shown when there is no matching data. -->
            <!-- <tr class="mat-row" *matNoDataRow>
              <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
            </tr> -->
        </table>
    </div>
    <mat-paginator *ngIf="orders && orders.length > 0" #paginator [length]="orders.length" [pageSize]="10"></mat-paginator>


    <div id="fnbo-no-orders">
        <p *ngIf="orders && orders.length == 0" class="text-center">
            No orders 
        </p>
    </div>

</div>

<div class="fnbo-loading-area" *ngIf="loading">
    <app-loading [loadingMsg]="'Loading Your Orders'"></app-loading>
</div>