
export class OrderInfoModel {
    public fullName: string;
    public companyName: string;
    public phoneAreaCode: string;
    public phonePrefix: string;
    public phoneLine: string;
    public phoneAltAreaCode: string;
    public phoneAltPrefix: string;
    public phoneAltLine: string;
    public shippingAddress1: string;
    public shippingAddress2: string;
    public shippingCity: string;
    public shippingState: string;
    public shippingCountry: string;
    public shippingPostalCode: string;
    public emailAddress: string;
    public paymentFirstName: string;
    public paymentLastName: string;
    public paymentCardType: string;
    public paymentCardNumber: string;
    public paymentCardNumberMasked: string;
    public paymentExpirationMonth: number;
    public paymentExpirationYear: number;
    public cvv: string;
    public paymentSameAddress: boolean = true;
    public billingAddress1: string;
    public billingAddress2: string;
    public billingCity: string;
    public billingState: string;
    public billingCountry: string;
    public billingPostalCode: string;
    public usePointsAndDollars: boolean = false;
    public expeditedShippingAmount: number = 0;
  }
  