/*
 * This service class get and holds the application settings. 
 */
import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable, switchMap, throwError } from "rxjs";
import { HttpWrapperService } from "./http-wrapper.service";
import { SettingsModel } from "../models/settings";
import { UtilityService } from "./utility.service";

@Injectable()
export class SettingsService {

    defaultProgramSettings: SettingsModel[];
    programSettings: SettingsModel[];
    private _programSettings: BehaviorSubject<SettingsModel[]>;
    $programSettings: Observable<SettingsModel[]>;

    tnc: string;
    private _tnc: BehaviorSubject<any>;
    $tnc: Observable<any>;

    /**
     *
     */
    constructor(private http: HttpWrapperService, private extHttp: HttpClient,
        private utilityService : UtilityService) {

        this._tnc = new BehaviorSubject<string>(this.tnc);
        this.$tnc = this._tnc.asObservable();
        this._programSettings = new BehaviorSubject<SettingsModel[]>(undefined!);
        this.$programSettings = this._programSettings.asObservable();
    }


    loadDefaultSettings() {
        let _settings = sessionStorage.getItem('defaultSettings');

        if (_settings == null || _settings === '') {
            this.utilityService.addLoadingCallsCount(1);
            this.http.get('api/settings/default')
                .subscribe(
                    sett => {

                        this.defaultProgramSettings = sett;
                        sessionStorage.setItem('defaultSettings', JSON.stringify(sett));
                        
                        this.utilityService.addCompletedLoadingCalls(1);
                    });
        }
        else {
            this.defaultProgramSettings = JSON.parse(_settings);
        }

    }

    //loads all the settings
    loadAllSettings(){

        this.utilityService.addLoadingCallsCount(1);

        this.http.get('api/settings/default').pipe(switchMap(defSettings => {

            return this.http.get('api/programsettings').pipe(map(programSettings  => ({defSettings, programSettings})));
        }) ).subscribe(({defSettings, programSettings}) => {
           
            this.programSettings = programSettings;
            this.defaultProgramSettings = defSettings;

            sessionStorage.setItem('programSettings', JSON.stringify(programSettings));
            sessionStorage.setItem('defaultSettings', JSON.stringify(defSettings));

            this.programSettigsSuccess();
            this.utilityService.addCompletedLoadingCalls(1);
        });
    }

    loadProgramSettings() {
        this.utilityService.addLoadingCallsCount(1);

        let _programSettings = sessionStorage.getItem('programSettings');

        if (_programSettings == null || _programSettings === '') {
            this.http.get('api/programsettings')
                .subscribe(
                    sett => {
                        this.programSettings = sett;
                        sessionStorage.setItem('programSettings', JSON.stringify(sett));
                        this.programSettigsSuccess();
                        this.utilityService.addCompletedLoadingCalls(1);
                    });
        } else {
            this.programSettings = JSON.parse(_programSettings);
            this.programSettigsSuccess();
            this.utilityService.addCompletedLoadingCalls(1);
        }
    }
   

    programSettigsSuccess() {
        if (this.programSettings !== undefined) {
            this._programSettings.next(this.programSettings);
        }
    }

    /**
     * 
     * @param key 
     * @returns string
     */
    getDefaultSetting(key: string): string {

        if (this.defaultProgramSettings == undefined) {
            this.loadDefaultSettings();
        }

        if (this.defaultProgramSettings !== undefined) {
            if (this.defaultProgramSettings.length > 0) {
                let _setting = this.defaultProgramSettings.filter(d => d.key === key);

                if (_setting && _setting.length > 0) {
                    return _setting[0].value;
                }
            }
        }
        return '';
    }

    /**
     * 
     * @param key 
     * @returns 
     */
    getProgramSetting(key: string): string {
        
        if (this.programSettings !== undefined) {
            if (this.programSettings.length > 0) {
                let _setting = this.programSettings.filter(d => d.key === key);


                if (_setting && _setting.length > 0
                    && this.isSettingActive(_setting[0])) {
                    return _setting[0].value;
                }
            }
        }
        return '';
    }

    getDefaultTnC() {

        let headers = { 'Content-Type': 'text/html', responseType: "text" };
        let options = { headers: headers };

        let url = this.getDefaultSetting('rdpterms');

        if ((this.programSettings != null && this.programSettings.length > 0) && this.getProgramSetting('rdpterms') !== '') {
            url = this.getProgramSetting('rdpterms');
        }

        this.extHttp.get('/app/assets/tandc/' + url, {responseType: "text"}).pipe(catchError(err => this.handleError(err)))
            .subscribe(
                body => {
                    if (body != null) {
                        this.tnc = <string>body;
                        this.tncSuccess();
                    }
                }
            );
    }

    tncSuccess() {
        if (this.tnc !== undefined) {
            this.tncChange();
        }
    }

    tncChange() {
        this._tnc.next(this.tnc);
    }

    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {
            errMsg = `${error.status} - ${error.statusText || ''}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return throwError(() => new Error(errMsg));
    }

    public isMobile() {
        // device detection
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
            || (/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i)
            .test(navigator.userAgent.substring(0, 4))) {
            return true;
        }
        else {
            return false;
        }
    }

    //check if the setting is active  
    isSettingActive(setting: SettingsModel): boolean {

        let showSetting = false;

        let startDate = new Date(setting.startDate);
        let endDate = new Date(setting.endDateField);


        let today = new Date();


        if (setting.startDate && startDate <= today) {

            showSetting = true;
        }

        if (setting.endDateField && endDate < today) {
            showSetting = false;
        }

        //'A' is used for active in the database
        if (setting.status != 'A') {
            showSetting = false;
        }

        return showSetting;
    }

}