/**
 * This class is use for the program settings
 */
export class SettingsModel {
    public key: string;
    public value: string;
    public name: string;
    public description: string;
    public programCode: string;
    public status: string;
    public startDate: string;
    public endDateField: string;
    public dataType: string;

}

// holds all the settings for each program
export enum SettingsOptions {
    ALERT_MSG = "alertmsg",
    COLOR_PRIMARY = "pcolor",
    COLOR_SECONDARY = "scolor",
    PROMO_BANNER_DSK_IMG = "bnimgdk",
    PROMO_BANNER_DSK_IMG_2 = "bnimgdk2",
    PROMO_BANNER_DSK_IMG_3 = "bnimgdk3",
    PROMO_BANNER_MBLE_IMG = "bnimgmb",
    PROMO_BANNER_MBLE_IMG_2 = "bnimgmb2",
    PROMO_BANNER_MBLE_IMG_3 = "bnimgmb3",
    PROMO_BANNER_URL = "bnimglk",
    PROMO_BANNER_URL_2 = "bnimglk2",
    PROMO_BANNER_URL_3 = "bnimglk3",
    BRAND_MSG = "brandmsg",
    BRAND_MSG_STYLE ="bmsgstyl",
    HIDE_FAQS = "hidefaqs",
    LARGE_LOGO = "llogo",
    SMALL_LOGO = "slogo",
    NGC_CODE = "ngccode",
    PROGARM_TYPE = "progtype",
    AD_BANNER = "ad",
    AD_BANNER_LINK = "adlink",
    AD_BANNER_MOBILE = "admobile",
    AUTO_REDEMPTION_AMT = "aurdpamt",
    CASHBACK_ONLY_PRGM = "cshbkoly",
    CSR_PHONE = "csrphone",
    HIDE_AGENT_LOGO = "hdagbklg",
    HINDA_ACCT_KEY = "hindaact",
    HINDA_MSG = "hindamsg",
    MIN_REDEMPTION_PTS = "minrdppt",
    POINTS_EXP = "pointexp",
    REDEMPTION_TERMS = "rdpterms",
    STYLESHEET = "stylesst",
    CUSTOM_HIGHLIGHT = "csthglts",
    CUSTOM_HIGHLIGHT_LAYOUT = "custly",
    HIGHLIGHT_INFO_IMG = "hlInfoIg",
    HIGHLIGHT_INFO_IMG2 = "hlIfoIg2",
    HIGHLIGHT_INFO_IMG3 = "hlIfoIg3",
    HIGHLIGHT_INFO_IMG4 = "hlIfoIg4",
    HIGHLIGHT_INFO_LINK = "hlIfoLk",
    HIGHLIGHT_INFO_LINK2 = "hlIfoLk2",
    HIGHLIGHT_INFO_LINK3 = "hlIfoLk3",
    HIGHLIGHT_INFO_LINK4 = "hlIfoLk4",
    BANNER_HTML_OVERLAY1 =  "bnohtml"
}


//holds the product options
export enum ProductOptions{
    CASHBACK = "FBO_Cashback"
}


export enum FormsPatterns{

    PHONENUMBER = "^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$",
    STATE = "^(A[LKSZRAEP]|C[AOT]|D[EC]|F[LM]|G[AU]|HI|I[ADLN]|K[SY]|LA|M[ADEHINOPST]|N[CDEHJMVY]|O[HKR]|P[ARW]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY])|(a[lkszraep]|c[aot]|d[ec]|f[lm]|g[au]|hi|i[adln]|k[sy]|la|m[adehinopst]|n[cdehjmvy]|o[hkr]|p[arw]|ri|s[cd]|t[nx]|ut|v[ait]|w[aivy])$",
    ZIPCODE = "^[0-9]{5}(?:-[0-9]{4})?$",

}

export enum StyleSettings{
    MAXMOBILEWIDTH = 991.98,
    MAXTABLETWIDTH = 1199.98,
}

export const UNIVERSALPROGRAMS = ["317","318"]