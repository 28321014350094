<div id="fnbo-cart" *ngIf="cartSummary" class="fnbo-content">

    <div id="fnbo-cart-sections" class="fnbo-display-flex">

        <div id="fnbo-cart-left-area" *ngIf="!showTablet" class="fnbo-primary-bg tablet-hidden">

            <div *ngIf="!showTablet && cartSummary.isOrderShippable" id="fnbo-orderInfo-address">
                <p> Where's this order going ?</p>
                <app-order-address [showForm]="cartSummary.isOrderShippable"></app-order-address>
            </div>


            <div id="fnbo-cart-no-shipping" *ngIf="!cartSummary.isOrderShippable" >
                <div>


                    <div>
                        <mat-icon class="">local_shipping</mat-icon>

                    </div>
                    <p *ngIf="itemCount > 0">No Shipping Information Required.</p>
                    <p *ngIf="itemCount == 0">Nothing to Ship.</p>
                </div>
            </div>
        </div>
        <div *ngIf="!loadingCart" id="fnbo-cart-right-area" class="fnbo-flex-align-right ">
            <!-- top functions and summary -->

            <div class="fnbo-display-flex">
                <section id="fnbo-cart-right-header">
                    <p>Your Cart <span> {{itemCount}} items </span></p>

                </section>
                <section *ngIf="previousUrl" class="fnbo-flex-align-right">
                    <button mat-icon-button class="" (click)="goBack()">
                        <mat-icon class="">close</mat-icon>
                    </button>
                </section>



            </div>
            <!-- top functions and summary -->

            <!-- items -->
            <section>
                <ng-container *ngIf="cartItems">
                  

                    <div *ngFor="let item of cartItems" class="fnbo-cart-single-item">

                       <app-cart-item [cartItem]="item" (removeItem)="removeItem($event)"></app-cart-item>

                    </div>
                </ng-container>


                <div *ngIf="!cartItems">
                    Cart is empty
                </div>

            </section>
            <!-- items ends -->


            <section id="fnbo-cart-action-area" class="fnbo-primary-border fnbo-space-below">
                <div class="fnbo-display-flex fnbo-cart-total-points fnbo-primary-color fnbo-space-below">
                    <p>Total Points</p>
                    <p>{{totalPoints.toString() | commaFormat}} points</p>
                </div>

                <section *ngIf="cartSummary.isOrderShippable">
                    <div *ngIf="showTablet" id="fnbo-orderInfo-address" class="fnbo-mobile-view">
                        <p> Where's this order going ?</p>
                        <app-order-address [showForm]="cartSummary.isOrderShippable"></app-order-address>
                    </div>
                </section>

                <div *ngIf="itemCount > 0" class="fnbo-space-below">
                    <section class="">
                        <mat-checkbox [disableRipple]="true" class="fnbo-cart-text fnbo-cart-addy-check"
                            (ngModelChange)="tncComfirmedChange($event)" [(ngModel)]="tncComfirmed">
                        </mat-checkbox>
                        <span class="fnbo-cart-text"> I agree to all <a class="fnbo-inline-link"
                                (click)="showRedemptionTNC()">Redemptions Terms & Conditions</a></span>
                    </section>
                    <!-- error message -->
                    <div class="fnbo-error-msg fnbo-text-sm">
                        <p *ngIf="showTncError">Accept Terms and Conditions before continuing.</p>
                    </div>
                    <!-- error message ends -->

                </div>

                <div>
                    <!-- error msg -->
                    <div *ngIf="errorMsg && errorMsg.length> 0">
                        <p class="fnbo-error-msg fnbo-error-msg-bg">{{errorMsg}}</p>
                    </div>
                    <!-- error msg ends -->
                    <button *ngIf="itemCount > 0 && !disableCheckout" class="fnbo-primary-bg fnbo-cart-btn"
                        mat-button (click)="checkout()">
                        <mat-icon aria-hidden="false" aria-label="shopping cart">shopping_cart_checkout</mat-icon>

                        Checkout</button>
                    <button class="fnbo-primary-color fnbo-cart-btn" mat-button (click)="goBack()">

                        Continue Shopping</button>
                </div>

                <div id="fnbo-cart-order-history-lk" class="text-center fnbo-primary-color fnbo-space-below">
                    <a [routerLink]="'/order-history'">View Complete Order History</a>
                </div>

                <div *ngIf="itemCount > 0" class="fnbo-space-below">
                    <p class="fnbo-cart-text">
                        <strong>Note:</strong> Redemption amount includes standard shipping, handling, and applicable
                        taxes. Due to daily
                        purchase limits,
                        gift card orders each day can not exceed $10,000.
                    </p>
                </div>

                <div id="fnbo-so-non-mag-gift-msg" class="fnbo-space-below" *ngIf="!isMagazineOrGiftCard">
                    <h6 class="fnbo-cart-text"><strong>Merchandise cannot be shipped to a P.O. Box</strong></h6>
                    <p class="fnbo-cart-text">
        
                        Please provide a physical address for your delivery. Please note it will result in a delay or cancellation of your order if a physical address is not provided.
                    </p>
                </div>
        
            </section>


        </div>

        <div *ngIf="loadingCart" class="fnbo-loading-area" >
            <app-loading [loadingMsg]="'Loading Cart'"></app-loading>
        </div>

    </div>

</div>