<div>
    <div>

        <mat-spinner></mat-spinner>
    </div>
    <div id="fnbo-loadingMsg" *ngIf="loadingMsg">

        <p class="text-center fnbo-primary-color">{{loadingMsg}}</p>
    </div>

</div>