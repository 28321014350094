<div id="fnbo-error">

    <div class="fnbo-display-flex">

        <div>


            <div>
                <div> <mat-icon class="fnbo-primary-color">sentiment_dissatisfied</mat-icon></div>
                
            </div>
            <div>

                <h3 class="fnbo-primary-color">Opps Something went wrong!</h3>
                <p>Try again later. If it happens again please contact us at</p>
                <p><strong>

                    <a href="tel:1-888-801-7987" class="plain text-offblack">888-801-7987</a>.
                </strong></p>
            
            </div>
        </div>
    </div>
</div>