
export class AccountModel {
    public firstName: string;
    public lastName: string;
    public programLogo: string;
    public agentLogo: string;
    public points: number;
    public accountNumber: number;
    public errorMessage: string;
    public programCode: string;
    public portfolioId: number;
    public categoryUrl: string;
    public categoryImage: string;
    public id: number;
    public topLevelCatagory: string[];
    public parentCat: string
    public email: string;
    public phone: string;
    public alternatePhone: string;
    public canRedeem: boolean;
    public optIn: string;
    public methodSelected: string;
    public billingAddress: AccountAddressModel;
    public shippingAddress: AccountAddressModel;
    public desktopImage: string;
    public mobileImage: string;
    public adLink: string;
    public groupId: number;
    public cardNumber: string;
}


export class AccountAddressModel {
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
}


