<div id="fnbo-custom-rewards">
    <form [formGroup]="bankInfoForm" novalidate>
        <div>
            <ng-container *ngIf="type === 'ACH' || type === '529A' || type === 'CHECK'">
                <div class="form-group fnbo-space-below">
                    <label for="forms-account_number" class="required">Bank Routing Number
                        <mat-icon class="fnbo-custrwds-help-icon" (click)="openCheckModal()">help</mat-icon>
                    </label>
                   <input class="fnbo-custom-rewards-inpt fnbo-primary-border fnbo-primary-color" [(ngModel)]="bankRoutingNumber" type="text"
                        [ngClass]="[(bankInfoFormControls.bankRouting.dirty && !bankInfoFormControls.bankRouting.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                        required aria-describedby="help-bank-routing" formControlName="bankRouting"  autocomplete="off" 
                        name="bankRouting">

                    <div *ngIf="bankInfoFormControls.bankRouting.dirty  && !bankInfoFormControls.bankRouting.valid"
                        id="help-bank-routing" class="fnbo-custrwds-error-text">
                        Must be 9 or more numbers.
                    </div>
                </div>

                <!-- account number -->

                <div class="form-group fnbo-space-below">
                    <label for="forms-account_number" class="required">Account Number
                        <mat-icon class="fnbo-custrwds-help-icon" (click)="openCheckModal()">help</mat-icon>
                    </label>
                    <input class="fnbo-custom-rewards-inpt fnbo-primary-border fnbo-primary-color" [(ngModel)]="bankAccountNumber" type="text"
                        [ngClass]="[(bankInfoFormControls.bankAccount.dirty  && !bankInfoFormControls.bankAccount.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                        required aria-describedby="help-account_number" formControlName="bankAccount"  autocomplete="off"
                        name="bankAccount">

                    <div *ngIf="bankInfoFormControls.bankAccount.dirty  && !bankInfoFormControls.bankAccount.valid"
                        class="fnbo-custrwds-error-text">
                        Must be 5 or more numbers.
                    </div>
                </div>

                <!-- account number ends -->
            </ng-container>

            <ng-container *ngIf="type === 'MORTGAGE'">
                <!-- account number -->

                <div class="form-group fnbo-space-below">
                    <label for="forms-account_number" class="required">Account Number
                        <mat-icon class="fnbo-custrwds-help-icon" (click)="openCheckModal()">help</mat-icon>
                    </label>
                    <input class="fnbo-custom-rewards-inpt fnbo-primary-border fnbo-primary-color" [(ngModel)]="bankAccountNumber" type="text"
                        [ngClass]="[(bankInfoFormControls.bankAccount.dirty  && !bankInfoFormControls.bankAccount.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                        required aria-describedby="help-account_number" formControlName="bankAccount"  autocomplete="off"
                        name="bankAccount">

                    <div *ngIf="bankInfoFormControls.bankAccount.dirty  && !bankInfoFormControls.bankAccount.valid"
                        class="fnbo-custrwds-error-text">
                        Must be 5 or more numbers.
                    </div>
                </div>

                <!-- account number ends -->
                <div class="form-group fnbo-space-below">
                    <label for="forms-account_number" class="required">Primary Borrower Name</label>
                    <input class="fnbo-custom-rewards-inpt fnbo-primary-border fnbo-primary-color" [(ngModel)]="recipientInfo" type="text"
                        [ngClass]="[(bankInfoFormControls.borrower.dirty && !bankInfoFormControls.borrower.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                        required aria-describedby="help-borrower" formControlName="borrower" name="borrower"  autocomplete="off">
                    <div *ngIf="bankInfoFormControls.borrower.dirty && !bankInfoFormControls.borrower.valid"
                        id="help-borrower"  class="fnbo-custrwds-error-text">
                        Please enter Borrower name to continue.
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="type === 'ENABLE'">
                <!-- account number -->

                <div class="form-group fnbo-space-below">
                    <label for="forms-account_number" class="required">Account Number
                        <mat-icon class="fnbo-custrwds-help-icon" (click)="openCheckModal()">help</mat-icon>
                    </label>
                    <input class="fnbo-custom-rewards-inpt fnbo-primary-border fnbo-primary-color" [(ngModel)]="bankAccountNumber" type="text"
                        [ngClass]="[(bankInfoFormControls.bankAccount.dirty  && !bankInfoFormControls.bankAccount.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                        required aria-describedby="help-account_number" formControlName="bankAccount"  autocomplete="off"
                        name="bankAccount" >

                    <div *ngIf="bankInfoFormControls.bankAccount.dirty  && !bankInfoFormControls.bankAccount.valid"
                       class="fnbo-custrwds-error-text" >
                        Must be 5 or more numbers.
                    </div>
                </div>

                <!-- account number ends -->
            </ng-container>

            <ng-container *ngIf="type === 'NRA'">
                <div class="form-group fnbo-space-below">
                    <label for="forms-member_info" class="required">Member Info</label>
                    <textarea [(ngModel)]="recipientInfo" id="forms-member_info" rows="10"
                        [ngClass]="[(bankInfoFormControls.recieverInfo.dirty && !bankInfoFormControls.recieverInfo.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                        formControlName="recieverInfo" name="recieverInfo" required>
                    </textarea>
                    <div *ngIf="bankInfoFormControls.recieverInfo.dirty && !bankInfoFormControls.recieverInfo.valid"
                        class="fnbo-custrwds-error-text">
                        Please enter membership information to continue.
                    </div>
                    <small id="forms-member_info_help" class="form-text text-mediumgray text-tiny">
                        <span class="text-offblack">FORMAT EXAMPLE:</span>
                        <br /> John Doe
                        <br /> 111 Freedom Ave., Atlanta GA, 30331
                        <br /> Bob Smith
                        <br /> 600 Liberty Cir., Tallahassee FL, 32301</small>
                </div>
            </ng-container>

            <ng-container *ngIf="type === 'STATEMENTCREDIT' && isPooling">
                <div class="form-group fnbo-space-below">
                    <label for="forms-RPSAccounts" class="required">RPS Accounts</label>
                    <div class="select-override">
                        <select formControlName="selectedRPSAccounts" name="selectedRPSAccounts"
                            [(ngModel)]="recipientInfo" (ngModelChange)="selectItem($event)" id="forms-RPSAccounts"
                            [ngClass]="[(bankInfoFormControls.selectedRPSAccounts.dirty && !bankInfoFormControls.selectedRPSAccounts.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                            required>
                            <option [ngValue]="null" disabled selected> Please select one option </option>
                            <option *ngFor="let item of rPSAccounts" [ngValue]="item">{{item.firstName}}
                                {{item.lastName}}
                                -
                                {{item.cardNumber | slice:-4 }}</option>
                        </select>
                    </div>
                </div>
            </ng-container>
        </div>

    </form>
</div>



<!-- dialog for the checks -->
<ng-template let-data #sampleCheck>
    <div class="fnbo-custrwds-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="fnbo-display-flex">
                <div class="fnbo-flex-align-right">
                    <button id="fnbo-custrwds-dg-close" mat-icon-button class="" (click)="closeCheckModal()">
                        <mat-icon class="">close</mat-icon>
                    </button>
                </div>
            </div>
           
            <div class="fnbo-custrwds-dialog-body">
                <div class="fnbo-custrwds-dialog-content">

                    <div>
                        <p>Sample check with bank account number and routing number highlighted.</p>
                    </div>

                    <div>
                        <img src="assets/images/sample-check.jpg"
                            alt="Sample check with bank account number and routing number highlighted">

                    </div>
                </div>
            </div>
            <div class="fnbo-custrwds-dialog-footer">
                <button class="fnbo-primary-bg" mat-button (click)="closeCheckModal()">
                    Close</button>
            </div>
        </div>
    </div>

</ng-template>