<mat-drawer-container id="fnbo-main-wrapper" class="fnbo-sidenav-container" autosize hasBackdrop="true">
  <mat-drawer #sidenav class="fnbo-sidenav" mode="over">
    <ng-container *ngIf="!inGoodbyeFlow">

    </ng-container>

    <ng-container *ngIf="!inGoodbyeFlow">


      <button mat-icon-button class="web-hidden fnbo-side-nav-close-btn" (click)="sidenav.toggle()">

        <mat-icon class="fnbo-primary-color">close</mat-icon>
      </button>
      <app-side-menu-bar></app-side-menu-bar>
    </ng-container>
    
  </mat-drawer>

  <section class="fnbo-sidenav-content">
    
    <ng-container *ngIf="!inGoodbyeFlow">
      <app-messaging></app-messaging>
      <div>
        <app-top-alert-msg></app-top-alert-msg>
      </div>
      <div class="fnbo-app-main-menu">
        <app-top-menu-bar></app-top-menu-bar>
      </div>
    </ng-container>

    <router-outlet></router-outlet>

    <ng-container *ngIf="!inGoodbyeFlow">
      <app-footer></app-footer>
    </ng-container>
  </section>

</mat-drawer-container>

<div class="fnbo-loading-area fnbo-overlay-spinner" *ngIf="utilityService.showGlobalLoadingScreen">
  <app-loading [loadingMsg]="'Loading Your Rewards...'"></app-loading>
</div>