import { Component } from '@angular/core';
import { Subscription, map, switchMap } from 'rxjs';
import { FaqCategory, FaqTopic } from 'src/core/models/faq';
import { FAQService } from 'src/core/services/faq.service';
import {FloatLabelType, MatFormFieldModule} from '@angular/material/form-field';
import { SettingsService } from 'src/core/services/settings.service';
import { AccountService } from 'src/core/services/account.service';
import { AccountModel } from 'src/core/models/account';
import { UNIVERSALPROGRAMS } from 'src/core/models/settings';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
  selected: string;
  categories: FaqCategory[];
  selectedCategory: FaqTopic[] = [];
  matSelectFloat : FloatLabelType = <FloatLabelType> "never";
  accountInfo : AccountModel;
  faqsType : string = "DEFAULT";
  

   //holds the subcriptions
  subscriptions: Subscription[];

  constructor(private faqService: FAQService, private settings : SettingsService, private accountService : AccountService) {
    
    this.subscriptions = [];
  }

  ngOnInit() {
    this.setup();
  }

   setup(){
    this.accountInfo = this.accountService.accountInfo;

    if(UNIVERSALPROGRAMS.includes(this.accountInfo.programCode) ){
      this.faqsType = "UNIVERSAL";    

    }else{
      this.settings.getDefaultTnC();
      this.setCategories();
      this.faqsType = "DEFAULT"
    }

  }

  /* Change categories after selected in the drop down menu.
   */
  changeCategory(el: string, selection: string): void {

    if(selection){

      this.selected = el;
    }
     
    this.clearTopics();
    this.loadTopics(el);
  }

  /* Clear the selected topic list via splice, so it is detected by angular change detected to reload the ngFor
   */
  private clearTopics(): void {
    if(this.selectedCategory.length > 0) {
      this.selectedCategory.splice(0);
    }
  }

  /* Load the selected topic list via push, so it is detected by angular change detected to reload the ngFor
   */
  private loadTopics(categoryId): void {
    this.getTopicFromCategories(categoryId).forEach(element => this.selectedCategory.push(element));
  }

  /* Find the topic list from the specified category id.
   */
  private getTopicFromCategories(id: string): FaqTopic[] {
    let result: any;
    if(id != null) {
      this.categories.filter(obj => {
        if(obj.id === id) {
          result = obj.frequentlyAskedQuestions;
        }
      });
    } 
    return result;
    
  }

  /* Load the categories from service layer.
   */
  private setCategories(): void {
    let faqsSub = this.faqService.getFAQs().pipe(switchMap(faqs => {

      return this.settings.$tnc.pipe(map(tnc=>({faqs, tnc})))
    })).subscribe(({faqs, tnc}) => {
      this.categories = faqs;

      //Load the Terms and Conditions Category
      this.setTermsAndConditions(tnc);

      // By default, load the page with the first category returned from the API
      
      this.changeCategory(this.categories[0].id, this.categories[0].description);
      
    });
  
    this.subscriptions.push(faqsSub);
  }

  /* Loads the Terms and Conditions Category from service
   */
  private setTermsAndConditions(value): void {
 
      let tacHTML:string = value;
      let faqTac:FaqTopic;
      let tac:FaqCategory;
      //Manually create the FaqTopic object with the HTML as a string returned from the service.
      faqTac = {
        id: 'termsAndConditionsTopic',
        question: 'Terms And Conditions',
        answer: tacHTML
      };
      //Manually create the FaqCategory object with the above FaqTopic
      tac = {
        id: 'termsAndConditionsCategory',
        description: 'Terms And Conditions',
        frequentlyAskedQuestions: [faqTac]
      };
      //Push the Terms and Conditions into the existing categories array to be found by angular change detection
      this.categories.push(tac);
  }

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }

}
