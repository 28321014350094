import { Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { Subscription } from 'rxjs';
import { LogoutConfirmationComponent } from 'src/core/components/logout-confirmation/logout-confirmation.component';
import { TimeoutComponent } from 'src/core/components/timeout/timeout.component';
import { AccountService } from 'src/core/services/account.service';
import { SettingsService } from 'src/core/services/settings.service';
import { SideNavService } from 'src/core/services/side-nav.service';
import { StyleService } from 'src/core/services/style.service';
import { UtilityService } from 'src/core/services/utility.service';
import { environment } from 'src/environments';

// Declare ga function as ambient
declare let ga: Function;
declare let gtag: Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: any = 'FNBO Consumer Website';
  timeoutDialogRef: MatDialogRef<TimeoutComponent>;
  isLoggedIn: boolean = false;
  // Keepalive properties
  idleState: string = 'Active';
  timedOut: boolean = false;

  timeoutDialogOpen: boolean = false;

  lastPing: Date;
  frozen: boolean;

  inGoodbyeFlow: boolean = false;

  subscriptions: Subscription[];

  @ViewChild('sidenav') public sidenav: MatSidenav;

  constructor(private settings: SettingsService, public dialog: MatDialog, private idle: Idle,
    private keepalive: Keepalive, private sideNavService: SideNavService,
    private styleService: StyleService, private router: Router, private accountService: AccountService,
    private elementRef: ElementRef, public utilityService: UtilityService) {

    this.subscriptions = [];
    this.configureIdle(idle, keepalive);

  }

  ngOnInit(): void {

    this.settings.loadAllSettings();
    this.styleService.setup();

    this.setup();
  }


  setup() {

    // After navigation to a new page, scroll to the top of it.
    this.router.events.subscribe((evt) => {

      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      //use to force the content to the top when 
      //the route params changes
     const matScrollContent = document.querySelector("mat-drawer-content");
     if(matScrollContent){
       matScrollContent.scrollTop = 0;
     }

    
      let routeString: string = this.router.routerState.snapshot.url;

      if (routeString.startsWith("/cart") || routeString.startsWith("/checkout")) {

      }
      else if (routeString.startsWith("/goodbye") || routeString.startsWith("/error")) {

        this.inGoodbyeFlow = true;
        this.idleState = "Timed out";
        this.accountService.islogout = true;

        // Stop both the idle and keepalive after logout or error
        this.idle.stop();
        this.keepalive.stop();
      }
      else {
        this.inGoodbyeFlow = false;
      }

      // Push page to Google Analytics if enabled in the environment file
      if (environment.gaEnabled) {
        ga('send', 'pageview');
      }

    });
  }


  reset() {
    this.idle.watch();
    this.idleState = "Active";
    this.timedOut = false;
  }

  configureIdle(idle: Idle, keepalive: Keepalive) {

    // Timeout related options are set from the environment file
    idle.setIdle(environment.timeoutIdle!);
    idle.setTimeout(environment.timeoutTimeout!);
    keepalive.interval(environment.timeoutPingInterval);

    // Set the default interrupt tracking sources
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    // Timeout event handlers

    let sub = idle.onIdleStart.subscribe(() => {
      this.idleState = "Timeout alert";
      this.showTimeoutConfirmation(this.idleState, undefined!);

    });

    this.subscriptions.push(sub);

    sub = idle.onIdleEnd.subscribe(() => {
      this.idleState = "Active";
      this.hideTimeoutDialog();
    });

    this.subscriptions.push(sub);

    sub = idle.onTimeout.subscribe(() => {
      this.idleState = "Timed out";
      this.showTimeoutConfirmation(undefined!, this.idleState);
      sessionStorage.clear();

      this.hideTimeoutDialog();
      this.router.navigate(['goodbye']);
    });

    this.subscriptions.push(sub);

    sub = idle.onTimeoutWarning.subscribe((countdown) => {

      this.idleState = ' You will time out in ' + countdown + ' seconds. Move your mouse' +
        ' or click anywhere to continue.';

      this.showTimeoutConfirmation(this.idleState, countdown);

    });

    this.subscriptions.push(sub);

    sub = keepalive.onPing.subscribe(() => {
      this.lastPing = new Date();

      //this to refresh the server with a heartbeat to keep the server session alive.
      if (this.idleState === "Active") {
        this.accountService.keepSessionAlive();
      }

    });

    this.subscriptions.push(sub);

    this.reset();
  }


  showTimeoutConfirmation(message: string, header: any) {

    if (!message && !header) {
      return;
    }

    if (this.timeoutDialogRef && this.timeoutDialogRef.getState() === MatDialogState.OPEN) {
      this.timeoutDialogRef.componentInstance.data = {
        message: message,
        header: header
      }

      return;
    }

    //open dialog
    this.timeoutDialogRef = this.dialog.open(TimeoutComponent, {
      maxWidth: '760px',
      panelClass: 'fnbo-timeout-dialog',
      hasBackdrop: true,
      data: {
        message: message,
        header: header
      }
    });

    this.timeoutDialogRef.afterOpened().subscribe(result => {
      this.timeoutDialogOpen = true;
    })


    this.timeoutDialogRef.afterClosed().subscribe(result => {

      this.timeoutDialogOpen = false;
    });
  }

  hideTimeoutDialog() {
    if (this.timeoutDialogRef) {

      this.timeoutDialogRef.close();
      this.timeoutDialogOpen = false;
    }
  }

  loadScripts() {
    if (environment.adobeTagManger) {

      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = environment.adobeTagManger;

      script.setAttribute('async', '');

      this.elementRef.nativeElement.appendChild(script);
    }
  }

  ngAfterViewInit() {

    this.sideNavService.setSidenav(this.sidenav);
    this.loadScripts();
  }

  ngOnDestroy() {

    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }
}
