
import { Pipe, PipeTransform } from '@angular/core';
import { ItemModel } from '../models/product';

@Pipe({ name: 'filterByprops' })
export class FilterByPropPipe implements PipeTransform {

    /**
     * Pipe filters the list of elements based on the search text provided
     *
     * @param items list of products
     * @param queryText string to search by
     * @returns filtered list
     */

    transform(items: any[], props: string[], queryText: string): any[] {
        if (!items) {
            return [];
        }
        if (!queryText) {
            return [];
        }
        queryText = queryText.toString().toLocaleLowerCase();

        return items.filter(x => {
            let value = false;

            for (var prop of props) {

                if (x[prop] && !value) {
                    value = x[prop].toString().toLocaleLowerCase().includes(queryText);

                }
            }

            return value;
        });
    }
}



@Pipe({ name: 'filterBypointsMax' })
export class FilterByMinPointsPipe implements PipeTransform {

    transform(items: ItemModel[], maxPoints: any) {

        try {

            if (items && maxPoints && maxPoints != -1) {
                items = items.filter(x => x.memberCost <= maxPoints);
            }

            return items;

        } catch (e) {

            return items;
        }
    }


}