export class ContactModel {
    public firstName: string;
    public lastName: string;
    public name: string;
    public email: string;
    public phone: string;
    public altPhone: string;
    public subject: string;
    public message: string;
    public accountId: string;
}
