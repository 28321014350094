<div id="fnbo-single-ohistory" *ngIf="orderHistory">


    <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
            <ng-container>
                <!-- Order Confirmation Number  -->
                <div>
                    <p class="fnbo-s-ohistory-prop-header fnbo-primary-color"> Order Confirmation
                        Number
                    </p>
                    <p class="fnbo-s-ohistory-prop-value">{{orderHistory.orderNum}}</p>
                </div>
                <!-- Order Date  -->
                <div>
                    <p class="fnbo-s-ohistory-prop-header fnbo-primary-color">Order Date</p>
                    <p class="fnbo-s-ohistory-prop-value">
                        {{ orderHistory.date | date: 'MM/dd/YYYY' }}</p>
                </div>

                <!--Total Points Redeemed  -->
                <div>
                    <p class="fnbo-s-ohistory-prop-header fnbo-primary-color">POINTS REDEEMED</p>
                    <p class="fnbo-s-ohistory-prop-value" *ngIf="orderHistory.amount">
                        {{orderHistory.amount.toString() | commaFormat }}
                    </p>
                </div>
                <!--Charged to Credit Card  -->
                <div>
                    <p class="fnbo-s-ohistory-prop-header fnbo-primary-color">Charged to Credit Card
                    </p>
                    <p class="fnbo-s-ohistory-prop-value">
                        {{orderHistory.totalPartialPay | currency:'USD':"symbol":'1.2-2'}}
                    </p>
                </div>
            </ng-container>
        </div>
        <div class="col-lg-6  col-md-6 col-12">

            <!-- Shipping Information -->
            <div>
                <p class="fnbo-s-ohistory-prop-header fnbo-primary-color">Shipping Information</p>
                <p class="fnbo-s-ohistory-prop-value">{{orderHistory.firstName}} {{orderHistory.lastName}} </p>

                <p class="fnbo-s-ohistory-prop-value">{{orderHistory.street}}</p>
                <p class="fnbo-s-ohistory-prop-value">{{orderHistory.city}},
                    {{orderHistory.state}}</p>
                <p class="fnbo-s-ohistory-prop-value">{{orderHistory.country}},
                    {{orderHistory.zip}}</p>
            </div>

        </div>

    </div>

    <!-- table of items -->
    <div>
        <div *ngIf="orderHistory.items" id="fnbo-s-ohistory-item-table" class="fnbo-primary-border fnbo-space-below">

            <table>
                <tr class="fnbo-primary-color">
                    <th>QTY</th>
                    <th>Description</th>
                    <th>Status</th>
                </tr>
                <ng-container *ngFor="let item of orderHistory.items">
                    <tr>
                        <td>{{item.itemCount}}</td>
                        <td>
                            <p [innerHTML]="item.name"></p>
                          
                        </td>
                        <td class="fnbo-text-right">
                            <p>{{item.status}}</p>
                        </td>
                    </tr>
                </ng-container>

            </table>

        </div>

    </div>

</div>