import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'commaFormat' })
export class CommaFormatPipe implements PipeTransform {
  transform(value: string) {
    let newValue = String(value).replace(/(\d)(?=(\d{3})+$)/g,'$1,');

    if (newValue === undefined) {
      return "";
    }
    else {
      return newValue;
    }
    
  }
}
