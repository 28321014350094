import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BasicConfirmationDialogComponent } from 'src/core/components/basic-confirmation-dialog/basic-confirmation-dialog.component';
import { ProductModel } from 'src/core/models/product';
import { SettingsOptions } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { StyleService } from 'src/core/services/style.service';

@Component({
  selector: 'app-auto-redeem',
  templateUrl: './auto-redeem.component.html',
  styleUrls: ['./auto-redeem.component.scss']
})
export class AutoRedeemComponent {
  subscriptionRedeem: Subscription;

  @Input()
  product: ProductModel;

  //selected Denomination and stores the value
  selectedDenomination: any = 'n/a';

  isOptIn: boolean = false;
  //holds Y or N for yes or no 
  optIn: string;
  methodSelected: string = "";

  showMethodError: boolean = false;

  //used to signify if there was an update
  updated: boolean = false;
  showSaveBtn: boolean = true;

  //use to navigate back to previous location
  //if user comes directly from a link,
  //then the user goes to the default
  @Input()
  previousUrl :string = "/";

  /**
   *
   */
  constructor(private accountService: AccountService, public dialog: MatDialog,
    private styleService: StyleService, private router: Router) {

  }

  ngOnInit(): void {
 
    this.showSaveBtn = true;
    this.updated = false;

    this.getAccountSettings();
    this.setup();
  }

  //getting the information from the account
  //to display the correct options; initial setup
  getAccountSettings() {
  
    if (this.accountService.accountInfo.methodSelected !== undefined) {
      if (this.accountService.accountInfo.optIn === "Y" || this.accountService.accountInfo.optIn === "True") {
        this.isOptIn = true;
      }
      else {
        this.isOptIn = false;
      }

      //hard coded code  for each type of auto redeem. 
      if (this.accountService.accountInfo.methodSelected === "FBOS-PCHK0100AC"
        || this.accountService.accountInfo.methodSelected === "Auto Redeem for Check") {
        this.methodSelected = "Auto Redeem for Check";

      }
      else if (this.accountService.accountInfo.methodSelected === "FBOS-FBOSC0100AS"
        || this.accountService.accountInfo.methodSelected === "Auto Redeem for Statement Credit") {

        this.methodSelected = "Auto Redeem for Statement Credit";
      } else {

        this.methodSelected = "n/a";
      }

      this.selectedDenomination = this.accountService.accountInfo.methodSelected;

    }

  }



  setup() {

    this.styleService.setColorStyle(".fnbo-autor-optin-sect * .mdc-checkbox__checkmark", "color", SettingsOptions.COLOR_PRIMARY)

    //subscribing to the change to the auto redeem to check if it was
    //updated or not
    this.subscriptionRedeem = this.accountService.autoRedeem$.subscribe(
      optIn => {
   
        if (optIn) {
          //if updated or failed to, a confirmation is shown with 
          //a message to the user
          this.showConfirmation(optIn);
        }

      });
  }
  /**
   * Only runs if there have been an update 
   * to the options on the screen
   * @param optIn 
   */
  showConfirmation(optIn) {

    let message = "";
    let success = false;

    //if updated  then a pop up will show
    if (this.updated) {
      this.showSaveBtn = false;
      this.accountService.accountInfo.optIn = this.optIn;
      
      if (optIn['item'] === "FBOS-PCHK0100AC") {
        this.methodSelected = "Auto Redeem for Check";
        this.accountService.accountInfo.methodSelected = "Auto Redeem for Check";
      }
      else if (optIn['item'] === "FBOS-FBOSC0100AS") {
        this.methodSelected = "Auto Redeem for Statement Credit";
        this.accountService.accountInfo.methodSelected = "Auto Redeem for Statement Credit";
      }

      this.selectedDenomination = optIn['item'];

      if (optIn['name'] === "Auto_Redeem_Inv_Item_Id") {
        message = "Settings were updated successfully.";
        success = true;

      }
      else {
        message = "Settings was unsuccessfully updated.";
        success = false;
        this.updated = false;
      }

      //open dialog to confirm the change
      const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
        maxWidth: '760px',
        minWidth: '300px',
        minHeight: '200px',
        maxHeight: '300px',
        panelClass: 'fnbo-auto-redeem-confirmation-dialog',
        backdropClass: '',
        data: {
          message: message
        }
      });

    }

  }

  ngOnDestroy() {
    this.subscriptionRedeem.unsubscribe();
  }

  onOptInChange(item) {
    this.showSaveBtn = true;
    this.updated = false;
  }

  /**
 * 
 * @param $event 
 * check for the denomination change
 */
  onChangeDenomination(item) {

    this.updated = false;
    this.showSaveBtn = true;

    //setting the denomination 
    if (this.methodSelected === "Auto Redeem for Check") {
      this.selectedDenomination = "FBOS-PCHK0100AC";

    }
    else if (this.methodSelected === "Auto Redeem for Statement Credit") {
      this.selectedDenomination = "FBOS-FBOSC0100AS";
    }
    else {
      return;
    }
 
    // since there was a change in the selection, no longer showing error
    this.showMethodError = false;
  }


  updateAutoRedeem() {
  
    if (this.methodSelected == "n/a" || !this.selectedDenomination) {
      //there wasn't a method selected by the user
      //so this triggers a message on the screen
      this.showMethodError = true;
      return;
    }


    //getting the optin value and setting the actual value to 
    //send to the service
    if (this.isOptIn == true) {
      this.optIn = "Y";
    }
    else {
      this.optIn = "N";
    }

    //processing the auto redeem settings
    this.accountService.AutoRedeemOptIn(this.optIn, this.selectedDenomination);

    //change to true but if fails the subscribe in the 
    //showConfirmation will handle it
    this.updated = true;
  }

  //go back to the previous page
  goBack(){
    this.router.navigate(["/highlights"]);
  }

}
