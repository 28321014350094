import { Component, Input } from '@angular/core';
import { OrderInfoModel } from 'src/core/models/order';
import { AccountService } from 'src/core/services/account.service';

@Component({
  selector: 'app-order-address-summary',
  templateUrl: './order-address-summary.component.html',
  styleUrls: ['./order-address-summary.component.scss']
})
export class OrderAddressSummaryComponent {


  orderInfo : OrderInfoModel;
  
  //for styling the edit button
  @Input()
  editClasses : string ="";
  /**
   *
   */
  constructor(private accountService : AccountService) {
   
    
  }

  ngOnInit(){
    this.orderInfo = this.accountService.orderInfo;
  }


  ngOnDestroy(){

  }

}
