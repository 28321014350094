
import { Directive, Input, SimpleChanges, Renderer2, ElementRef, OnChanges } from '@angular/core';

@Directive({
  selector: '[searchHighlight]'
})
export class SearchHighlightDirective implements OnChanges {
  @Input() highlightQueryString: string; 
  @Input() highlightContent: string; 
  @Input() highlightClass: string; 
  @Input() setTitle = false; 

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.highlightContent) {
      return;
    }

    if (this.setTitle) {
      this.renderer.setProperty(
        this.el.nativeElement,
        'title',
        this.highlightContent
      );
    }

    if (!this.highlightQueryString || !this.highlightQueryString.length || !this.highlightClass) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.highlightContent);
      return;
    }

    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      this.getFormattedText()
    );
  }

  getFormattedText() {
    const re = new RegExp(`(${this.highlightQueryString})`, 'gi');
    return this.highlightContent.replace(re, `<span class="${this.highlightClass}">$1</span>`);
  }
}