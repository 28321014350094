import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HomeCarouselComponent } from 'src/core/components/home-carousel/home-carousel.component';
import { BrandMsgComponent } from './brand-msg/brand-msg.component';
import { ProductHighlightsComponent } from './product-highlights/product-highlights.component';
import { SharedModule } from 'src/core/shared.module';



@NgModule({
  declarations: [
    HomeComponent,
    HomeCarouselComponent,
    BrandMsgComponent,
    ProductHighlightsComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgbModule,
    SharedModule
  ],
  exports:[
    
  ]
})
export class HomeModule { }
