import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { CustomError, Errorlevel } from "../models/error";
import { HttpWrapperService } from "./http-wrapper.service";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { environment } from "src/environments";
import { NavigationEnd, Router } from "@angular/router";
import { delay } from "rxjs";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    sendError: boolean = true;
    numErrorCallsPerSec: number = 0;

    /**
     *
     */
    constructor(private http: HttpWrapperService, private zone: NgZone, private router: Router) {

        this.montiorUrl();
    }

    montiorUrl() {
        this.router.events.subscribe((evt) => {

            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            let routeString: string = this.router.routerState.snapshot.url;

            if (routeString.startsWith("/goodbye") || routeString.startsWith("/error")) {
                this.sendError = false;
            }
        })
    }

    handleError(error: any): void {

        if (!error) {
            return;
        }

        if (environment.name !== "prod") {
            console.log(typeof(error))
                console.error(error);
           
        }

        if (!this.sendError) {
            return;
        }

        let routeString: string = this.router.routerState.snapshot.url;

        if (!(error instanceof HttpErrorResponse) && error.rejection) {
            error = error.rejection; // get the error object
        }

        let errObject = new CustomError();

        errObject.message = `From Client : ${String(error.message || 'Undefined client error')}. Error stack : ${String(error.stack || error || "")}`;

        errObject.level = Errorlevel.ERROR;
        errObject.url = routeString;


        this.zone.run(() => {

            if (environment && environment.name !== "prod") {

                this.reportError(errObject);
            } else {

                if (this.numErrorCallsPerSec > 10) {
                    setTimeout(() => { }, 1000);

                    this.numErrorCallsPerSec = 0;

                } else {
                    this.numErrorCallsPerSec += 1;
                    this.reportError(errObject);
                }
            }
        });

    }

/**
 *  update error logs
 * @param errObject 
 */
    reportError(errObject: CustomError) {

        var headers: HttpHeaders = new HttpHeaders();
        headers.append('Content-Type', 'application/json');

        this.http.post('api/error', {
            message: errObject.message, level: errObject.level
        }, { headers: headers }).subscribe(x => {


        });
    }
    /**
     * Turns off the global error handler
     */
    turnOff() {
        this.sendError = false;
    }

}