import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopAlertMSGComponent } from './components/top-alert-msg/top-alert-msg.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MenuBarComponent } from './components/menu-bar/menu-bar.component';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { MenuAccountAreaComponent } from './components/menu-account-area/menu-account-area.component';
import { CommaFormatPipe } from './pipes/comma-format.pipe';
import { SideMenuBarComponent } from './components/side-menu/side-menu-bar.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ProductFilterPipe, ProductSortPipe } from './pipes/product-filter.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { SearchDialogComponent } from './components/search-dialog/search-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SearchFilterPipe } from './pipes/search-products.pipe';
import { SearchHighlightDirective } from './directives/search-highlight.directive';
import { FooterComponent } from './components/footer/footer.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { TermsConditionsDialogComponent } from './components/terms-conditions-dialog/terms-conditions-dialog.component';
import { FilterByMinPointsPipe, FilterByPropPipe } from './pipes/custom-filter.pipe';
import { BasicConfirmationDialogComponent } from './components/basic-confirmation-dialog/basic-confirmation-dialog.component';
import { SingleProductComponent } from 'src/app/products/single-product/single-product.component';
import { MatCardModule } from '@angular/material/card';
import { OrderAddressComponent } from './components/order-address/order-address.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PaymentIframeComponent } from './components/payment/payment-iframe.component';
import { CartItemComponent } from 'src/core/components/cart-item/cart-item.component';
import { OrderAddressSummaryComponent } from './components/order-address-summary/order-address-summary.component';
import { ShippingOptionsComponent } from './components/shipping-options/shipping-options.component';
import { ItemShippingOptionPipe, ShippingOptionPipe } from './pipes/item-shipping-option.pipe';
import { OrderResultMsgComponent } from './components/order-result-msg/order-result-msg.component';
import { LogoutConfirmationComponent } from './components/logout-confirmation/logout-confirmation.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
import { TravelComponent } from 'src/app/travel/travel.component';
import { LoadingComponent } from './components/loading/loading.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MessagingComponent } from './components/messaging/messaging.component';
import { GlobalErrorHandler } from './services/global-error-handler.service';
import { CustomTextSort } from './pipes/custom-sort.pipe';
import { UniversalSingleHiglightComponent } from './components/custom/universal/single-product/universal-single-highlight.component';
import { UniversalHighlightsComponent } from './components/custom/universal/universal-highlights-area/universal-highlights-area.component';
import { UniSingleInfoComponent } from './components/custom/universal/single-info/uni-single-info.component';
import { UniversalFaqComponent } from './components/custom/universal/faqs/universal-faq.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [
    TopAlertMSGComponent,
    MenuBarComponent,
    MenuAccountAreaComponent,
    SideMenuBarComponent,
    SearchDialogComponent,
    FooterComponent,
    TermsConditionsDialogComponent,
    BasicConfirmationDialogComponent,
    SingleProductComponent,
    OrderAddressComponent,
    PaymentIframeComponent,
    CartItemComponent,
    OrderAddressSummaryComponent,
    ShippingOptionsComponent,
    OrderResultMsgComponent,
    LogoutConfirmationComponent,
    TimeoutComponent,
    TravelComponent,
    LoadingComponent,
    MessagingComponent,
    UniversalSingleHiglightComponent,
    UniversalHighlightsComponent,
    UniSingleInfoComponent,
    UniversalFaqComponent,
    
    //directives
    SearchHighlightDirective,
    
    //pipes
    CustomTextSort,
    SearchFilterPipe,
    FilterByPropPipe,
    CommaFormatPipe,
    ItemShippingOptionPipe,
    ProductFilterPipe,
    ProductSortPipe,
    ShippingOptionPipe,
    FilterByMinPointsPipe
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FormsModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonModule,
    MatListModule,
    MatBadgeModule,
    NgxPaginationModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatExpansionModule,
    MatCardModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSelectModule

  ],
  exports: [
    TopAlertMSGComponent,
    MenuBarComponent,
    SideMenuBarComponent,
    CommaFormatPipe,
    ProductFilterPipe,
    ProductSortPipe,
    SearchFilterPipe,
    SearchHighlightDirective,
    FooterComponent,
    FilterByPropPipe,
    BasicConfirmationDialogComponent,
    SingleProductComponent,
    OrderAddressComponent,
    PaymentIframeComponent,
    CartItemComponent,
    OrderAddressSummaryComponent,
    ShippingOptionsComponent,
    TravelComponent,
    CartItemComponent,
    SearchDialogComponent,
    LoadingComponent,
    MessagingComponent,
    CustomTextSort,
    UniversalHighlightsComponent,
    UniversalFaqComponent,

    FilterByMinPointsPipe,
    ShippingOptionPipe
  ],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler }
  ]
})
export class SharedModule { }
