<div class="fnbo-bcf-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="fnbo-display-flex">
            <div class="fnbo-flex-align-right">
                <button id="fnbo-bcf-dg-close" mat-icon-button class="" (click)="closeDialog()">
                    <mat-icon class="">close</mat-icon>
                </button>
            </div>
        </div>
        <div class="fnbo-bcf-dialog-header" *ngIf="title">
         
            <h1 id="">{{title}}</h1>
        </div>
        <div class="fnbo-bcf-dialog-body">
            <div class="fnbo-bcf-dialog-content">

                
                <div>{{message}}</div>
            </div>
        </div>
        <div class="fnbo-bcf-dialog-footer">
            <button class="fnbo-primary-bg" mat-button  (click)="closeDialog()">
                             Close</button>
        </div>
    </div>
</div>
