import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FnboProductCustomLogicService } from 'src/app/products/fnbo-product-custom-logic-service/fnbo-product-custom-logic.service';
import { CartItemModel, CartShippingClassModel, CartSummaryModel } from 'src/core/models/cart';
import { OrderInfoModel } from 'src/core/models/order';
import { RedemptionResultModel } from 'src/core/models/redemption';
import { AccountService } from 'src/core/services/account.service';
import { CartService } from 'src/core/services/cart.service';
import { MessagingService } from 'src/core/services/messaging.service';
import { PaymentService } from 'src/core/services/payment.service';

@Component({
  selector: 'app-order-result',
  templateUrl: './order-result.component.html',
  styleUrls: ['./order-result.component.scss']
})
export class OrderResultComponent {

  orderResult: RedemptionResultModel;
  orderInfo: OrderInfoModel;
  subscriptions: Subscription[];

  cartItems: CartItemModel[];
 shippingClasses: CartShippingClassModel[];
  cartSummary: CartSummaryModel;

  partialPayPointsTotal: number;

  // use to track items that are important to be loaded before showing the view
  loadingItems: number = 0;


  constructor(
    private accountService: AccountService,
    private cartService: CartService,
    private router: Router,
    private paymentService: PaymentService,
    private _fnboCustomLogicService: FnboProductCustomLogicService) {

    this.subscriptions = [];
    this.cartItems = [];
    this.cartSummary = new CartSummaryModel;

  }


  ngOnInit() {
    this.checkOrderValidity();
    this.setup();

  }

  setup() {
    this.shippingClasses = this.cartService.cartShippingClasses;

    this.setOrderInfo();

    this.setOrderResults();

    this.getCartItems();

    let partialPaySub = this.cartService.$partialPayPoints.subscribe(x => {
      if (x) {
        this.partialPayPointsTotal = x;
      }
    });

    this.subscriptions.push(partialPaySub);
  }

  //set up the order info
  setOrderInfo() {

    this.loadingItems += 1;

    this.orderInfo = this.accountService.orderInfo;

    if (!this.orderInfo) {
      //if order is not available locally, we check the session storage
      let orderInfoSession = sessionStorage.getItem('orderInfo');

      if (orderInfoSession) {
        this.orderInfo = JSON.parse(orderInfoSession);


      } else {

        this.loadingItems -= 1;
        // TODO: what if there isn't no order information
        this.router.navigate(['/highlights']);
      }
    } else {
      sessionStorage.setItem('orderInfo', JSON.stringify(this.orderInfo));
    }

    this.loadingItems -= 1;
  }

  //set up order results
  setOrderResults() {

    this.loadingItems += 1;
    // Subscribe to redemption order updates
    let subscriptionOrder = this.accountService.$redemptionResult
      .subscribe(orderResult => {

        if (orderResult) {

          this.orderResult = orderResult;

          if (!this.orderResult) {
            this.orderResult = JSON.parse(sessionStorage.getItem('orderResult')!);
          } else {
            sessionStorage.setItem('orderResult', JSON.stringify(this.orderResult));
          }

          this.cartSummary = Object.assign(this.cartSummary, this.cartService.cartSummary);

          if (this.cartService.cartSummary.pointsTotal === 0) {
            this.cartSummary = JSON.parse(sessionStorage.getItem('cartSummary')!);
          } else {

            sessionStorage.setItem('cartSummary', JSON.stringify(this.cartSummary));
          }

          this.loadingItems -= 1;
        }


      });

    this.subscriptions.push(subscriptionOrder);
  }

  getCartItems() {

    this.loadingItems += 1;

    let subscriptionCart = this.cartService.getCartItems()
      .subscribe(cartItems => {

        if (cartItems) {

          for (let i = 0; i < cartItems.length; i++) {
            this._fnboCustomLogicService.applyCustomFCARules(cartItems[i])
          }

          this.cartItems = cartItems;
          this.loadingItems -= 1;
        }
      });

    this.subscriptions.push(subscriptionCart);
  }

  ngAfterViewInit() {

    var sub = this.router.events.subscribe(event => {
      if (this.router.url !== "/checkout/order-result" &&
        this.orderInfo && this.orderResult) {

        this.updateOrderInfo();
      }
    });

    this.subscriptions.push(sub);
  }

  checkOrderValidity() {
    if (!this.accountService.orderInfo && (this.cartService.cartSummary.isOrderShippable == true)) {
      this.router.navigate(['highlights']);
    }
  }
  //clearing the order  result once 
  //user leaves the page
  updateOrderInfo() {

    if (this.orderResult !== undefined) {
      if (this.orderResult.status === 2) {
        this.cartService.clearCart();
        this.accountService.orderInfo = undefined!;
        this.accountService.orderResult = undefined!;
        this.accountService.clearRedemtionResult();
        this.paymentService.resetToken();
      }
    } else {

      this.router.navigate(['highlights']);
    }
  }
  continueShopping() {
    this.router.navigate(['highlights']);
  }

  ngOnDestroy() {

    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }
}
