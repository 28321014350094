
export class CategoryModel {
    public errorMessage: string;
    public parentId: number;
    public description: string;
    public id: number;
    public children: string[];
    public code: string;
    public displayOrder: string;
    public name: string;
    public active: boolean;
    public smallImage: string;
    public largeImage: string;

}

export class MenuCategory{
    public rootCat : CategoryModel;
    public children : CategoryModel[];
}