import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { ProductDetailComponent } from './products/product-detail/product-detail.component';
import { ProductsComponent } from './products/products.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { OrderReviewComponent } from './checkout/order-review/order-review.component';
import { OrderResultComponent } from './checkout/order-result/order-result.component';
import { OrderHistoryComponent } from './order-history/order-history/order-history.component';
import { GoodbyeComponent } from './goodbye/goodbye.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ErrorComponent } from './error/error.component';
import { TravelComponent } from './travel/travel.component';

const routes: Routes = [

  { path: '', redirectTo: 'highlights', pathMatch: 'full' },
  { path: 'api/authorize/arn', redirectTo: 'highlights', pathMatch: 'full' },
  { path: 'api/authorize/saml', redirectTo: 'highlights', pathMatch: 'full' },
  { path: 'highlights', component: HomeComponent },
  { path: 'products/:categoryCode', component: ProductsComponent },
  { path: 'product-details', component: ProductDetailComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'cart', component: CartComponent },
  { path: 'search-results', component: SearchResultsComponent },
  { path: 'order-history', component: OrderHistoryComponent },
  { path: 'goodbye', component: GoodbyeComponent },
  {
    path: 'checkout',
    children: [
      {path: 'order-review', component: OrderReviewComponent },
      {path: 'order-results', component: OrderResultComponent }
    ]
  },
  { path: 'error', component: ErrorComponent },
  { path: '**', component: PageNotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
