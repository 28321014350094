<div>
    <form *ngIf="showForm" [formGroup]="orderAddressForm">
        <div class="row fnbo-orderAddress-inpt-area">
            <!-- fullname -->
            <div class="col-lg-12 col-md-12 col-sm-12 fnbo-group-form-mb-space">


                <label class="required"> Full name*

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.fullname.dirty && !orderAddressFormControls.fullname.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    required aria-describedby="help-fullname" formControlName="fullname" name="fullname">

                <div *ngIf="orderAddressFormControls.fullname.dirty && !orderAddressFormControls.fullname.valid"
                    id="help-fullname" class="fnbo-orderAddress-error-text">
                    A name is required.
                </div>
            </div>
            <!-- fullname done -->
           

        </div>
        

        <div class="row fnbo-orderAddress-inpt-area">
            <!-- company -->
            <div class="col-lg-6 col-md-6 col-sm-12 fnbo-group-form-mb-space">


                <label class="required"> Company

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.company.dirty && !orderAddressFormControls.company.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    aria-describedby="help-company" formControlName="company" name="company">
            </div>
            <!-- company done -->
            <!-- email-->
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="required"> Email*

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.email.dirty && !orderAddressFormControls.email.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    required aria-describedby="help-email" formControlName="email" name="email">

                <div *ngIf="orderAddressFormControls.email.dirty && !orderAddressFormControls.email.valid"
                    id="help-zipCode" class="fnbo-orderAddress-error-text">
                    A valid Email is required (example@mail.com).
                </div>
            </div>
            <!-- email done -->

        </div>
        <div class="row fnbo-orderAddress-inpt-area">
            <!-- Phone -->
            <div class="col-lg-6 col-md-6 col-sm-12 fnbo-group-form-mb-space">


                <label class="required"> Phone Number*

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.phoneNumber.dirty && !orderAddressFormControls.phoneNumber.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    required aria-describedby="help-phoneNumber" formControlName="phoneNumber" name="phoneNumber">

                <div *ngIf="orderAddressFormControls.phoneNumber.dirty && !orderAddressFormControls.phoneNumber.valid"
                    id="help-phoneNumber" class="fnbo-orderAddress-error-text">
                    A valid Phone Number is required (000-000-0000 or +1 000 000 0000).
                </div>
            </div>
            <!-- Phone done -->
            <!-- Alternative Phone Number-->
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="required"> Alternative Phone Number

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.altNumber.dirty && !orderAddressFormControls.altNumber.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    aria-describedby="help-altNumber" formControlName="altNumber" name="altNumber">

                <div *ngIf="orderAddressFormControls.altNumber.dirty && !orderAddressFormControls.altNumber.valid"
                    id="help-zipCode" class="fnbo-orderAddress-error-text">
                    Alternative Phone Number Invalid (000-000-0000 or +1 000 000 0000).
                </div>
            </div>
            <!-- Alternative Phone Number done -->

        </div>

        <div class="row fnbo-orderAddress-inpt-area">

            <!--  street -->
            <div class="col-lg-8 col-md-7 col-sm-12 fnbo-group-form-mb-space">


                <label class="required"> Street*

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.street.dirty && !orderAddressFormControls.street.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    required aria-describedby="help-street" formControlName="street" name="street">

                <div *ngIf="orderAddressFormControls.street.dirty && !orderAddressFormControls.street.valid"
                    id="help-street" class="fnbo-orderAddress-error-text">
                    Street required.
                </div>
            </div>
            <!--  street done -->
            <!-- apt or suite number -->
            <div class="col-lg-4 col-md-5 col-sm-12">
                <label> Apt or Suite or Floor

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.number.dirty && !orderAddressFormControls.number.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    aria-describedby="help-number" formControlName="number" name="number">
            </div>
            <!-- apt or suite number  done -->

        </div>


        <div class="row fnbo-orderAddress-inpt-area">
            <!-- City -->
            <div class="col-lg-6 col-md-6 col-sm-12 fnbo-group-form-mb-space">


                <label class="required"> City*

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.city.dirty && !orderAddressFormControls.city.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    required aria-describedby="help-city" formControlName="city" name="city">

                <div *ngIf="orderAddressFormControls.city.dirty && !orderAddressFormControls.city.valid" id="help-city"
                    class="fnbo-orderAddress-error-text">
                    City required.
                </div>
            </div>
            <!-- City done -->
            <!-- State -->
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="required"> State*

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.state.dirty && !orderAddressFormControls.state.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    required aria-describedby="help-state" formControlName="state" name="state" maxlength="2">

                <div *ngIf="orderAddressFormControls.state.dirty && !orderAddressFormControls.state.valid"
                    id="help-state" class="fnbo-orderAddress-error-text">
                    A valid 2 letter State code is required. (e.g. NY, NJ, FL)
                </div>
            </div>
            <!-- State done -->

        </div>


        <div class="row fnbo-orderAddress-inpt-area">
            <!-- country -->
            <div class="col-lg-6 col-md-6 col-sm-12 fnbo-group-form-mb-space">


                <label class="required"> Country*

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.country.dirty && !orderAddressFormControls.country.valid 
                    && !orderAddressFormControls.country.disabled) ? 'form-control border-gray is-invalid' : 'form-control' ]" required aria-describedby="help-country" formControlName="country"
                    name="country">

                <div *ngIf="orderAddressFormControls.country.dirty && !orderAddressFormControls.country.valid && 
                    !orderAddressFormControls.country.disabled" id="help-country" class="fnbo-orderAddress-error-text">
                    A valid Country is required.
                </div>
            </div>
            <!-- country done -->
            <!-- Zip Code -->
            <div class="col-lg-6 col-md-6 col-sm-12">
                <label class="required"> Zip Code*

                </label>
                <input class="fnbo-orderAddress-inpt fnbo-primary-border" type="text"
                    [ngClass]="[(orderAddressFormControls.zipCode.dirty && !orderAddressFormControls.zipCode.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]"
                    required aria-describedby="help-zipCode" formControlName="zipCode" name="zipCode">

                <div *ngIf="orderAddressFormControls.zipCode.dirty && !orderAddressFormControls.zipCode.valid"
                    id="help-zipCode" class="fnbo-orderAddress-error-text">
                    A valid Zip Code required (12345 or 12345-1234).
                </div>
            </div>
            <!-- Zip Code done -->

        </div>

    </form>

    <section *ngIf="showForm && !disableForm">
        <mat-checkbox [disableRipple]="true" class="fnbo-cart-text fnbo-cart-addy-check"
            (ngModelChange)="billingAddiSelectChange($event)" [(ngModel)]="billingSelected">
            <span id="billing-text" class="fnbo-cart-text "> Billing address same as shipping address</span></mat-checkbox>
    </section>

</div>