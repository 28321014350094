//service use to manage the styling of the site
import { Injectable } from "@angular/core";
import { SettingsModel, SettingsOptions } from "../models/settings";
import { SettingsService } from "./settings.service";
import { UtilityService } from "./utility.service";

@Injectable({
    providedIn: 'root'
})
export class StyleService {

    //default values at init
    primaryColor: string = "#000";
    secondaryColor: string = "#fff";


    constructor(private settings: SettingsService, private utilityService : UtilityService) {  }

    setup() {
        this.utilityService.addLoadingCallsCount(1);
        this.settings.$programSettings.subscribe(x => {
            if (x) {

                this.setDefaultStyles(x)
                this.setStyleSheetClass(x);
                this.setOtherStyling();

            }
            this.utilityService.addCompletedLoadingCalls(1);
        });
    }



    //uses the program settings to set specific styles.
    setDefaultStyles(settings: SettingsModel[]) {

        //primary color
        this.primaryColor = settings.find(setting => setting.key === SettingsOptions.COLOR_PRIMARY.toString())?.value!;
        //secondary color
        this.secondaryColor = settings.find(setting => setting.key === SettingsOptions.COLOR_SECONDARY.toString())?.value!;

        if (!this.primaryColor) {
            this.primaryColor = this.settings.getDefaultSetting(SettingsOptions.COLOR_PRIMARY.toString());
        }
        if (!this.secondaryColor) {
            this.secondaryColor = this.settings.getDefaultSetting(SettingsOptions.COLOR_SECONDARY.toString());
        }

        //setting the primary and secondary color 
        this.setStyleClassProps('.fnbo-primary-bg', 'background-color', this.primaryColor);
        this.setStyleClassProps('.fnbo-secondary-bg', 'background-color', this.secondaryColor);
        this.setStyleClassProps('.fnbo-primary-border', 'border-color', this.primaryColor);
        this.setStyleClassProps('.fnbo-secondary-border', 'border-color', this.secondaryColor);
        this.setStyleClassProps('.fnbo-primary-color', 'color', this.primaryColor);

    }

    //method that can be used in any component to set a global style
    //this is very important for theming dynamically or
    //on page load 
    setColorStyle(prop, attr, option: SettingsOptions) {

        if (option == SettingsOptions.COLOR_PRIMARY) {
            this.setStyleClassProps(prop, attr, this.primaryColor);

        } else if (option == SettingsOptions.COLOR_SECONDARY) {
            this.setStyleClassProps(prop, attr, this.secondaryColor);
        }

    }

    setStyleSheetClass(settings : SettingsModel[]){
        let stylesheet = settings.find(setting => setting.key === SettingsOptions.STYLESHEET.toString());

        if(stylesheet && stylesheet.status == "A"){

            let mainWrapper = document.getElementById("fnbo-main-wrapper");

            if(mainWrapper){
                mainWrapper?.classList.add(`fnbo-${stylesheet.value.toLowerCase()}`)
            }
        }
    }


    //setup the class props 
    setStyleClassProps(prop, attr, value) {

        //incase there isn't any data available
        if (value == '') {
            value = "inherit"
        }
        //getting the class
        let classProp = document.getElementsByClassName(prop) as HTMLCollectionOf<HTMLElement>;

        if (classProp.length != 0 && value) {
            //setting the background color prop of the class
            classProp[0].style.backgroundColor = value.value;
        }


        var styleList = document.getElementsByTagName('style');
        var style = `${prop}{ ${attr}: ${value} !important; }`;

        if (styleList.length != 0) {
            styleList[0].innerHTML += style;
        } else {
            var newStyle = document.createElement('style');
            newStyle.innerHTML = style;
            document.getElementsByTagName('head')[0].appendChild(newStyle);
        }

    }

    setOtherStyling() {
        this.setColorStyle(" .mdc-circular-progress__indeterminate-circle-graphic ", "stroke", SettingsOptions.COLOR_PRIMARY);
    }
}