import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AccountModel } from 'src/core/models/account';
import { AccountService } from 'src/core/services/account.service';
import { CartService } from 'src/core/services/cart.service';
import { SettingsService } from 'src/core/services/settings.service';
import { UtilityService } from 'src/core/services/utility.service';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-menu-account-area',
  templateUrl: './menu-account-area.component.html',
  styleUrls: ['./menu-account-area.component.scss']
})
export class MenuAccountAreaComponent {


  username: any = 'Unknown';
  points: any = 0;
  cartItemCount: any = 0
  hideCartBadge: boolean = true;

  showfilter: boolean = false;

  accountInfo: AccountModel;
  
  //holds the subcriptions
  subscriptions: Subscription[];

  /**
   *
   */
  constructor(private settingsService: SettingsService, private router: Router,
    private cartService: CartService, public dialog: MatDialog,
    private accountService: AccountService, private utilityService: UtilityService) {
    this.subscriptions = [];

  }

  ngOnInit() {

    this.setup();
  }

  ngOnDestroy() {
    

    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }



  setup() {

    this.connectSubscriptions();

    this.accountService.getAccount(false);

  }

  connectSubscriptions() {

    // Subscribe to account changes
    let sub = this.accountService.accountInfoItem$.subscribe(
      account => {

        if (this.accountInfo !== undefined) {
          this.settingsService.loadProgramSettings();
        }

        if (account) {

          this.accountInfo = account;
          this.username = account?.firstName || 'Unknown';
          this.points = Math.floor(account?.points) || 0;
        }
      }
    );

    this.subscriptions.push(sub);

    // Subscribe to cart changes
    sub = this.cartService.$cart
      .subscribe(cart => {
        if (cart && cart.itemCount > 0) {

          this.cartItemCount = cart.itemCount;

          if (this.cartItemCount > 0) {
            this.hideCartBadge = false;
          }

        } else {
          this.hideCartBadge = true;
          this.cartItemCount = 0;
        }

      });

    this.subscriptions.push(sub);
    //listening to the filter button click
    sub =this.utilityService.filterButton$.subscribe(x => {
      this.showfilter = x; //either hide or show product filters
    });

    this.subscriptions.push(sub);
  }


  goToCart() {
    // reseting order info 
    this.accountService.orderInfo = undefined!;

    this.router.navigate(["cart"], {
      state: { previousUrl: this.router.url }
    });
  }


  showLogoutConfirmation() {
    //open dialog
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      maxWidth: '760px',
      panelClass: 'fnbo-logout-dialog',
      hasBackdrop: true
    });
  }


  //trigging the on/off for the filter area for product list
  toggleFilter() {
    this.showfilter = !this.showfilter;
    this.utilityService.filterButton$.next(this.showfilter);
  }
}