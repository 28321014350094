<div>
    <div class="drop-down-menu-label">Select a topic*</div>
    <div class="fnbo-space-below">

        <mat-form-field appearance="fill" class="drop-down-menu">
            <mat-select [panelClass]="'fnbo-select'" [(ngModel)]="selected">
                <div>
                    <mat-option [value]="'pnd'">Points and Redemption</mat-option>
                    <mat-option [value]="'contactus'">Contact Us</mat-option>
                    <mat-option [value]="'unifunds'">Universal FUNds Visa<sup>®</sup> Prepaid Card</mat-option>
                    <mat-option [value]="'shoppingCart'">Shopping Cart</mat-option>
                    <mat-option [value]="'shipping'">Shipping Options</mat-option>
                    <mat-option [value]="'delivery'">Delivery Options</mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>
    <div>

        <div *ngIf="selected == 'pnd'">

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How can I view my point balance?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">

                        You can view your points balance in the upper right-hand corner on the rewards website, check
                        your credit card billing statement, view your online Account Detail page, or call the Rewards
                        Service Center toll-free number at
                        <a href="tel:1-888-801-7987" class="plain text-offblack"> 1-888-801-7987</a> to get your current available point balance.
                    </p>
                </mat-expansion-panel>

            </mat-accordion>


            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Can I redeem for more than one reward?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Yes, as long as you have the required number of points available. Note: There may be a limit to
                        the number of items allowed per order. The limit amounts will be listed in the drop down box for
                        the quantity available when placing your order. If you exceed the maximum amount, your order
                        will not be processed and you will receive a message stating that you must adjust the quantity
                        in your shopping cart before proceeding.
                    </p>
                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            If I have not yet earned enough points for the redemption item of my choice, may I pay the
                            difference?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        No, partial pay is not available. The full point total will be needed to make a redemption.
                    </p>
                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Can I combine points from two or more separate accounts?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Customers that have more than one account cannot combine points.
                    </p>
                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Will my points ever expire?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Unredeemed points will expire 3 years from the date in which they were earned.
                    </p>
                    <p class="faq-answers-p fnbo-text-body">
                        Points that are redeemed and loaded on to your Universal FUNds, will not expire.
                    </p>
                </mat-expansion-panel>

            </mat-accordion>

        </div>


        <div *ngIf="selected == 'contactus'">

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Where do I call if I want to redeem points over the phone?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        To redeem points over the phone, contact
                        <a href="tel:1-888-801-7987" class="plain text-offblack"> 1-888-801-7987</a>. Hours of operation are Monday to Friday
                        from 8am to 9pm CST and Saturday and Sunday from 8am to 4:30pm CST. Our Rewards Service Center
                        is closed on New Year's Day, Memorial Day, Juneteenth, Independence Day, Labor Day, Thanksgiving Day and
                        Christmas Day.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            What should I do if I have a question about my redemption?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        For questions about an existing redemption, you can click on the Contact Us form to submit an
                        inquiry or call the Rewards Service Center at
                        <a href="tel:1-888-801-7987" class="plain text-offblack"> 1-888-801-7987</a>. Please provide your order
                        confirmation number so we can access your order information. To view your current order status,
                        visit your Order History page.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Who can I contact if I have questions about my credit card account?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        If you have questions about your credit card account, not specific to the rewards program, you
                        can call the customer service number listed on the back of your credit card and a representative
                        will be happy to help you.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

        </div>

        <div *ngIf="selected == 'unifunds'">

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How do I redeem points for a Universal FUNds Visa Prepaid Card?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>

                    <ol>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Click on the Universal FUNds Visa Prepaid Card  (Universal FUNds)  image on the Homepage.
                            </p>
                        </li>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Check the Visa Prepaid Card details page for the specific steps required to redeem for the
                                item and select the point redemption value you want to load on to the card.
                            </p>
                        </li>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Select “Add to Cart” and proceed to checkout.
                            </p>
                        </li>
                    </ol>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How do I redeem my rewards?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        To redeem your rewards, you will load them onto a Universal FUNds Visa Prepaid Card, which can
                        be used at select merchants where Visa debit cards are accepted for on-site purchases at
                        Universal Theme Parks in Hollywood and Orlando, including theme park tickets, Universal Express
                        passes, select merchandise, select food and beverages, and on vacation packages purchased online
                        from Universal Parks & Resorts Vacations (accessed via
                        <a class="fnbo-inline-link " href="//UniversalStudiosHollywood.com" target="_blank">UniversalStudiosHollywood.com </a> and <a class="fnbo-inline-link " href="//UniversalOrlando.com" target="_blank">UniversalOrlando.com</a>).
                        You can also use your Universal FUNds for flights and hotel stays booked directly with airlines and hotels.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Are my Universal FUNds reloadable?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        You will get a new Universal FUNds every time you select to redeem rewards with the redemption amount
                        loaded onto the card. When the Universal FUNds have been spent in full, you can discard that card.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">

                            How much can I put on my Universal FUNds?

                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        You must load a minimum of $25 on to the Universal FUNds and you can put a maximum of $1,000.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How can I find out where my Universal FUNds will be accepted?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        For the most up to date information on where your Universal FUNds will work, please visit
                        <a class="fnbo-inline-link " href="//card.fnbo.com/universalFAQ" target="_blank">card.fnbo.com/universalFAQ</a>
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            What if I want to use my rewards for a portion of my purchase?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        To use your Universal FUNds on a purchase where you don't have the full purchase amount in Universal FUNds you
                        will need to do a split transaction. Ensure that the merchant knows you need to split your
                        payment across multiple payment types. You can do split payments for vacation packages purchased
                        online from Universal Parks & Resorts Vacations (accessed via <a class="fnbo-inline-link " href="//UniversalStudiosHollywood.com" target="_blank">UniversalStudiosHollywood.com</a> and
                        <a class="fnbo-inline-link " href="//UniversalOrlando.com" target="_blank"> UniversalOrlando.com)</a>. As well as on in park purchases for tickets, merchandise, food and
                        beverages, Universal Express Passes and and more at Universal Studios Florida, Universal Islands
                        of Adventure, Universal Volcano Bay and Universal Studios Hollywood.
                    </p>
                    <p class="faq-answers-p fnbo-text-body">
                        To use your Universal FUNds to purchase Universal Theme Park Tickets and Universal Express Passes online
                        you will need the full amount on your Universal FUNds.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            What is the value of my rewards earned?  
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Your rewards are issued as points that can be redeemed for a Universal FUNds Prepaid Visa (Universal FUNds). When redeemed, rewards points are converted at the rate of 1 cent per 1 point.  For example, 2,500 reward points can be redeemed for a $25 Universal FUNds Prepaid Visa Card.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>
            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Am I able to give a Universal FUNds Prepaid Visa Card to someone?  
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Yes, you can gift a Universal FUNds Visa Prepaid Card to someone other than the account owner.  To change the name and/or address of the card recipient, you must update the prepopulated customer information during the checkout process.   
                    </p>

                </mat-expansion-panel>

            </mat-accordion>
        </div>

        <div *ngIf="selected == 'shoppingCart'">
            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            What is the Shopping Cart?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        After you log in to your account, each time you select an item for which you wish to redeem,
                        that item gets listed in the Shopping Cart. You can access your Shopping Cart anytime by
                        clicking the Shopping Cart icon at the top of every page. When you are finished shopping, you
                        can view the Shopping Cart to confirm/adjust the items you have selected and the quantity of
                        each item. You will also see detailed instructions on how to pay for your order by redeeming
                        points (2,500 point minimum). From there, you can begin the checkout process. All redemption
                        items are non-returnable and non-refundable.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How does the checkout process work?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>

                    <ol>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Select "Checkout" after all the items you wish to redeem are placed in your cart.
                            </p>
                        </li>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Confirm or edit the address where you want your item(s) to be shipped. Please note that
                                some addresses will not pre-populate and so street number, city, state, and zip code
                                field will need to be entered.
                            </p>
                        </li>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Items will be shipped via standard delivery methods for no additional charge.
                            </p>
                        </li>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Review total cost and payment information. If your billing address varies from the
                                shipping address provided, you can add it at this time.
                            </p>
                        </li>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Confirm all the information on the "Review Order" page is correct, including: items you
                                have selected, shipping address, delivery method, and contact information. If you need
                                to adjust your order at any time, you can select "Back to Cart" at the bottom of the
                                checkout section. Once you have verified that the information is correct, click on the
                                "Complete Order" button to complete your order.
                            </p>
                        </li>
                    </ol>


                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How can I redeem for an item if I don't have enough points?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        The full point total will be needed to make a redemption.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Why do you need my e-mail address?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Your e-mail address will be used to send a confirmation of your order once you complete the
                        checkout process. This e-mail address will not be used for any other purposes.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Why do you need my phone number?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Your phone number will only be used to contact you in the event that we have a question about
                        your order. It will not be used for any other purpose.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

        </div>

        <div *ngIf="selected == 'shipping'">
            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            What are my shipping options?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Available shipping options will be presented at the time of checkout. The shipping options
                        cannot be changed after you have received confirmation of your order. Be aware that we are
                        unable to deliver items to P.O. Box addresses. Orders to international addresses cannot be
                        supported at this time.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Is there a cost for shipping?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Items will be shipped via standard delivery methods for no additional charge. Universal FUNds
                        Visa Prepaid Cards with a delivery address outside the contiguous United States, including
                        Alaska, Hawaii, and Puerto Rico, may incur an additional non-standard handling charge. Orders to
                        international addresses cannot be supported at this time.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Can I ship to multiple addresses?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        You can only ship to one address each time you check out with the Shopping Cart. To ship to
                        multiple addresses, simply complete the checkout process for each individual shipping location
                        with the rewards for that destination.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Can I ship to a P.O. Box?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        No, we cannot ship to a P.O. Box at this time.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Can I ship items internationally?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        At this time, we are unable to ship to addresses outside the United States and Puerto Rico.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How can I change my shipping address?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        On the Shipping page during checkout, you can edit your shipping address to change where you
                        want your items delivered.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

        </div>

        <div *ngIf="selected == 'delivery'">
            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            What are my delivery options?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <ul>
                        <li>
                            <p class="faq-answers-p fnbo-text-body">
                                Standard Delivery
                            </p>
                        </li>
                    </ul>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Will I have to sign for my delivery?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Redemptions are shipped USPS-First Class Mail which does not require a signature.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Can I change my delivery options?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Redemptions are shipped only by USPS-First Class Mail at this time.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How long will standard delivery take?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Redemption items will usually be delivered within 10 business days of the order confirmation.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How do you estimate delivery time?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Delivery time is estimated based on the date the item is redeemed from your Shopping Cart.
                        Standard delivery averages about 10 business days. Once your order has shipped, access shipping
                        information for your package by visiting the Order History page.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            Can I expedite shipping?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Expedited shipping is not available at this time.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            How do I cancel an item?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        All orders are final, non-returnable and non-refundable once your order submission has been
                        confirmed.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>

            <mat-accordion>

                <mat-expansion-panel id=""
                    class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color" hideToggle>
                    <mat-expansion-panel-header class="faq-panel-header-no-padding">
                        <mat-panel-title class="fnbo-primary-color">
                            What if my item has not arrived by the expected delivery date?
                        </mat-panel-title>
                        <span class="material-icons open">add</span>
                        <span class="material-icons close">remove</span>
                    </mat-expansion-panel-header>
                    <p class="faq-answers-p fnbo-text-body">
                        Visit the Order History page or check your Shipping Confirmation email (you will be prompted to
                        log in) in order to view specific delivery details regarding your item, as well as shipping
                        information on your package. You can also click on the Contact Us link on the navigational
                        ribbon and fill out the Contact Us form to report an item not received.
                    </p>

                </mat-expansion-panel>

            </mat-accordion>
          

        </div>

    </div>

</div>