<div id="fnbo-search-dialog-content" class="fnbo-primary-bg">

    <div class="fnbo-display-flex">
        <div class="fnbo-flex-align-right">
            <button id="fnbo-search-dg-close" mat-icon-button class="" (click)="closeSearchDialog()">
                <mat-icon class="">close</mat-icon>
            </button>
        </div>

    </div>


    <div id="fnbo-search-dialog-area" class="fnbo-primary-bg">
        <mat-form-field appearance="outline">
            <input matInput [type]="'search'" placeholder="Search for products or information" [(ngModel)]="searchQuery"
                (keyup.enter)="searchProduct()">
            <mat-icon matPrefix>search</mat-icon>
            <span id="fnbo-search-action" *ngIf="searchQuery" matSuffix (click)="searchProduct()">Search</span>
        </mat-form-field>

        <!-- search result section -->
        <div>
            <div id="fnbo-search-dialog-result">
                <mat-list>
                    <mat-list-item *ngFor="let option of suggestions | searchFilter : searchQuery |slice:0:10"
                    (click)="goToOption(option)">
                        <span class="fnbo-sdg-result-item" 
                        searchHighlight [highlightQueryString]="searchQuery"  [highlightContent]="option"
                        [highlightClass]="'fnbo-search-opt-highlight'" [setTitle]="true"
                          [innerHTML]="option"></span>
                    </mat-list-item>
                    
                  </mat-list>

            </div>
        </div>

    </div>


</div>







