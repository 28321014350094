import { Pipe, PipeTransform } from '@angular/core';
import { CartItemModel, CartShippingClassModel } from '../models/cart';

@Pipe({ name: 'itemShippingOption' })
export class ItemShippingOptionPipe implements PipeTransform {
    transform(array: Array<CartItemModel>, id: number): Array<CartItemModel> {

    if (!array || array.length === 0) {
      return [];
    }

    let filteredObjects = array.filter(i => i.shipmentChoicesId === id);

    return filteredObjects;
  }
}


@Pipe({ name: 'shippingOption' })
export class ShippingOptionPipe implements PipeTransform {
    transform(description: string, classes: CartShippingClassModel[], id : number): string {
      
    if (!classes || classes.length === 0) {
      return description;
    }

    let shippingClass = classes.find(i => i.shipmentChoicesId === id);
    
    if(shippingClass && shippingClass.choices.length > 0){
      return shippingClass.choices[0].name;
    }

    return description;
  }
}
