import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-basic-confirmation-dialog',
  templateUrl: './basic-confirmation-dialog.component.html',
  styleUrls: ['./basic-confirmation-dialog.component.scss']
})
export class BasicConfirmationDialogComponent {

  title : string;
  message : string;

  /**
   *
   */
  constructor(public dialogRef: MatDialogRef<BasicConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string }) {
    
    
  }

  ngOnInit(){
    this.title = this.data.title;
    this.message = this.data.message;
  }

  closeDialog(){
    this.dialogRef.close();
  }

}
