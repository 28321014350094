import { Component, OnInit } from '@angular/core';
import { Subscription, map, switchMap } from 'rxjs';
import { AccountModel } from 'src/core/models/account';
import { CategoryModel, MenuCategory } from 'src/core/models/category';
import { SettingsOptions } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { CatalogService } from 'src/core/services/catalog.service';
import { SettingsService } from 'src/core/services/settings.service';
import { SideNavService } from 'src/core/services/side-nav.service';
import { UtilityService } from 'src/core/services/utility.service';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-side-menu-bar',
  templateUrl: './side-menu-bar.component.html',
  styleUrls: ['./side-menu-bar.component.scss']
})
export class SideMenuBarComponent implements OnInit {

  
  username : any = "Unknown";
  largeLogoUrl: any = "";
  smallLogoUrl: any = "";
  rootCategories :  CategoryModel[];
  showFiller = false;
  sideNavOpen : any = false;
  accountInfo: AccountModel;

  subscriptionAccount: Subscription;
  panelOpenState: boolean= false;
  menuCats : MenuCategory[];
  closeAllPanel = undefined;
  subscriptions: Subscription[];

  TRAVELID = 550000000;
  NONVISIBLE = 1; 
  
  constructor(private settings: SettingsService, 
    private catalogService: CatalogService, private accountService: AccountService,
    private sidenav: SideNavService,  private utilityService: UtilityService, public dialog: MatDialog) {
      this.subscriptions = [];
     }

  ngOnInit(): void {

    this.setup();
    this.sideNavOpen = false;

  }


  setup(): void {

    this.connectSubscriptions();

    this.catalogService.getCategories();
  }


  connectSubscriptions(){

    //getting the categories for the menu 
    let sub = this.catalogService.getMenuCats$().subscribe(menuCats=>{
      this.menuCats = menuCats.sort((a,b) => {
        return (a.rootCat.displayOrder < b.rootCat.displayOrder)? 1 : -1;
      });
    });

    this.subscriptions.push(sub);


    sub =  this.catalogService.rootCategories$.subscribe(rootCategories =>{

      if(rootCategories && rootCategories.length > 0){

        this.rootCategories = rootCategories;
      }else{
      //TODO : redirect if there isn't any categoreis #Error
      }
    });

    this.subscriptions.push(sub);
    
      sub = this.accountService.accountInfo$.pipe(switchMap(accountInfo => {
      return this.settings.$programSettings.pipe(map(programSettings => ({ accountInfo, programSettings })))
    })).subscribe(({ accountInfo, programSettings }) => {
      this.utilityService.addLoadingCallsCount(1);

      if(accountInfo){
        this.accountInfo = accountInfo;
        this.username = accountInfo?.firstName || 'Unknown';
      }

      //global loading
      this.utilityService.addCompletedLoadingCalls(1);
    });

    

    this.subscriptions.push(sub);
  }
  
  toggleSideNav(){
    this.sidenav.toggle();
    this.sideNavOpen = !this.sideNavOpen;

  }

  ngOnDestroy(){
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  
  }

  showLogoutConfirmation() {
    //open dialog
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      maxWidth: '760px',
      panelClass: 'fnbo-logout-dialog',
      hasBackdrop: true
    });
  }

  logout(){
    this.showLogoutConfirmation()
  }

  gotoTravel() {
    this.accountService.navigateToTravel();
  }
}
