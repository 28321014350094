import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, of, throwError } from 'rxjs';
import { AccountModel } from '../models/account';
import { CartItemModel, CartShippingClassModel, CartSummaryModel, PartialPayStatus, ShippingCatalogModel, ShippingOptionModel } from '../models/cart';
import { ProductOptions } from '../models/settings';
import { HttpWrapperService } from './http-wrapper.service';
import { MessagingService } from './messaging.service';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  userName: string;
  cashBackOnly: boolean = false;


  cartSummary: CartSummaryModel = new CartSummaryModel();

  canRedeem: boolean = true;
  //set to true if the cart has a cashback product
  cashBackProductExist: boolean = false;

  // Shipping options for items in the cart (vary by catalog)
  shippingCatalogs: ShippingCatalogModel[];
  shippingOptions: ShippingOptionModel[];
  cartShippingClasses: CartShippingClassModel[] = [];

  // Amount of points available in the user account (set by UserAccount service)
  userAccountPoints: number = 0;
  partialPayPointsTotal: number;
  partialPayCentsTotal: number;
  totalPoints: number;
  totalPayCents: number = 0;
  isDisabled: boolean = false;
  isDisabledCart: boolean = false;
  isChecked: boolean = false;
  isItemValid: boolean = true;
  todayPoints: number = 0;

  private _cartSource: BehaviorSubject<CartSummaryModel>;

  $cart: Observable<CartSummaryModel>;

  private _partialPayPoints: BehaviorSubject<number>;
  $partialPayPoints: Observable<number>;

  private _partialPayCents: BehaviorSubject<number>;
  $partialPayCents: Observable<number>;

  private _totalPoints: BehaviorSubject<number>;
  $totalPoints: Observable<number>;
  private _resetComboBox: BehaviorSubject<boolean>;
  $resetComboBox: Observable<boolean>;

  constructor(private http: HttpWrapperService,
    private messagingService: MessagingService,
    private settingsService: SettingsService) {

    this._cartSource = new BehaviorSubject<CartSummaryModel>(this.cartSummary);
    this.$cart = this._cartSource.asObservable();

    this._partialPayPoints = new BehaviorSubject<number>(this.partialPayPointsTotal);
    this.$partialPayPoints = this._partialPayPoints.asObservable();

    this._partialPayCents = new BehaviorSubject<number>(this.partialPayCentsTotal)
    this.$partialPayCents = this._partialPayCents.asObservable();

    this._totalPoints = new BehaviorSubject<number>(this.totalPoints);
    this.$totalPoints = this._totalPoints.asObservable();

    this._resetComboBox = new BehaviorSubject<boolean>(false);
    this.$resetComboBox = this._resetComboBox.asObservable();

    this.getShippingInfo();

    //initial load of thesite to check for for the cart
    this.getFullCartList();

  }

  // Public service methods
  addItem(item: CartItemModel) {

    this.settingsService.$programSettings.subscribe(
      settings => {
        if (settings != undefined) {
          if (settings.length > 0) {
            let _setting = settings.filter(d => d.key === 'cshbkoly');

            if (_setting && _setting.length > 0) {
              this.cashBackOnly = true;
            }
            else {
              this.cashBackOnly = false;
            }
          }
        }
        else {
          this.cashBackOnly = false;
        }
      }
    );

    if (!this.isCartItemValid(item)) {
      return false;
    }
    // Get the curent cart if any exists
    const cart: CartItemModel[] = this.getCart();

    // Determine if the item is already in the cart
    const cartItem = cart.find(i => i.itemId === item.itemId);

    // If an item already exists in the cart, then call updateCartItem instead
    if (cartItem) {

      //you can't get more than 1 synapse or cash back item
      if (((cartItem.brand.toLowerCase() != 'synapse') && ((cartItem.brand != 'FBO_Cashback') && !this.cashBackOnly))) {
        this.updateItem(cartItem.itemId, item.quantity);
      }

    } else {


      //Don't add another cash back item even though they are different product types
      const isCashBackInCart = cart.find(i => i.brand === 'FBO_Cashback');

      //Bail out if we already have a cash back item
      if ((isCashBackInCart) && ((item.brand == 'FBO_Cashback') && this.cashBackOnly))
        return;

      if (item.brand === ProductOptions.CASHBACK.toString()) {
        this.cashBackProductExist = true;
      }
      cart.push(item);

      // Reset partial pay checkbox flag
      this.isChecked = false;

      // Recalculate possible shipping options for the cart items
      this.determineCartShippingOptions(cart);

      this.saveCart(cart);

    }
    return true;

  }

  removeItem(itemId: number) {
    // Get the curent cart if any exists
    let cart: CartItemModel[] = this.getCart();
    cart = cart.filter(obj => {

      if (obj.itemId !== itemId) {
        return true;
      }

      //only one cashback should be available in the cart
      //if item is found and it is cashback, we reset the cashback
      //flag to false
      if (obj.brand === ProductOptions.CASHBACK.toString()) {
        this.cashBackProductExist = false;
      }
      return false;

    });

    // Reset partial pay checkbox flag
    this.isChecked = false;

    // Recalculate possible shipping options for the cart items
    this.determineCartShippingOptions(cart);

    this.saveCart(cart);
  }

  resetCombo() {
    this._resetComboBox.next(true);
  }

  updateItem(id: number, qty: number) {
    // Get the curent cart if any exists

    const cart: CartItemModel[] = this.getCart();

    const findItem = cart.find(i => i.itemId === id);

    if (findItem) {
      findItem.quantity = findItem.quantity + qty;
      findItem.pointsExtended = findItem.quantity * findItem.points;

    }

    // Reset partial pay checkbox flag
    this.isChecked = false;

    // Recalculate possible shipping options for the cart items
    this.determineCartShippingOptions(cart);

    this.saveCart(cart);
  }

  getCartItems(): Observable<CartItemModel[]> {
    return of<CartItemModel[]>(this.getCart());
  }

  //TODO: this method creates a infinite loop if called wrong
  public getCart(): CartItemModel[] {
    // Retrieve the cart details from local storage

    let cart: CartItemModel[];
    const cartString = sessionStorage.getItem('cart');

    if (cartString) {
      cart = JSON.parse(cartString);
    } else {
      cart = [];
    }
    // Calculate cart summary data
    this.summarizeCart(cart);

    // Broadcast the cart change to initialize the cart through the service
    this.cartChange(this.cartSummary);
    return cart;
  }

  getFullCartList(): CartItemModel[] {
    let cart: CartItemModel[];
    const cartString = sessionStorage.getItem('cart');

    if (cartString) {
      cart = JSON.parse(cartString);
    } else {
      cart = [];
    }

    // Calculate cart summary data
    this.summarizeCart(cart);
    return cart;
  }


  private saveCart(cart: CartItemModel[]) {

    // Persist the cart to storage
    const cartString = JSON.stringify(cart);
    sessionStorage.setItem('cart', cartString);

    // Calculate cart summary data
    this.summarizeCart(cart);

    // Broadcast the cart change
    this.cartChange(this.cartSummary);
  }

  public summarizeCart(cart: CartItemModel[]): CartSummaryModel {

    // Populate the cart summary
    this.cartSummary.itemCount = 0;
    this.cartSummary.isOrderShippable = false;
    this.cartSummary.pointsTotal = 0;
    this.cartSummary.totalCost = 0;
    this.cartSummary.totalFace = 0;


    if (sessionStorage.getItem("accountInfo") !== null) {

      let accountInfo = <AccountModel>JSON.parse(sessionStorage.getItem('accountInfo')!);
      if (accountInfo != null || accountInfo !== '') {

        this.userAccountPoints = accountInfo.points;
      } else {
        //TODO: do something if cannot get the points
        this.userAccountPoints = 0; // set it to default if it fails
      }
    }



    this.cartSummary.hasShippingOptions = true;
    // Start with the widest available option for partial pay and then restrict
    // after we compute cart details and account point avialability.
    this.cartSummary.partialPayStatus = PartialPayStatus.PartialPaySufficientPoints;

    // Walk through the cart and summarize items, points, and partial pay status.
    cart.forEach(i => {
      this.cartSummary.itemCount = this.cartSummary.itemCount + i.quantity,
        this.cartSummary.pointsTotal = this.cartSummary.pointsTotal + i.pointsExtended;

      // Check the cart items to see if we are even allowed to partial pay (all items
      // in the cart must allow partial pay to be able to partial pay for the order).
      if (i.canPartialPay === false) {
        this.cartSummary.partialPayStatus = PartialPayStatus.PointsOnlySufficientPoints;
      }

      // if any item of this order is shippable, then the order is shippable.
      if (i.isItemShippable) {
        this.cartSummary.isOrderShippable = true;
      }

      // Add up the vendor cost of this item
      this.cartSummary.totalCost = this.cartSummary.totalCost + (i.quantity * i.actualCost);

      // Add up the total face value of cart items
      this.cartSummary.totalFace = this.cartSummary.totalFace + (i.quantity * i.faceValue);
    });

    // If we are only able to pay with points and don't have sufficient points available,
    // then we can't check out.
    if (((this.cartSummary.partialPayStatus as PartialPayStatus) === PartialPayStatus.PointsOnlySufficientPoints) && (this.userAccountPoints < this.cartSummary.pointsTotal)) {
      this.cartSummary.partialPayStatus = PartialPayStatus.PartialPayInsuffientPointsCannotPartialPay;
    } else if (((this.cartSummary.partialPayStatus as PartialPayStatus) === PartialPayStatus.PartialPaySufficientPoints) && (this.userAccountPoints < this.cartSummary.pointsTotal)) {
      // We don't have enough points to check out.  Check if we have met the minimum point balance to
      // be able to partial pay in the first place.
      if (this.userAccountPoints >= 1000) {
        // The user must partial pay to be able to check out.
        this.cartSummary.partialPayStatus = PartialPayStatus.PartialPayInsuffientPointsMustPartialPay;
      } else {
        // The user does not meet the minimum point business rule in order to be able to partial pay.
        // Therefore we are not able to complete check-out.
        this.cartSummary.partialPayStatus = PartialPayStatus.PartialPayInsuffientPointsCannotPartialPay;
      }
    } else if (((this.cartSummary.partialPayStatus as PartialPayStatus) === PartialPayStatus.PartialPaySufficientPoints) && (this.userAccountPoints > this.cartSummary.pointsTotal)) {
      // We don't have enough points to check out.  Check if we have met the minimum point balance to
      // be able to partial pay in the first place.
      if (this.userAccountPoints >= 1000 && this.cartSummary.pointsTotal >= 1000) {
        // The user must partial pay to be able to check out.
        this.cartSummary.partialPayStatus = PartialPayStatus.PartialPaySufficientPoints;
      } else {
        // The user does not meet the minimum point business rule in order to be able to partial pay.
        // Therefore we are not able to complete check-out.
        this.cartSummary.partialPayStatus = PartialPayStatus.PointsOnlySufficientPoints;
      }
    }


    this.cartSummary.cardTotal = Math.round(this.cartSummary.cardTotal * 100) / 100;

    return this.cartSummary;
  }

  public determineCartShippingOptions(cart: CartItemModel[]) {

    this.cartShippingClasses = [];

    cart.forEach(i => {

      // Determine the shipping options for the given item in the cart (by shipping classes)

      if (!this.cartShippingClasses.find(s => s.shipmentChoicesId === i.shipmentChoicesId)) {
        const newShipClass: CartShippingClassModel = new CartShippingClassModel();

        newShipClass.shipmentChoicesId = i.shipmentChoicesId;
        newShipClass.name = this.getShippingCatalogName(i.shipmentChoicesId);
        newShipClass.choices = this.getShippingChoices(i.shipmentChoicesId);

        newShipClass.selectedOption = newShipClass.choices[0].id;

        this.cartShippingClasses.push(newShipClass);
      }

    });

  }

  private getShippingInfo() {
    this.http.get('api/shipping/catalogs').pipe(catchError(error => this.handleError(error)))
      .subscribe(
        cats => {
          this.shippingCatalogs = cats

          if (this.shippingCatalogs)
            sessionStorage.setItem('shippingCatalogs', JSON.stringify(this.shippingCatalogs));
        }
      );

    this.http.get('api/shipping/options').pipe(catchError(error => this.handleError(error)))
      .subscribe(
        opts => {
          this.shippingOptions = opts

          if (this.shippingOptions)
            sessionStorage.setItem('shippingOptions', JSON.stringify(this.shippingOptions));
        }
      );
  }

  getShippingCatalogName(id: number): string {

    if (!this.shippingCatalogs) {
      if (sessionStorage.getItem("shippingCatalogs") !== null)
        this.shippingCatalogs = JSON.parse(sessionStorage.getItem('shippingCatalogs') || new ShippingCatalogModel[0]);
    }

    const cat = this.shippingCatalogs.find(c => c.id === id);
    let name: string = '';

    if (cat !== null && cat !== undefined) {
      name = cat.name;
    }

    return name;
  }

  getShippingChoices(id: number): ShippingOptionModel[] {

    if (!this.shippingCatalogs) {
      let _shippingCatalogs = sessionStorage.getItem("shippingCatalogs");
      if (_shippingCatalogs !== null)
        this.shippingCatalogs = JSON.parse(_shippingCatalogs || ShippingCatalogModel[0]);
    }

    if (!this.shippingOptions) {
      let _shippingOptions = sessionStorage.getItem("shippingOptions");
      if (_shippingOptions !== null)
        this.shippingOptions = JSON.parse(_shippingOptions || ShippingOptionModel[0]);
    }

    const catalog = this.shippingCatalogs.find(c => c.id === id);
    const choices: ShippingOptionModel[] = [];

    if (catalog !== null && catalog !== undefined) {
      catalog.options.forEach(o => {
        const option = this.shippingOptions.find(opt => opt.id === o);

        if (option !== null && option !== undefined) {
          choices.push(option);
        }
      });
    }

    return choices;
  }

  cartChange(cartSummary: CartSummaryModel) {

    this.settingsService.$programSettings.subscribe(
      settings => {
        if (settings != undefined) {
          if (settings.length > 0) {
            let _setting = settings.filter(d => d.key === 'cshbkoly');

            if (_setting && _setting.length > 0) {
              this.cashBackOnly = true;
            }
            else {
              this.cashBackOnly = false;
            }
          }
        }
        else {
          this.cashBackOnly = false;
        }
      }
    );

    //Fix for the button not showing after a refresh
    if (sessionStorage.getItem("accountInfo") !== null) {
      let accountInfo = JSON.parse(sessionStorage.getItem('accountInfo') || '');

      if (accountInfo !== '') {

        this.userAccountPoints = accountInfo.points;
      }
    }

    this.userName = sessionStorage.getItem('csr_name') || '';
    // Broadcast the cart change to all listeners
    this._cartSource.next(cartSummary);

    if (this.userAccountPoints < 1000) {

      if (this.canRedeem) {
        this.messagingService.onMessageCall('Sorry, you need to have a minimum of 1000 points to redeem for any item.');
      }


      this.isDisabled = true;

    } else if ((cartSummary.partialPayStatus as PartialPayStatus) === PartialPayStatus.PartialPayInsuffientPointsCannotPartialPay) {
      //this.messagingService.onMessageCall('Sorry, you do not have enough points to complete your order.');
      this.isDisabled = true;


    } else if ((cartSummary.partialPayStatus as PartialPayStatus) === PartialPayStatus.PartialPayInsuffientPointsMustPartialPay) {
     

      this.isDisabled = true;

    }

    else if (this.todayPoints + this.cartSummary.totalFace >= 10000) {

      this.isDisabled = true;

      this.isItemValid = false;
    } else {
      if (this.canRedeem) {
        this.messagingService.onMessageCall('');
      }

      this.isItemValid = true;
      this.isDisabled = false;

    }

  }

  partialPayChange(partialPayPointsTotal: number, partialPayCentsTotal: number, total: number) {

    // Store the partial pay points total locally for use later in the cart flow.

    this.partialPayPointsTotal = partialPayPointsTotal;
    this.partialPayCentsTotal = partialPayCentsTotal;

    // Set amount to pay on card.
    this.cartSummary.cardTotal = partialPayCentsTotal;

    // Update the status of the cart based on the change state of partial pay
    if (this.todayPoints + this.cartSummary.totalFace >= 10000) {
      this.isDisabled = true;

      this.isItemValid = true;
    }

    // Broadcast the partialPay change to all listeners.
    this._partialPayPoints.next(partialPayPointsTotal);
    this._partialPayCents.next(partialPayCentsTotal);
    this._totalPoints.next(total);
  }

  public clearCart() {
    this.cashBackProductExist = false;
    this.saveCart([]);
    this.resetCartService();
  }

  private resetCartService() {
    this.cartSummary = {
      itemCount: 0,
      pointsTotal: 0,
      shippingTotal: 0,
      cardTotal: 0,
      cashTotal: 0,
      hasShippingOptions: false,
      canRedeem: true,
      partialPayStatus: PartialPayStatus.PointsOnlySufficientPoints,
      isOrderShippable: false,
      totalCost: 0,
      totalFace: 0
    };

    // Not sure if all of these need to be reset.
    this.partialPayPointsTotal = 0;
    this.partialPayCentsTotal = 0;
    this.totalPoints = 0;
    this.totalPayCents = 0;
    this.isDisabled = false;
    this.isChecked = false;
  }

  // When a shipping option is chosen for a shipping
  // class, update any associated items in the cart
  public updateCartShippingSelections() {
    // Get the curent cart if any exists

    const cart: CartItemModel[] = this.getFullCartList();
    this.cartSummary.shippingTotal = 0;

    // For each shipping class, if we have selected an option, then
    // assign associated cart item shipping options for that shipping class.
    this.cartShippingClasses.forEach(sc => {

      if (sc.selectedOption) {

        let filteredList: CartItemModel[] = [];

        filteredList = cart.filter(function (item) { return item.shipmentChoicesId === sc.shipmentChoicesId });

        const choice = sc.choices.find(c => c.id === sc.selectedOption) || new ShippingOptionModel();

        filteredList.forEach(i => {
          i.selectedShippingOptionDescription = choice.description;
          i.selectedShippingOptionId = choice.id;

        });

        // Update the cart summary with the new shipping total
        this.cartSummary.shippingTotal = this.cartSummary.shippingTotal + choice.cost;

        // Determine the total amount to put on the card
        this.cartSummary.cashTotal = this.cartSummary.shippingTotal + this.cartSummary.cardTotal;

        this.saveCart(cart);

      }
    });

    cart.forEach(i => {

      // Determine the shipping options for the given item in the cart (by shipping classes)

      if (!this.cartShippingClasses.find(s => s.shipmentChoicesId === i.shipmentChoicesId)) {
        const newShipClass: CartShippingClassModel = new CartShippingClassModel();

        newShipClass.shipmentChoicesId = i.shipmentChoicesId;
        newShipClass.name = this.getShippingCatalogName(i.shipmentChoicesId);
        newShipClass.choices = this.getShippingChoices(i.shipmentChoicesId);

        // If we only have one choice, then set it automatically for redemption
        if (newShipClass.choices.length === 1) {
          newShipClass.selectedOption = newShipClass.choices[0].id;
        }

        this.cartShippingClasses.push(newShipClass);
      }

    });

  }

  public populateTodayTotalRedemptionPoints() {

    this.http.get('api/redemption/getTotalRedeemedPoints').pipe(catchError(error => this.handleError(error)))
      .subscribe(points => {
        this.todayPoints = points;
      })
  }

  isCartItemValid(item: CartItemModel) {
    if (!item.recipientInfo && !item.bankAccountNumber && !item.bankRoutingNumber) {
      return true;
    }

    const recipientNumber = (item.recipientInfo) ? Number(item.recipientInfo) : null;
    const bankAccountNumber = (item.bankAccountNumber) ? Number(item.bankAccountNumber) : null;
    const bankRoutingNumber = (item.bankRoutingNumber) ? Number(item.bankRoutingNumber) : null;

    if (bankAccountNumber === 0 || bankRoutingNumber === 0 || recipientNumber === 0) {
      this.messagingService.onMessageCall('Values should not be all zeros.');
      this.isDisabled = true;

      this.isItemValid = false;
      return false;
    } else {
      this.messagingService.onMessageCall('');
    }

    return true;
  }

  isCartValid() {
    if (this.userAccountPoints < 1000) {

      if (this.canRedeem) {
        this.messagingService.onMessageCall('Sorry, you need to have a minimum of 1000 points to redeem for any item.');
      }


      this.isDisabled = true;

    } else if ((this.cartSummary.partialPayStatus as PartialPayStatus) === PartialPayStatus.PartialPayInsuffientPointsCannotPartialPay) {

      this.isDisabled = true;


    } else if ((this.cartSummary.partialPayStatus as PartialPayStatus) === PartialPayStatus.PartialPayInsuffientPointsMustPartialPay) {

      this.isDisabled = true;

    }
  }

  /**
   * returns if the cart has 
   * @returns boolean
   */
  hasCashBackProduct(): boolean {
    return this.cashBackProductExist;
  }

  isProductInCart(id): CartItemModel {
    let cart: CartItemModel[];
    const cartString = sessionStorage.getItem('cart');

    if (cartString) {
      cart = JSON.parse(cartString);
    } else {
      cart = [];
    }

    let cartItem: CartItemModel = undefined!;

    if (cart) {
      cartItem = cart.find(x => x.itemId == id)!;
    }
    return cartItem;

  }

  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {

      const body = error.json() || '';

      var err = "";
      body.then(er => {
        err = er.error || JSON.stringify(body);
      })

      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }

    return throwError(() => new Error(errMsg));
  }
}