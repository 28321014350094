export class ProductModel {
    public id: number;
    public name: string;
    public imageurl: string;
    public largeImage: string;
    public smallImage: string;
    public description: string;
    public detailView?: boolean = false;
    public isStartingAt: boolean;
    public startingAt: number;
    public partialPayIndicator: boolean;
    public items: ItemModel[];
    public brand: string;
    public cashbackPaymentType: string;
    public shipmentChoicesId: number;
    public isOnSale: boolean = false;
    public tandC: string;
    public productBPSRate: number;
    public csrOnly: boolean = true;
    public catId: any[]
    public altName:string;
}



export class ItemModel {
    public code: string;
    public description: string;
    public displayOrder: number;
    public inventoryItemId: number;
    public inventoryItemName: string;
    public smallImageUrl: string;
    public largeImageUrl: string;
    public memberCost: number;
    public memberCostDenomination: string;
    public vendorCost: number;
    public vendorId: number;
    public bankAccountNumber: string;
    public bankRoutingNumber: string;
    public defaultValue: string = 'Selects an Item';
    public isOnSale: boolean = false;
    public shipOptionIds: number[];
    public faceValue: number;
}

export class FilteredProductModel {
    public programCode: string;
    public categoryId: number;
    public brand: string;
    public priceRange: string;
    public onSale: boolean;
    public sortDescending: boolean;
    public page: number;
    public productSearch: string;
}