import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  private _canRedeemMessage: BehaviorSubject<string>;
  private _disabled: BehaviorSubject<boolean>;
  private _isAddDisabled: BehaviorSubject<boolean>;

  // Observable streams
  $canRedeemMessage : Observable<string>;
  $disabled  : Observable<boolean>;
  $isAddDisabled :  Observable<boolean>;


  /**
   *
   */
  constructor() {

    this._canRedeemMessage = new BehaviorSubject<string>('');
    this._disabled = new BehaviorSubject<boolean>(false);
    this._isAddDisabled = new BehaviorSubject<boolean>(false);

    this.$canRedeemMessage = this._canRedeemMessage.asObservable();
    this.$disabled = this._disabled.asObservable();
    this.$isAddDisabled = this._isAddDisabled.asObservable();

  }
  onMessageCall(message) {
    this._canRedeemMessage.next(message);
  }
  isDisabled(disabled) {
    this._disabled.next(disabled);
  }
  isDisabledAdd(disabled) {
    this._isAddDisabled.next(disabled);
  }

}
