<ng-container *ngIf="!loading">

    <ngb-carousel *ngIf="!showMobile && promoBanners.length > 0" [showNavigationArrows]="(promoBanners.length > 1)"
        [showNavigationIndicators]="false" [interval]="12000" [keyboard]="true" [pauseOnHover]="true" [wrap]="true">
        <ng-template *ngFor="let banner of promoBanners; let i = index" ngbSlide>
            <div class="fnbo-banner-img">
                <ng-container
                    *ngTemplateOutlet="bannerImageTemplate; context: {img:banner.img, link:banner.link, index:i, text:banner.text }"></ng-container>
            </div>
        </ng-template>
    </ngb-carousel>
    <ngb-carousel *ngIf="showMobile && promoMobileBanners" class="fnbo-carosuel-mb "
        [showNavigationArrows]="(promoMobileBanners.length > 1)" [showNavigationIndicators]="false" [interval]="12000"
        [keyboard]="true" [pauseOnHover]="true" [wrap]="true">
        <ng-template *ngFor="let banner of promoMobileBanners; let i = index" ngbSlide>
            <div class="picsum-img-wrapper fnbo-banner-img">
                <ng-container
                    *ngTemplateOutlet="bannerImageTemplate; context: {img:banner.img, link:banner.link, index:i, text:banner.text}"></ng-container>
            </div>
        </ng-template>
    </ngb-carousel>

</ng-container>

<ng-template #bannerImageTemplate let-link="link" let-img="img" let-index="i" let-text="text">
    <a *ngIf="link" class="fnbo-home-carosuel" [href]="link">

        <img src="{{img}}" alt="banner {{index}} image" />
    </a>

    <div *ngIf="!link" class="fnbo-home-carosuel">
        <img src="{{img}}" alt="banner {{index}} image" />
    </div>
    <ng-container *ngIf="text">
        <div>
            <div [innerHTML]="text" class="cover-banner-text"></div>
        </div>
    </ng-container>
</ng-template>