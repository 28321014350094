/**
 * A custom wrappper class for the http calls
 */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments';

@Injectable()
export class HttpWrapperService {


    private _errorMessage: any;
    errorMessage$: any;

    constructor(private http: HttpClient, private router: Router) {
        this._errorMessage = new BehaviorSubject<string>('');
        this.errorMessage$ = this._errorMessage.asObservable();
    }

    // Public service methods

    get(url: string, options?: any): Observable<any> {
        return this.sendRequest("GET", environment.urlBase + url, options);
    }

    post(url: string, body: any, options?: any): Observable<any> {
        options.body = body;
        return this.sendRequest("POST", environment.urlBase + url, options);
    }

    delete(url: string, options?: any): Observable<any> {

        return this.sendRequest("DELETE", environment.urlBase + url, options);
    }


    patch(url: string, body: any, options?: any): Observable<any> {
        options.body = body;
        return this.sendRequest("PATCH", environment.urlBase + url, options);
    }

    put(url: string, body: any, options?: any): Observable<any> {
        options.body = body;
        return this.sendRequest("PUT", environment.urlBase + url, options);
    }

    head(url: string, options?: any): Observable<any> {

        return this.sendRequest("HEAD", environment.urlBase + url, options);
    }

    // Helper methods

    private addAuthorizationHeader(options: any): any {

        // Check for bearer token in local storage and add if necessary


        const arn = sessionStorage.getItem('arn');
        const token = sessionStorage.getItem('access_token');


        if (options == undefined || options == null) {
            options = {}
        }

        if (options.headers == undefined || options.headers == null || !options.headers) {
            var headers: HttpHeaders = new HttpHeaders(); // { 'content-type': 'application/json' }
            options.headers = headers;
        }

        // Add the authorization token to the headers collection if it exists
        if (token != null) {


            if (!options.headers.has('authorization')) {

                options.headers = options.headers.append('authorization', 'bearer ' + token);
            }
        }

        // Add the arn to the headers collection if it exists
        if (arn != null) {


            if (!options.headers.has('x-auth-arn')) {
                options.headers = options.headers.append('x-auth-arn', arn);
            }
        }

        return options;
    }

    public createHeader(name: string, value: string): any {

        var options: any = {};
        var headers: Headers = new Headers();
        options.headers = headers;
        options.headers.set(name, value);

        return options;
    }

    private sendRequest(requestType: any, url: string, options: any): Observable<Response> {

        let newOptions = this.addAuthorizationHeader(options);

        var result = this.http.request(requestType, url, newOptions).pipe(
            catchError(error => {
                return this.handleRequestError(requestType, url, error, newOptions);


            })
        );


        return result;
    }

    private refreshToken(): Observable<boolean> {

        const headers = new HttpHeaders({ 'content-type': 'application/x-www-form-urlencoded' });
        const body = 'grant_type=refresh_token&refresh_token=' + sessionStorage.getItem("refresh_token");

        if (sessionStorage.getItem("refresh_token") == null) {
            this._errorMessage.next('400');
            this.router.navigate(['error']);
        }

        return this.http.post(environment.urlBase + 'token', body, { headers: headers }).pipe(map((response) => {

            var tokens: any = response;

            if (tokens.access_token !== 'undefined') {
                sessionStorage.setItem('access_token', tokens.access_token);
                sessionStorage.setItem('refresh_token', tokens.refresh_token);
                return true;
            }
            return false;
        }), catchError(e => {
            this.router.navigate(['error']);
            return this.handleError(e)
        }));
    }

    //handle request error
    private handleRequestError(requestType: any, url: any, err: Response | any, options: any): Observable<any> {

        if (err.status === 401) {


            return this.refreshToken().pipe(map((tokenResult) => {

                if (options == undefined || options == null) {
                    options = {}

                }

                //if true, the request will retry
                if (tokenResult) {

                    options.headers = null;
                    options = this.addAuthorizationHeader(options);

                    return this.http.request(requestType, url, options).pipe(catchError(e => {

                        this._errorMessage.next(e.status);
                        this.router.navigate(['error']);
                        return throwError(() => new Error(err));
                    }));
                } else {

                    //session timeout
                    this._errorMessage.next(err.status);
                    this.router.navigate(['error']);
                    
                    if(environment.name !== "prod"){
                        console.log("req url : ", url)
                    }
                    
                    return throwError(() => new Error(err));

                }

            }));

        } else {

            if (err.status > 401 || err.status < 200) {
                this._errorMessage.next(err.status);
                this.router.navigate(['error']);
            }
           
            if(environment.name !== "prod"){
                console.log("req url : ", url)
            }
            
            return throwError(() => new Error(err));

        }

    }

    //TODO: double check; might fail
    private handleError(error: Response | any) {
        let errMsg: string;
        if (error instanceof Response) {

            const body = error.json() || '';

            var err = "";
            body.then(er => {
                err = er.error || JSON.stringify(body);
            })

            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        } else {
            errMsg = error.message ? error.message : error.toString();
        }

        return throwError(() => new Error(errMsg));
    }
}
