import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/core/shared.module';
import { HttpWrapperService } from 'src/core/services/http-wrapper.service';
import { SettingsService } from 'src/core/services/settings.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CatalogService } from 'src/core/services/catalog.service';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SideNavService } from 'src/core/services/side-nav.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ProductsModule } from './products/products.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StyleService } from 'src/core/services/style.service';
import { UtilityService } from 'src/core/services/utility.service';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { SearchResultsComponent } from './search-results/search-results.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { FaqComponent } from './faq/faq.component';
import { FAQService } from 'src/core/services/faq.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeModule } from './home/home.module';
import { ContactService } from 'src/core/services/contact.service';
import { OrderService } from 'src/core/services/order.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { CheckoutModule } from './checkout/checkout.module';
import { CartModule } from './cart/cart.module';
import { PaymentService } from 'src/core/services/payment.service';
import { OrderHistoryModule } from './order-history/order-history.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { GoodbyeComponent } from './goodbye/goodbye.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ErrorComponent } from './error/error.component';
import { TravelComponent } from './travel/travel.component';


@NgModule({
  declarations: [
    AppComponent,
    ContactUsComponent,
    SearchResultsComponent,
    FaqComponent,
    GoodbyeComponent,
    PageNotFoundComponent,
    ErrorComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    HomeModule,
    ProductsModule,
    CartModule,
    CheckoutModule,
    OrderHistoryModule,
    MatSidenavModule,
    MatIconModule,
    NgxPaginationModule,
    MatSelectModule,
    MatButtonModule,
    MatGridListModule,
    MatOptionModule,
    MatSelectModule,
    MatExpansionModule,
    MatCheckboxModule,
    NgbModule,
    NgIdleKeepaliveModule.forRoot()
  ],
  providers: [
    HttpWrapperService,
    SettingsService,
    CatalogService,
    SideNavService,
    StyleService,
    UtilityService,
    ContactService,
    OrderService,
    FAQService,
    PaymentService,
    {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
