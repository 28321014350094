<mat-toolbar id="fnbo-menu-bar">
  <mat-toolbar-row>
    <!-- hamburger button to toggle menu options in mobile view -->
    <button mat-icon-button class="web-hidden " (click)="toggleSideNav()">
      <mat-icon class="fnbo-primary-color">menu</mat-icon>

    </button>
    <div class="fnbo-menu-bar-logo mobile-hidden">
      <a [routerLink]="'/'">
        <img [src]="largeLogoUrl" alt="Company logo in top menu" (error)="setDefaultImg($event)"/>
      </a>
    </div>
    <div class="fnbo-menu-bar-logo web-hidden">
      <a [routerLink]="'/'">
        <img [src]="smallLogoUrl" alt="Company logo in top menu" (error)="setDefaultImg($event)" />
      </a>
    </div>

    <span class="menu-spacer"></span>

    <!-- Main menu -->
    <div class="mobile-hidden fnbo-primary-color fnbo-menu-main-links"
      [ngStyle]="{'justify-content' : menuCats && menuCats.length <= 1 ? 'start' : 'space-between'} ">

      <div class="fnbo-menu-main-link">
        <a mat-button routerLink="/">Home</a>
      </div>
      <ng-container *ngIf="menuCats && menuCats.length > 0">


        <div *ngFor="let cat of menuCats" class="fnbo-menu-main-link">

          <ng-container *ngIf="cat.rootCat.id == TRAVELID && cat.rootCat.id != NONVISIBLE ">

            <a mat-button (click)="gotoTravel()">{{cat.rootCat.name}}</a>
          </ng-container>

          <ng-container *ngIf="cat.rootCat.id !== TRAVELID && cat.rootCat.id != NONVISIBLE ">

            <a mat-button routerLink="products/{{cat.rootCat.code}}">{{cat.rootCat.name}}</a>
          </ng-container>

          <div *ngIf="cat.rootCat.id != NONVISIBLE" class="fnbo-mbar-subnav fnbo-primary-bg">
            <a *ngFor="let children of cat.children" class="" mat-button
              routerLink="products/{{children.code}}">{{children.name}}</a>

          </div>
        </div>
      </ng-container>
    </div>


    <!-- secondary menu -->
    <div id="fnbo-menu-sec" class="fnbo-flex-align-right">


      <div class="fnbo-primary-color fnbo-menu-sec-links">
        <a class="" mat-button routerLink="faq"> FAQ </a>
        <a class="" mat-button routerLink="contact-us">Contact Us</a>
        <button mat-icon-button class="" (click)="openSearchDialog()">
          <mat-icon class="fnbo-primary-color">search</mat-icon>
        </button>

      </div>


      <div>
        <app-menu-account-area></app-menu-account-area>
      </div>
    </div>
  </mat-toolbar-row>

</mat-toolbar>