import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CartItemModel } from 'src/core/models/cart';
import { ProductModel } from 'src/core/models/product';
import { SettingsOptions } from 'src/core/models/settings';
import { FAQService } from 'src/core/services/faq.service';
import { SettingsService } from 'src/core/services/settings.service';

@Component({
  selector: 'app-terms-conditions-dialog',
  templateUrl: './terms-conditions-dialog.component.html',
  styleUrls: ['./terms-conditions-dialog.component.scss']
})
export class TermsConditionsDialogComponent {

  products: ProductModel[];
  cartItems: CartItemModel[];
  defaultTNC: any;
  termsNCPath : string = "DefaultTermsAndConditions.html";
  title: any;

  faqSub :Subscription;

  /**
   *
   */
  constructor(public dialogRef: MatDialogRef<TermsConditionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:
      { title: string, products: ProductModel[], cartItems: CartItemModel[], showDefault: boolean }, private faqService: FAQService,
      private settings : SettingsService) { }

  async ngOnInit() {
    this.setTNCPath();

    if(this.data.showDefault){
      this.defaultTNC =  await this.faqService.getTaCsFile(this.termsNCPath);
    }

    if (this.data.products) {
      this.products = this.data.products;
    } 

    if(this.data.cartItems){
      this.cartItems = this.data.cartItems;
    }

    this.title = this.data.title;
  }

  setTNCPath(){
    
    this.termsNCPath = this.settings.getProgramSetting(SettingsOptions.REDEMPTION_TERMS.toString());
    
    if(!this.termsNCPath || this.termsNCPath.length < 1){
      this.termsNCPath = this.settings.getDefaultSetting(SettingsOptions.REDEMPTION_TERMS.toString());
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy(){
    if(this.faqSub){

      this.faqSub.unsubscribe();
    }
  }
}
