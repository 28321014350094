import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription} from 'rxjs';
import { CategoryModel } from 'src/core/models/category';
import {  UNIVERSALPROGRAMS } from 'src/core/models/settings';
import { CatalogService } from 'src/core/services/catalog.service';

import { TermsConditionsDialogComponent } from '../terms-conditions-dialog/terms-conditions-dialog.component';
import { LogoutConfirmationComponent } from '../logout-confirmation/logout-confirmation.component';
import { AccountService } from 'src/core/services/account.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {


  largeLogoUrl: any = "";
  smallLogoUrl: any = "";
  rootCategories: CategoryModel[];

  subscriptions: Subscription[];
  currentYear: any = '2023';
  TRAVELID = 550000000;
  NONVISIBLE = 1;

  showShopCat : boolean = true;
  isUniversal : boolean = false;


  constructor(public dialog: MatDialog,
    private catalogService: CatalogService, private accountService: AccountService,
    private router: Router) {

    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.currentYear = (new Date()).getFullYear();
    this.setup();
  }

  setup(): void {

    this.getRootCategories();

    let sub = this.accountService.accountInfo$.subscribe(x=>{
      
      if(x && UNIVERSALPROGRAMS.includes(this.accountService.accountInfo.programCode) ){
        this.isUniversal = true;  

        sub.unsubscribe();
      }
    });

  }

  getRootCategories() {
    if (!this.catalogService.rootCategories) {

      //getting the main parent categories
      let sub = this.catalogService.rootCategories$.subscribe(rootCategories => {
        if(rootCategories && rootCategories?.length == 1 && rootCategories[0].id == this.NONVISIBLE){
          this.showShopCat = false;
          return;
        }

        if (rootCategories && rootCategories.length > 0) {
          this.rootCategories = rootCategories.sort((a, b) => {
            return (a.displayOrder > b.displayOrder) ? 1 : -1;
          });
          this.showShopCat = true;
        }else {
          this.showShopCat = false;
        }
      });

      this.subscriptions.push(sub);

      //after subscribing to the get the categories
      //getCategories() is called to populate the values
      this.catalogService.getCategories();

    } else {
      this.rootCategories = this.catalogService.rootCategories;

    }
  }

  showRedemptionTNC() {

    //open dialog
    const dialogRef = this.dialog.open(TermsConditionsDialogComponent, {
      maxWidth: '760px',
      panelClass: 'fnbo-terms-condition-dialog',
      backdropClass: '',
      data: {
        title: 'Redemption Terms & Conditions',
        showDefault: true
      }
    });
  }

  showLogoutConfirmation() {
    //open dialog
    const dialogRef = this.dialog.open(LogoutConfirmationComponent, {
      maxWidth: '760px',
      panelClass: 'fnbo-logout-dialog',
      hasBackdrop: true
    });
  }

  gotoTravel() {
    this.accountService.navigateToTravel();
  }
  
  ngOnDestroy() {

    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }


}
