<div id="fnbo-product-content" class="fnbo-center-content fnbo-content" *ngIf="!loading">
    <div id="fnbo-product-filter-btn-area">
        <button class="fnbo-primary-border fnbo-primary-color" mat-button (click)="toggleFilter()">
            <mat-icon aria-hidden="false" aria-label="shopping cart">tune</mat-icon>

            {{showFilterText}}</button>
    </div>
    <!-- filters -->

    <div id="fnbo-products-filter" class="fnbo-primary-border" *ngIf="showFilter">

        <!-- Filter by brands -->
        <div class="fnbo-products-filter-items">

            <mat-form-field appearance="fill">
                <mat-label>Brands</mat-label>
                <mat-select panelClass="fnbo-product-mat-panel" [(ngModel)]="selectedBrand">
                    <mat-option value="any" class="fnbo-primary-color">
                        -- Any --
                    </mat-option>
                    <mat-option *ngFor="let brand of brands" [value]="brand" class="fnbo-primary-color">
                        <span [innerHTML]="brand"></span>

                    </mat-option>
                </mat-select>
            </mat-form-field>

        </div>
        <!-- Filter by points -->
        <div class="fnbo-products-filter-items">

            <mat-form-field appearance="fill">
                <mat-label>Point</mat-label>
                <mat-select panelClass="fnbo-product-mat-panel" [(ngModel)]="selectedPoints"
                    (ngModelChange)="pointsFilterChange($event)">
                    <mat-option value="any" class="fnbo-primary-color">
                        -- Any --
                    </mat-option>
                    <mat-option value="0-2500" class="fnbo-primary-color">
                        0 - 2500
                    </mat-option>
                    <mat-option value="2500-5000" class="fnbo-primary-color">
                        2500 - 5000
                    </mat-option>

                    <mat-option value="5000-7500" class="fnbo-primary-color">
                        5000 - 7500
                    </mat-option>

                    <mat-option value="7500-over" class="fnbo-primary-color">
                        7500 - over
                    </mat-option>

                </mat-select>
            </mat-form-field>

        </div>
        <!-- sort -->
        <div class="fnbo-products-filter-items">

            <mat-form-field appearance="fill">
                <mat-label>Sort by:</mat-label>
                <mat-select [(ngModel)]="sortBy">

                    <!-- using the property to sort by and the order  -->
                    <mat-option value="startingAt-des" class="fnbo-primary-color">
                        Low to High
                    </mat-option>
                    <!-- using the property to sort by and the order  -->
                    <mat-option value="startingAt-asc" class="fnbo-primary-color">
                        High to Low
                    </mat-option>

                </mat-select>
            </mat-form-field>

        </div>

        <div class="fnbo-product-filter-clear fnbo-primary-border">

            <button class="fnbo-primary-border fnbo-primary-color" mat-button (click)="clearFilter()">
                <mat-icon aria-hidden="false" aria-label="shopping cart">tune</mat-icon>

                Clear Filter</button>

        </div>
    </div>


    <div id="fnbo-products-title-area" class="fnbo-display-flex fnbo-primary-border">
        <h2 class="fnbo-primary-color"> {{rootCategory.name}} </h2>

        <div class="fnbo-flex-align-right fnbo-horizontal-center-items-flex">
            <a class="fnbo-primary-color" [routerLink]="'/'">

                <mat-icon class="fnbo-primary-color">arrow_back</mat-icon>
                <p class="">Back Home</p>
            </a>
        </div>
    </div>
    <!-- filters ends -->

    <ng-container *ngIf="products && products.length > 0">

        <!-- main list of project -->
        <diV id="fnbo-product-list">
            <div class="fnbo-product"
                *ngFor="let product of products | productFilter : selectedBrand : '' : selectedPoints | productSort : sortBy |paginate:{itemsPerPage: itemsPerPage, currentPage: currentPage, id : 'fnbo-products-pagination'}">

                <app-single-product [product]="product" [categoryName]="rootCategory.name"></app-single-product>

            </div>

          
        </diV>
        <!-- msg for filters when product result is empty -->
        <div id="fnbo-no-filter-prod" class="fnbo-hide">
            <p>

                No products with filter criteria.
            </p>
        </div>

        <div class="fnbo-display-flex" *ngIf=" products.length > itemsPerPage && products.length > 1 ">

            <pagination-controls id="fnbo-products-pagination" (pageChange)="pageChanged($event)"
                [maxSize]="pageLinksMaxSize"></pagination-controls>
        </div>

    </ng-container>


    <ng-container *ngIf="!products || ( !loading && products.length == 0)">
        <p>No products</p>
    </ng-container>

</div>

<div class="fnbo-loading-area" *ngIf="loading">
    <app-loading [loadingMsg]="'Loading Your Rewards'"></app-loading>
</div>