<div id="fnbo-faq-page" class="" *ngIf="categories || faqsType == 'UNIVERSAL'">
    <div id="fnbo-faq-title-area" class="fnbo-display-flex fnbo-primary-border fnbo-center-content">
        <h2 class="fnbo-primary-color">Frequently Asked Questions</h2>

        <div class="fnbo-flex-align-right fnbo-horizontal-center-items-flex">
            <a class="fnbo-primary-color" [routerLink]="'/'">

                <mat-icon class="fnbo-primary-color">arrow_back</mat-icon>
                <p class="fnbo">Back Home</p>
            </a>
        </div>
    </div>

    <div id="fnbo-faq-content" class="fnbo-content">
        <div class="fnbo-center-content">
            <ng-container *ngIf="faqsType == 'DEFAULT'">
                <div>
                    <div class="drop-down-menu-label">Select a topic*</div>
                    <div class="fnbo-space-below">


                        <mat-form-field appearance="fill" class="drop-down-menu">
                            <mat-select [panelClass]="'fnbo-select'"[(ngModel)]="selected">
                                <div *ngFor="let category of categories">
                                    <mat-option
                                        (click)='changeCategory(category.id, category.description)' [value]="category.id">{{category.description}}</mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <div *ngFor="let topic of selectedCategory">
                            <div id="{{topic.id}}">
                                <mat-accordion>

                                    <mat-expansion-panel id="{{topic.id}}-panel"
                                        class="faq-panel-no-radius-no-shadow fnbo-faq-matexpand-panel fnbo-primary-color"
                                        hideToggle>
                                        <mat-expansion-panel-header class="faq-panel-header-no-padding">
                                            <mat-panel-title class="fnbo-primary-color">
                                                {{topic.question}}
                                            </mat-panel-title>
                                            <span class="material-icons open">add</span>
                                            <span class="material-icons close">remove</span>
                                        </mat-expansion-panel-header>
                                        <p class="faq-answers-p fnbo-text-body" [innerHTML]="topic.answer"></p>
                                    </mat-expansion-panel>
                                </mat-accordion>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="faqsType == 'UNIVERSAL'">
                <div>
                    <universal-faq></universal-faq>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="fnbo-loading-area" *ngIf="!categories && faqsType != 'UNIVERSAL' ">
    <app-loading></app-loading>
</div>