//using Material Design Angular for the table of this page
import { Component, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { OrderHistoryModel } from 'src/core/models/order';
import { Router } from '@angular/router';
import { OrderService } from 'src/core/services/order.service';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-order-history',
  templateUrl: './order-history.component.html',
  styleUrls: ['./order-history.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class OrderHistoryComponent {
  loading : boolean  = true;

  displayedColumns: string[] = ['orderNum', 'date', 'amount', 'totalPartialPay'];

  dataSource: MatTableDataSource<OrderHistoryModel>;

  orders: OrderHistoryModel[];
  expandedElement: OrderHistoryModel | null;
  columnsToDisplayWithExpand = ['expand', ...this.displayedColumns];

  subscriptions: Subscription[];

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private orderService: OrderService, private router: Router) {
    this.subscriptions = [];
  }

  ngOnInit() {

   let sub = this.orderService.ordersInfoItem$.subscribe(x => {
      this.loading = true;

      if (x) {
        this.orders = x;

        //order by date in descending order
       this.orders =  this.orders.sort((a, b) => {
          let d1 = new Date(a.date);
          let d2 = new Date(b.date);
          return (d1 < d2) ? 1 : -1;
        });

        //have to set a timeout so that mat table initialized after the data is set
        this.dataSource = new MatTableDataSource(this.orders);
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.loading = false;
        });

      }
    });

    this.subscriptions.push(sub);

    this.orderService.getOrders();
  }


  ngOnDestroy() {

    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }

}
