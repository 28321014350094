import { CartItemModel } from "./cart";
import { OrderInfoModel } from "./checkout";


export class RedemptionModel {
    public accountNumber: number;
    public accountId: number;
    cart: CartItemModel[];
    public orderInfo: OrderInfoModel;
    public redemptionUser: string;
    public cartPointsTotal: number;
    public partialPayPointsTotal: number;
    public cashTotal: number;
    public authResponse: string;
    public specialUse: string;
}

export class RedemptionResultModel {
    createDate: string;
    id: number;
    status: number;
}
