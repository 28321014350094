import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss']
})
export class TimeoutComponent {

  constructor(public dialogRef: MatDialogRef<TimeoutComponent>, @Inject(MAT_DIALOG_DATA) 
  public data: { message: string, header: any }) {

  }


  closeDialog() {
    this.dialogRef.close();
  }


}
