<div id="fnbo-orderReview" class="fnbo-content">

    <div id="fnbo-orderReview-sections" class="fnbo-display-flex">

        <div *ngIf="!showTablet" id="fnbo-orderReview-left-area" class="fnbo-primary-bg">
            <div *ngIf="cartSummary.isOrderShippable == true" id="fnbo-oreview-shopping" class="">
                <!-- shipping address -->
                <div *ngIf="cartSummary.isOrderShippable == true" id="fnbo-orderInfo-address">
                    <div class="fnbo-display-flex">
                        <p class="fnbo-cart-headings">Shipping Address</p>
                        <a id="fnbo-or-address-edit-link"  [routerLink]="'/cart'">Edit</a>
                    </div>
                
                    
                    <app-order-address  [showForm]="cartSummary.isOrderShippable" [disableForm]="true" [setDisabledAddressSummary]="true"></app-order-address>

                </div>
                <!-- shipping address -->

            </div>
            <div id="fnbo-cart-no-shipping" *ngIf="!cartSummary.isOrderShippable">
                <div>
                    <div>
                        <mat-icon class="">local_shipping</mat-icon>

                    </div>
                    <p>No Shipping Information Required.</p>

                </div>
            </div>
        </div>
        <div *ngIf="!loadingCart" id="fnbo-orderReview-right-area" class="fnbo-flex-align-right ">

            <!-- top functions and summary -->

            <div class="fnbo-display-flex">
                <section id="fnbo-orderReview-right-header">
                    <p>Cart Summary <span> {{itemCount}} items </span></p>

                </section>
                <section *ngIf="previousUrl" class="fnbo-flex-align-right">
                    <button mat-icon-button class="" (click)="goBack()">
                        <mat-icon class="">close</mat-icon>
                    </button>
                </section>



            </div>
            <!-- top functions and summary -->

            <!-- items -->
            <section>
                <ng-container *ngIf="cartItems">
                    <div id="fnbo-or-cart-heading" class="row">
                        <div class="col-lg-2 col-md-2  col-2 fnbo-primary-color">
                            QTY
                        </div>
                        <div class="col-lg-8 col-md-8 col-8 fnbo-primary-color">
                            Description
                        </div>
                        <div class="col-lg-2 col-md-2 col-2 fnbo-primary-color">
                            Points
                        </div>
                    </div>
                    <div *ngFor="let item of cartItems; let i = index" class="fnbo-orderReview-single-item">

                        <app-cart-item [cartItem]="item" (removeItem)="removeItem($event)" [position]="i+1"
                            [showSummary]="true"></app-cart-item>

                    </div>
                </ng-container>


                <div *ngIf="!cartItems">
                    Cart is empty
                </div>

            </section>
            <!-- items ends -->

            <!-- sub total section -->
            <section id="fnbo-orderReview-subtotal" class="fnbo-space-above fnbo-primary-border">

                <div class="fnbo-display-flex fnbo-orderReview-total-points fnbo-primary-color">
                    <p *ngIf="cartSummary.cardTotal===0">TOTAL POINTS REDEMPTION:</p>
                    <p *ngIf="cartSummary.cardTotal>0">Points Subtotal:</p>
                    <p>{{totalPoints.toString() | commaFormat}}</p>
                </div>

                <div *ngIf="cartSummary.cardTotal>0"
                    class="fnbo-display-flex fnbo-orderReview-total-points fnbo-primary-color">

                    <p>Total Points Redemption:</p>
                    <p>{{partialPayPointsTotal}}</p>
                </div>


                <div *ngIf="cartSummary.shippingTotal>0"
                    class="fnbo-display-flex fnbo-orderReview-total-points fnbo-primary-color">

                    <p>Shipping Charges:</p>
                    <p> {{cartSummary.shippingTotal | currency:'USD':"symbol":'1.2-2'}}</p>
                </div>


                <div *ngIf="cartSummary.cashTotal>0"
                    class="fnbo-display-flex fnbo-orderReview-total-points fnbo-primary-color">

                    <p>CHARGE TO CREDIT CARD:</p>
                    <p>{{cartSummary.cashTotal | currency:'USD':"symbol":'1.2-2'}}</p>
                </div>



            </section>
            <!-- sub total section ends -->

            <!-- mobile view items .. ,-->
            <section *ngIf="showTablet">
                <div *ngIf="cartSummary.isOrderShippable == true" id="fnbo-oreview-shopping" class="fnbo-display-flex">

                    <!-- shipping address -->
                    <div *ngIf="cartSummary.isOrderShippable == true">
    
                        <app-order-address-summary [editClasses]="'fnbo-primary-color fnbo-primary-border'"></app-order-address-summary>
    
                    </div>
                    <!-- shipping address -->

                </div>
            </section>
            <!-- mobile view items ends -->


            <!-- payment section -->
            <section *ngIf="cartSummary && cartSummary.cashTotal>0">
                <app-payment-iframe [cartSummary]="cartSummary"></app-payment-iframe>
            </section>
            <!-- payment section ends -->

            <section id="fnbo-orderReview-paper-text" *ngIf="cartSummary && isPaperCheck">
                <h6>NOTE: Paper Checks will arrive in a plain white envelope marked Important Information Inside.</h6>
                <p class="text-red fnbo-cart-text">Based on your redemption, you may receive one or more checks in the
                    mail.
                    Please keep a lookout for your redemption check arrival to avoid accidentally discarding.</p>

                <img class="fnbo-img-w100" src="assets/images/CheckImage_Paper_Envelope.png"
                    alt="Check Image Paper Envelope">

            </section>
            <!-- payment section ends -->

            <section id="fnbo-orderReview-action-area" class="fnbo-primary-border">


                <div>
                    <button *ngIf="cartSummary.cashTotal===0 && itemCount>0" class="fnbo-primary-bg fnbo-orderReview-btn" mat-button
                        (click)="completeOrder()"  [disabled]="disableComplete" [class.loading-spinner-btn]="processing">
                        <mat-icon aria-hidden="false" aria-label="shopping cart" *ngIf="!processing">shopping_cart_checkout</mat-icon>

                        <span *ngIf="!processing">Complete Order</span></button>
                    <button class="fnbo-primary-color fnbo-orderReview-btn" mat-button (click)="goBack()">

                        Continue Shopping</button>
                </div>

                <div id="fnbo-orderReview-order-history-lk" class="text-center fnbo-primary-color">
                    <a [routerLink]="'/order-history'">View Complete Order History</a>
                </div>
            </section>


        </div>

        <div *ngIf="loadingCart" class="fnbo-loading-area" >
            <app-loading [loadingMsg]="'Loading Order Review'"></app-loading>
        </div>

    </div>

</div>