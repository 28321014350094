//service that aids the side navigation 
import { Injectable } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationStart, Router } from "@angular/router";

@Injectable()
export class SideNavService{


    private sidenav: MatSidenav;

    
    constructor(private router: Router) {
        
        
    }


    public setSidenav(sidenav: MatSidenav) {
        this.sidenav = sidenav;


        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                // TODO :Show progress spinner or progress bar
               
                this.sidenav.close();
            }});
    }

    public open() {
        return this.sidenav.open();
    }


    public close() {
        return this.sidenav.close();
    }

    public toggle(): void {
    this.sidenav.toggle();

    }
}
