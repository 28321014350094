import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, lastValueFrom, Observable, of, switchMap, take, throwError } from 'rxjs';
import { AccountModel } from '../models/account';
import { CartItemModel, CartShippingClassModel } from '../models/cart';
import { OrderInfoModel } from '../models/checkout';
import { RedemptionModel, RedemptionResultModel } from '../models/redemption';
import { CartService } from './cart.service';
import { HttpWrapperService } from './http-wrapper.service';
import { MessagingService } from './messaging.service';
import { UtilityService } from './utility.service';
import { SamlAssertionViewModel } from '../models/saml';
import { environment } from 'src/environments';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  redemptionResult: any;
  autoRedeemSuccess: AccountModel;
  accountInfo: AccountModel;
  accountGroupInfo: AccountModel;
  autoRedeemInfo: AccountModel;

  //use to check if the user is logout
  //it is set in the app component
  islogout: boolean = false;

  orderInfo: OrderInfoModel;  // Used to store order information while an order is in process
  cart: CartItemModel[];
  flatCart: CartItemModel[];
  orderResult: RedemptionResultModel;
  orderCsrUser: string;

  private _accountInfo: BehaviorSubject<AccountModel>;
  accountInfo$: Observable<AccountModel>;

  private _accountGroupInfo: BehaviorSubject<AccountModel>;
  accountGroupInfo$: Observable<AccountModel>;

  // Rx Observable for Account Information notification
  private _accountInfoSource: BehaviorSubject<AccountModel>;
  accountInfoItem$: Observable<AccountModel>;

  private _autoRedeem: BehaviorSubject<AccountModel>;
  autoRedeem$: Observable<AccountModel>;

  // Rx Observable for redemption result notification
  private _redemptionResult: BehaviorSubject<RedemptionResultModel>;
  $redemptionResult: Observable<RedemptionResultModel>;


  constructor(private http: HttpWrapperService, private cartService: CartService,
    private messagingService: MessagingService, private utilityService: UtilityService,
    private router: Router) {

    this._accountInfo = new BehaviorSubject<AccountModel>(this.accountInfo);
    this.accountInfo$ = this._accountInfo.asObservable();

    this._accountGroupInfo = new BehaviorSubject<AccountModel>(this.accountGroupInfo);
    this.accountGroupInfo$ = this._accountGroupInfo.asObservable();

    this._accountInfoSource = new BehaviorSubject<AccountModel>(this.accountInfo);
    this.accountInfoItem$ = this._accountInfoSource.asObservable();

    this._autoRedeem = new BehaviorSubject<AccountModel>(this.autoRedeemInfo);
    this.autoRedeem$ = this._autoRedeem.asObservable();
    this._redemptionResult = new BehaviorSubject<RedemptionResultModel>(undefined!);
    this.$redemptionResult = this._redemptionResult.asObservable();

  }


  accountInfoChange() {
    this.cartService.userAccountPoints = this.accountInfo.points;

    this._accountInfoSource.next(this.accountInfo);

  }

  orderResultChange() {
    this._redemptionResult.next(this.orderResult);

    // Right after sending out the order result, clear it for the next order.
    this.orderResult = undefined!;

  }
  clearRedemtionResult() {
    //the ! mark is important to let typescript know it is fine
    this._redemptionResult.next(undefined!);

  }

  // return an Observable
  getAccount(isRedemption: boolean) {

    this.http.get('api/userAccount')
      .subscribe(
        acct => {
          this.accountInfo = acct

          this.accountSuccess();
          if (isRedemption === false) {
            this._accountInfo.next(this.accountInfo);
          }
        }
      );

    // Refresh daily total points
    this.cartService.populateTodayTotalRedemptionPoints();
  }

  resetAccountInfo() {

    this.http.get('api/userAccount')
      .subscribe(
        acct => {
          this.accountInfo = acct
          this.accountSuccess();
        }
      );
  }


  getGroupAccounts() {

    this.http.get('api/getGroupAccounts')
      .pipe(catchError(error => this.handleError(error)))
      .subscribe(
        accountGroupInfo => {
          this.accountGroupInfo = accountGroupInfo
          this._accountGroupInfo.next(this.accountGroupInfo);
        }
      );
  }

  keepSessionAlive() {
    // if the session not expired, keep ping the API to refresh the token.
    this.http.get('api/refreshSession')
      .pipe(catchError(error => {

        this.router.navigate(['error']);

        return this.handleError(error);
      }), switchMap((results) => {

        //using the switchmap because the http custom error 
        //handler can return a observable
        if (results instanceof Observable) {
          return results
        } else {
          return of(results)
        }
      }))
      .subscribe(
        refresh => {

          if (refresh !== 'Session Keep Alive') {
            this.router.navigate(['goodbye']);
          }
        }
      );

    // Refresh the SSO for Montrose
    this.http.get('api/')
  }


  getSamlModel() {
    return this.http.get('api/travel')
      .pipe(catchError(error => this.handleError(error)));
  }

  navigateToTravel() {
    let samlModel = new SamlAssertionViewModel();

    this.getSamlModel().subscribe(model => {

      samlModel = model;
      const formPost = document.forms['formPost'];
      if (formPost) {
        const samlInput = formPost.elements['SAMLResponse'];
        formPost.action = samlModel.uri;
        samlInput.value = samlModel.response;
      }
      document.forms['formPost'].submit();
    });
  }

  accountSuccess() {
    if (this.accountInfo !== undefined) {

      this.cartService.userAccountPoints = this.accountInfo.points;
      // Broadcast message to observers that the account info has changed.
      this.accountInfoChange();

      if (this.accountInfo.canRedeem === false) {
        this.messagingService.onMessageCall('Sorry, your account is not eligible to redeem at this time.  Please call the number on the back of your credit card for further assistance.');
        this.messagingService.isDisabled(true);
        this.cartService.canRedeem = false;
      }
    }
  }

  async doRedemption(authResponse?: any) {
    // Get the cart details and format the request for the API

    const redemptionInfo: RedemptionModel = new RedemptionModel();

    // If we have an authorization response to send along, then add it to the data to post.
    if (authResponse) {
      redemptionInfo.authResponse = JSON.stringify(authResponse);
    }

    redemptionInfo.accountNumber = this.accountInfo.accountNumber;
    redemptionInfo.accountId = this.accountInfo.id;

    if (this.orderInfo !== undefined) {
      redemptionInfo.orderInfo = this.orderInfo;
    } else {
      redemptionInfo.orderInfo = new OrderInfoModel();
    }

    redemptionInfo.cartPointsTotal = this.cartService.cartSummary.pointsTotal;
    redemptionInfo.partialPayPointsTotal = this.cartService.partialPayPointsTotal;
    redemptionInfo.cashTotal = this.cartService.cartSummary.cashTotal;

    // Add any stored redemption CSR user info to the redemption model
    // for AMP redemptions.
    let csrUser = sessionStorage.getItem("csr_name");
    if (csrUser !== null && csrUser !== undefined) {
      this.orderCsrUser = csrUser;
    }

    let cartItems = await lastValueFrom(this.cartService.getCartItems() 
                .pipe(take(1), catchError(error => this.handleError(error))));

    redemptionInfo.cart = cartItems;
    this.flattenCart(cartItems);

     // Use the flattened cart for redemption instead of the normal cart
     redemptionInfo.cart = this.flatCart;

     redemptionInfo.redemptionUser = this.orderCsrUser;
     await this.redeemItems(redemptionInfo);
  }

  private async redeemItems(data: RedemptionModel) {
    var headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json');


    let result = await lastValueFrom(this.http.post('api/redeemPoints', data, { headers: headers })
      .pipe(take(1), catchError(error => this.handleError(error))));

    if (result) {
      this.redemptionResult = result;
      this.orderResult = result;
      this.getAccount(true);
      this.orderResultChange();
    }

  }

  AutoRedeemOptIn(optIn, method) {
    const accountModel: AccountModel = new AccountModel();
    accountModel.id = this.accountInfo.id;
    accountModel.optIn = optIn;
    accountModel.methodSelected = method;

    this.updateAutoRedeem(accountModel);
  }

  private updateAutoRedeem(data: AccountModel) {
    var headers: HttpHeaders = new HttpHeaders();
    headers.append('Content-Type', 'application/json')

    this.http.put('api/autoRedeem', data, { headers: headers })
      .pipe(catchError(error => this.handleError(error)))
      .subscribe(
        result => {
          this.autoRedeemSuccess = result;
          this._autoRedeem.next(this.autoRedeemSuccess);
        }
      );
  }

  // Helper methods

  private flattenCart(data: CartItemModel[]) {


    this.cartService.determineCartShippingOptions(data);

    // Get any partial pay points amount to use for redemption
    const partialPayPoints: number = this.cartService.partialPayPointsTotal;
    const totalCartPoints: number = this.cartService.cartSummary.pointsTotal;

    // Clear the flatCart
    this.flatCart = [];

    for (const cartItem of data) {
      for (let i = 0; i < cartItem.quantity; i++) {

        // Add a new item to the flatCart model
        // with quantity 1
        const flatItem = new CartItemModel();

        flatItem.itemId = cartItem.itemId;
        flatItem.quantity = 1;
        flatItem.bankAccountNumber = cartItem.bankAccountNumber;
        flatItem.bankRoutingNumber = cartItem.bankRoutingNumber;
        flatItem.totalCost = cartItem.totalCost;
        flatItem.wholesaleCost = cartItem.wholesaleCost;
        flatItem.actualCost = cartItem.actualCost;
        flatItem.cashDollarValue = cartItem.cashDollarValue;

        flatItem.shipmentChoicesId = this.setShippingChoice(cartItem);
        flatItem.recipientInfo = cartItem.recipientInfo;
        flatItem.code = cartItem.code;

        // If we are doing partial pay, then distribute points to items
        // in the cart by weighted average.

        if (partialPayPoints > 0) {
          const itemPoints = cartItem.points * (partialPayPoints / totalCartPoints);
          flatItem.points = itemPoints;
        } else {
          flatItem.points = cartItem.points;
        }

        this.flatCart.push(flatItem);
      }
    }
  }

  private setShippingChoice(cartItem: CartItemModel): number {
    let choice: number = 0;

    let shipOption: CartShippingClassModel = this.cartService.cartShippingClasses.find(s => s.shipmentChoicesId === cartItem.shipmentChoicesId)!;

    if (shipOption !== null) {
      choice = shipOption.selectedOption;
    }

    return choice;
  }


  setupDefaultOrderInfo() {
    let orderInfo = new OrderInfoModel();
    //seting basic information
    orderInfo.fullName = this.accountInfo.firstName + ' ' + this.accountInfo.lastName
    orderInfo.paymentFirstName = this.accountInfo.firstName || '';
    orderInfo.paymentLastName = this.accountInfo.lastName || '';
    orderInfo.emailAddress = this.accountInfo.email || '';


    //setting phone number
    let phone = this.utilityService.stripPhonenumber(this.accountInfo.phone);
    orderInfo.phoneAreaCode = phone.substring(0, 3);
    orderInfo.phonePrefix = phone.substring(3, 6);
    orderInfo.phoneLine = phone.substring(6);

    phone = this.utilityService.stripPhonenumber(this.accountInfo.alternatePhone);
    orderInfo.phoneAltAreaCode = phone.substring(0, 3);
    orderInfo.phoneAltPrefix = phone.substring(3, 6);
    orderInfo.phoneAltLine = phone.substring(6);

    //end setting phone numbers

    //shipping address from input from the form 
    orderInfo.shippingAddress1 = this.accountInfo.shippingAddress.street || '';
    orderInfo.shippingAddress2 = '';
    orderInfo.shippingCity = this.accountInfo.shippingAddress.city || '';
    orderInfo.shippingState = this.accountInfo.shippingAddress.state || '';
    orderInfo.shippingCountry = this.accountInfo.shippingAddress.country || '';
    orderInfo.shippingPostalCode = this.accountInfo.shippingAddress.zipCode || '';


    //billing address set to the account address
    orderInfo.billingAddress1 = this.accountInfo.shippingAddress.street;
    orderInfo.billingAddress2 = "";
    orderInfo.billingCity = this.accountInfo.shippingAddress.city;
    orderInfo.billingState = this.accountInfo.shippingAddress.state;
    orderInfo.billingCountry = this.accountInfo.shippingAddress.country;
    orderInfo.billingPostalCode = this.accountInfo.shippingAddress.zipCode;

    return orderInfo;
  }


  private handleError(error: Response | any) {
    let errMsg: string;
    if (error instanceof Response) {

      const body = error.json() || '';

      var err = "";
      body.then(er => {
        err = er.error || JSON.stringify(body);
      })

      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error.message ? error.message : error.toString();
    }


    return throwError(() => new Error(errMsg));
  }

}
