<div class="fnbo-tnc-dialog modal-lg" role="document">
    <div class="modal-content">
        <div class="fnbo-display-flex">
            <div class="fnbo-flex-align-right">
                <button id="fnbo-tnc-dg-close" mat-icon-button class="" (click)="closeDialog()">
                    <mat-icon class="">close</mat-icon>
                </button>
            </div>
        </div>
        <div class="fnbo-tnc-dialog-header">

            <h1 id="">{{title}}</h1>
        </div>
        <div class="fnbo-tnc-dialog-body">
            <div class="fnbo-tnc-dialog-content fnbo-text">
                <ng-container *ngFor="let product of products">


                    <h6 *ngIf="product && product.name && product.tandC" class="fnbo-text-sub-heading" [innerHTML]="product.name">
                    </h6>
                    <div *ngIf="product && product.tandC" [innerHTML]="product.tandC" class="fnbo-text-body"></div>
                </ng-container>

                <ng-container *ngFor="let product of cartItems">


                    <h6 *ngIf="product && product.name && product.tandC" class="fnbo-text-sub-heading" [innerHTML]="product.productName">
                        </h6>
                    <div *ngIf="product && product.tandC" [innerHTML]="product.tandC" class="fnbo-text-body "></div>
                </ng-container>
                <div id="fnbo-defterms-body" [ngClass]="cartItems ? 'fnbo-terms-with-products': ''" *ngIf="data.showDefault" [innerHTML]="defaultTNC"></div>
            </div>
        </div>
        <div class="fnbo-tnc-dialog-footer">
            <button class="fnbo-primary-bg" mat-button (click)="closeDialog()">
                Close</button>
        </div>
    </div>
</div>