
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'searchFilter' })
export class SearchFilterPipe implements PipeTransform {

  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of products
   * @param queryText string to search by
   * @returns filtered list
   */

  transform(items: string[],queryText: string): any[] {
    if (!items) {
      return [];
    }
    if (!queryText) {
      return [];
    }
    queryText = queryText.toLocaleLowerCase();

    return items.filter(x => {
      return x.toLocaleLowerCase().includes(queryText);
    });
  }
}