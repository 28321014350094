import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout-confirmation',
  templateUrl: './logout-confirmation.component.html',
  styleUrls: ['./logout-confirmation.component.scss']
})
export class LogoutConfirmationComponent {

  
  constructor(public dialogRef: MatDialogRef<LogoutConfirmationComponent>, private router : Router) {
       
  }

  
  closeDialog() {
    this.dialogRef.close();
  }


  logout(){
    sessionStorage.clear();
    this.router.navigate(["/goodbye"]);
  }

}
