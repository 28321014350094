<div id="fnbo-not-found">

    <div class="fnbo-display-flex">

        <div>


            <div>
                <div> <mat-icon class="fnbo-primary-color">error</mat-icon></div>
                
            </div>
            <div>

                <h3 class="fnbo-primary-color">Sorry, Page Not Found</h3>
            
            </div>
        </div>
    </div>
</div>