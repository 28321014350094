import { ItemModel, ProductModel } from "./product";

export class CartItemModel {
    public itemId: number;
    public name: string;
    public imageUrl: string;
    public quantity: number;
    public points: number;
    public pointsExtended: number;
    public canPartialPay: boolean;
    public description: string;
    public partialPayPoints: number;
    public partialPayCents: number;
    public vendorId: number;
    public bankAccountNumber: string;
    public bankRoutingNumber: string;
    public rPSAccounts: string;
    public shipmentChoicesId: number;
    public selectedShippingOptionId: number;
    public selectedShippingOptionDescription: string;
    public recipientInfo: string;
    public tandC: string;
    public totalCost: number;
    public wholesaleCost: number;
    public actualCost: number;
    public cashDollarValue: number;
    public productName: string;
    public isItemShippable: boolean;
    public faceValue: number;
    public brand: string;
    public memberCost: number;
    public code: string;


/**
 * Set some properties of the cartItem
 * @param item 
 * @param product 
 */
    public setCartItemModel(item : ItemModel, product : ProductModel){

      this.itemId = item.inventoryItemId;
      this.memberCost = item.memberCost;
      this.brand = product.brand;
      this.description = item.description;
      this.productName = product.name;
      this.imageUrl = item.smallImageUrl;
      this.itemId = item.inventoryItemId;
      this.name = item.inventoryItemName;
      this.points = item.memberCost;
      this.quantity = 1;
      this.pointsExtended = item.memberCost;
      this.canPartialPay = product.partialPayIndicator;
      this.vendorId = item.vendorId;
      this.isItemShippable = item.shipOptionIds === null ? false : true; 
      this.shipmentChoicesId = product.shipmentChoicesId;
      this.tandC = product.tandC;
      this.code = item.code;
    }

  }

  
export class CartSummaryModel {
  // Total items in cart, taking into account quantity.
  public itemCount: number;
  // Total points of items in cart.
  public pointsTotal: number;
  // Total cash charged to credit card, including shipping options and partial pay.
  public shippingTotal: number = 0;
  public cardTotal: number = 0;
  public cashTotal: number;
  // Set to true if at least one gift card (fufillable by Kobie) is in the cart.
  public hasShippingOptions: boolean = false;
  // Set to true if the account can redeem items (from userAccount record).
  public canRedeem: boolean = false;
  public isOrderShippable: boolean= false;

  public partialPayStatus: PartialPayStatus = PartialPayStatus.PointsOnlySufficientPoints;
  public totalCost: number = 0; // Used to track the total vendor cost of the cart (used in partial pay)
  public totalFace: number = 0; // Used to track the total face value of items in the cart (used in total daily limit)


  /**
   *
   */
  constructor() {
    this.itemCount = 0,
    this.pointsTotal = 0,
    this.shippingTotal= 0,
    this.cardTotal= 0,
    this.cashTotal= 0,
    this.hasShippingOptions =false,
    this.canRedeem = true,
    this.partialPayStatus = PartialPayStatus.PointsOnlySufficientPoints,
    this.isOrderShippable = false,
    this.totalCost = 0,
    this.totalFace=  0
    
  }
}

export class ShippingCatalogModel {
    public description: string;
    public name: string;
    public id: number;
    public options: number[];
}

export class ShippingOptionModel {
    public cost: number;
    public daysToOverdue: number;
    public description: string;
    public id: number;
    public name: string;
}

export class CartShippingClassModel {
  public name: string;
  public shipmentChoicesId: number;
  public choices: ShippingOptionModel[];
  public selectedOption: number;
}


export enum PartialPayStatus {
  // Items in cart prevent partial pay and the consumer has enough points to pay
  PointsOnlySufficientPoints = 1,
  // items in cart prevent partial pay and the consumer does not have enough points to pay
  PointsOnlyInsufficientPoints = 2,
  // Can partial pay, but not forced to based on available points balance
  PartialPaySufficientPoints = 3,
  // Can partial pay and forced to based on available points balance
  PartialPayInsuffientPointsMustPartialPay = 4,
  // Cannot partial pay because available points balance is less than business allows for partial pay
  PartialPayInsuffientPointsCannotPartialPay = 5
}

export enum RedeemStatus {
  CannotRedeem = 0,
  CanRedeem = 1
}

