import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CatalogService } from 'src/core/services/catalog.service';

@Component({
  selector: 'app-search-dialog',
  templateUrl: './search-dialog.component.html',
  styleUrls: ['./search-dialog.component.scss']
})
export class SearchDialogComponent {

  searchQuery: string;
  //suggestion array
  suggestions: string[] = [];

  /**
   *
   */
  constructor(public dialogRef: MatDialogRef<SearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { searchQuery: string },
    private catalogService: CatalogService, private router : Router, 
    private route: ActivatedRoute) {

      //getting previous search query
      if(this.data.searchQuery){
        this.searchQuery = this.data.searchQuery;
      }

      //get suggestions ahead of time
      //using the resources that is here
    this.catalogService.getTypeaheadProductNames("").subscribe(r => {
      if (r) {
        this.suggestions = r;
      }

      this.checkForQueryString();
    });

  }

  //if one of the suggestion is selected then
  //this method set that option to the query to search
  //if in the future more needs to get done if user
  //selected a specific product, it will be here
  goToOption(option) {
    this.searchQuery = option;
    this.searchProduct();
  }

  //method redirects to the search results
  searchProduct(){
  
    if(this.searchQuery.length > 0){
      this.router.navigate(['/search-results'], {queryParams : {s : this.searchQuery}});
      this.closeSearchDialog();
      
    }
  }

  checkForQueryString(){
    this.route.queryParams.subscribe(params => {
   
      if(params["s"]){

        this.searchQuery = params["s"];
      }
    
    
    });
  }


  //closes the dialog / popup
  closeSearchDialog() {

    //saving an history  of the searchQuery
    this.dialogRef.close(this.searchQuery);
  }
}
