
export class PaymentFormRequestModel {
    public amount: number;
    public firstName: string;
    public lastName: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public country: string;
    public email: string;
    public phone: string;
    public PaymentAddress: PaymentAddressModel;
}
export class PaymentAddressModel {
    public firstName: string;
    public lastName: string;
    public address: string;
    public city: string;
    public state: string;
    public zip: string;
    public country: string;
}
export class PaymentFormResponseModel {
    public token: string;
    public refId: string;
    public messages: PaymentFormResponseMessagesModel;
    public sessionToken: string;
}

export class PaymentFormResponseMessagesModel {
    public resultCode: number;
    public message: PaymentFormResponseMessageModel[];
}

export class PaymentFormResponseMessageModel {
    public code: string;
    public text: string;
}
