<div id="fnbo-footer" class="fnbo-primary-bg">

    <section id="fnbo-footer-top-area">
        <div class="row">

            <div id="fnbo-footer-top-right" class="fnbo-display-flex">

                <ng-container *ngIf="showShopCat">
                    <div *ngIf="rootCategories && rootCategories.length > 0">
                        <p class="fnbo-footer-header">Shop</p>

                        <ul class="fnbo-footer-links-list">
                            <li *ngFor="let cat of rootCategories">
                                <ng-container *ngIf="cat.id == TRAVELID && cat.id != NONVISIBLE">

                                    <a (click)="gotoTravel()">{{cat.name}}</a>
                                </ng-container>

                                <ng-container *ngIf="cat.id !== TRAVELID && cat.id != NONVISIBLE">

                                    <!-- <a mat-button routerLink="products/{{cat.rootCat.code}}">{{cat.rootCat.name}}</a> -->
                                    <a routerLink="products/{{cat.code}}">{{cat.name}}</a>
                                </ng-container>
                            </li>
                        </ul>
                    </div>
                </ng-container>

                <div id="fnbo-support-footer-sect">
                    <p class="fnbo-footer-header">Support</p>
                    <ul class="fnbo-footer-links-list">
                        <li>
                            <a [routerLink]="'/faq'">FAQ</a>
                        </li>
                        <li>
                            <a (click)="showRedemptionTNC()">Redemption Terms and Conditons</a>
                        </li>
                        <li>
                            <a [routerLink]="'/contact-us'">Contact Us</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <p class="fnbo-footer-header">Account</p>
                    <ul class="fnbo-footer-links-list">
                        <li><a [routerLink]="['/cart']">Shopping Cart</a></li>
                        <li><a [routerLink]="['/order-history']">Order History</a></li>
                        <li><a (click)="showLogoutConfirmation()">Logout</a></li>
                    </ul>
                </div>

            </div>

        </div>

    </section>
    <section id="fnbo-footer-bottom-area">
        <div class="row">
            <div class="col-md-6 col-12">
                <p class="fnbo-footer-text">Copyright {{currentYear}} &copy; First National Bank of Omaha. All Rights
                    Reserved.<br />
                    1620 Dodge Street, Omaha, Nebraska, 68197
                </p>

                <ng-container *ngIf="isUniversal">

                    <p class="fnbo-footer-text">
                        TM &  &copy; {{currentYear}} Universal Studios. All Rights Reserved.
                    </p>
                    <p class="fnbo-footer-text" >
                        Card is issued by Pathward<sup>®</sup>, N.A., Member FDIC, pursuant to a license from Visa U.S.A. Inc. No cash access or recurring payments. 
                        Can be used at select merchants that accept Visa debit cards, see <a href="https://myprepaidcenter.com/page/univ-studios-vpc" target="_blank"> MyPrepaidCenter.com/page/univ-studios-vpc</a>. Card valid for up to 24 months,
                        funds do not expire and may be available after card expiration date, 
                        fees may apply. Terms and conditions apply.
                    </p>
                </ng-container>

            </div>

            <div class="col-md-6 col-12">

                <p class="fnbo-footer-text">If you have a question, please use our
                    <a [routerLink]="['/contact-us']" class="fnbo-inline-link">
                        <strong>contact page </strong>
                    </a>
                    or call the Rewards Service Center at
                    <strong>
                        <a href="tel:888-801-7987" class="plain">888-801-7987</a>.

                    </strong>
                </p>

            </div>

        </div>
    </section>
</div>


<!-- form for tavel -->
<app-travel></app-travel>
