<div *ngIf="showAlertMessage" id="fnbo-top-banner" class="fnbo-primary-bg"  >
    <p [innerHTML]="alertMessage"></p>

   <div id="fnbo-close-alt-icon-div">
    <button  mat-icon-button aria-label="Button to close the alert message"  (click)="closeAlertMessage()">

        <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
    </button>

</div> 
</div>