import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FnboProductCustomLogicService {

  constructor() { }

  /** These rules are for FCA brand products. */
  public applyCustomFCARules(product) {
    if (product.brand == 'FCA') {
      product.largeImage = '/app/assets/images/MC Pref Swp Virtual.png';
      product.imageUrl = '/app/assets/images/MC Pref Swp Virtual.png';
      if(product.faceValue){
        product.name = '$' + product.faceValue + ' DEALERSHIP REDEMPTION PREPAID MASTERCARD<sup>&#174;</sup> <span class="small-name-text">(Dealership Virtual Card)</span>';
      }
       
      product.altName = 'DEALERSHIP REDEMPTION PREPAID MASTERCARD<sup>&#174;</sup> <span class="small-name-text">(Dealership Virtual Card)</span>';
    }
  }
}