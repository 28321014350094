import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AccountModel } from 'src/core/models/account';
import { AccountService } from 'src/core/services/account.service';

@Component({
  selector: 'app-custom-reward',
  templateUrl: './custom-reward.component.html',
  styleUrls: ['./custom-reward.component.scss']
})
export class BankingInfoComponent {

  @Input() type = '';
  accountInfo: AccountModel;
  rPSAccounts: any;
  bankAccountNumber: string = '';
  bankRoutingNumber: string = '';
  recipientInfo: string;
  isPooling: boolean;

  subscriptions: Subscription[];

  bankInfoForm: FormGroup;


  @ViewChild('sampleCheck') sampleCheckDialog = {} as TemplateRef<any>;
  sampleCheckDialogRef : MatDialogRef<any, any>;



  constructor(private accountService: AccountService,
     public formBuilder: FormBuilder, public dialog: MatDialog) {
      this.subscriptions = [];
  }


  ngOnInit(): void {

    this.setupForm();

    // Subscribe to account
    let sub = this.accountService.accountInfoItem$
      .subscribe(accountInfo => {
        if (accountInfo) {

          this.accountInfo = accountInfo;

          if (this.accountInfo.groupId > 0) {
            this.isPooling = true;
            this.accountService.getGroupAccounts();
          }
        }
      });

      this.subscriptions.push(sub);

    // Subscribe to account
    sub = this.accountService.accountGroupInfo$
      .subscribe(accountGroupInfo => {
        if (accountGroupInfo) {
          this.rPSAccounts = accountGroupInfo
        }
      });

      this.subscriptions.push(sub);

  }

  //setting up form with validators
  setupForm() {
    this.bankInfoForm = this.formBuilder.group({
      bankRouting: ['', [Validators.required, Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]{9,}$')]],
      bankAccount: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(17), Validators.pattern('^[0-9]{5,}$')]],
      borrower: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(25), Validators.pattern('[a-zA-Z0-9\s]+')]],
      recieverInfo: ['', [Validators.required, Validators.minLength(20), Validators.maxLength(200)]],
      selectedRPSAccounts: ['', [Validators.required]]

    });
  }

/**
 * check if the form is valid base on the type
 * @returns 
 */
  isValidForm(): boolean {

    let valid = true;


    if (this.type === 'ACH' || this.type === '529A' || this.type === 'CHECK') {

      if (this.bankInfoForm.controls.bankRouting.invalid) {
        this.bankInfoForm.controls.bankRouting.markAsDirty();
        valid = false;
      }

      if (this.bankInfoForm.controls.bankAccount.invalid) {
        this.bankInfoForm.controls.bankAccount.markAsDirty();
        valid = false;
      }


    } else if (this.type === 'MORTGAGE') {

      if (this.bankInfoForm.controls.bankAccount.invalid) {
        this.bankInfoForm.controls.bankAccount.markAsDirty();
        valid = false;
      }

      if (this.bankInfoForm.controls.borrower.invalid) {
        this.bankInfoForm.controls.borrower.markAsDirty();
        valid = false;
      }

    }
    else if (this.type === 'ENABLE') {

      if (this.bankInfoForm.controls.bankAccount.invalid) {
        this.bankInfoForm.controls.bankAccount.markAsDirty();
        valid = false;
      }

    }
    else if (this.type === 'NRA') {
      if (this.bankInfoForm.controls.recieverInfo.invalid) {
        this.bankInfoForm.controls.recieverInfo.markAsDirty();
        valid = false;
      }
    }
    else if (this.type === 'STATEMENTCREDIT') {

      if (!this.recipientInfo || this.bankInfoForm.controls.selectedRPSAccounts.invalid) {
        this.bankInfoForm.controls.selectedRPSAccounts.markAsDirty();
        valid = false;
      }

    }

    return valid;

  }

  selectItem(item: string) {
    this.recipientInfo = item;
  }

  openCheckModal() {
    this.sampleCheckDialogRef = this.dialog.open(this.sampleCheckDialog, {
      disableClose : false,
      panelClass : "fnbo-custrwds-panel",
      hasBackdrop : true
    });
  }

  closeCheckModal(){
    this.sampleCheckDialogRef.close();
  }

  //get the controls
  get bankInfoFormControls() {
    return this.bankInfoForm.controls;
  }

   
  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }
}
