<div *ngIf="!loading">
    <div id="fnbo-prd-highlights-header">
        <p class="fnbo-primary-color">Highlights</p>
    </div>


    <div class="fnbo-center-content">
        <ng-container *ngIf="products && initialColumns">

            <!-- main list of project -->
            <diV id="fnbo-prd-highlights-list" [ngStyle]="{'grid-template-columns' : initialColumns}">
                <!-- slicing cuts off at the 8th item in the list -->
                <div class="fnbo-prd-highlight"
                    *ngFor="let product of products | slice:0:8">
    
                    <app-single-product [product]="product"></app-single-product>
    
                </div>
            </diV>
              
        </ng-container>
    
    </div>
</div>