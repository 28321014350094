import { Component, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsOptions, StyleSettings } from 'src/core/models/settings';
import { SettingsService } from 'src/core/services/settings.service';

@Component({
  selector: 'app-home-carousel',
  templateUrl: './home-carousel.component.html',
  styleUrls: ['./home-carousel.component.scss']
})
export class HomeCarouselComponent {

  promoBanners: any[];
  promoMobileBanners: any[];
  showMobile = false;
  loading: boolean = true;

  subscriptions: Subscription[];
  /**
   *
   */
  constructor(private settings: SettingsService) {
    //determine which carousel to show
    if (window.innerWidth <= StyleSettings.MAXMOBILEWIDTH && this.settings.isMobile()) {
      this.showMobile = true;
    } else {
      this.showMobile = false;
    }


    this.subscriptions = [];
  }

  ngOnInit() {

    let programSettingSub = this.settings.$programSettings.subscribe(settings => {

      if (settings) {


        //web; large devices
        this.promoBanners = [];
        this.addPromoBanners(SettingsOptions.PROMO_BANNER_DSK_IMG, SettingsOptions.PROMO_BANNER_URL, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoBanners);
        this.addPromoBanners(SettingsOptions.PROMO_BANNER_DSK_IMG_2, SettingsOptions.PROMO_BANNER_URL_2, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoBanners,);
        this.addPromoBanners(SettingsOptions.PROMO_BANNER_DSK_IMG_3, SettingsOptions.PROMO_BANNER_URL_3, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoBanners,);

        
        //mobile; small devices
        this.promoMobileBanners = [];
        this.addPromoBanners(SettingsOptions.PROMO_BANNER_MBLE_IMG, SettingsOptions.PROMO_BANNER_URL, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoMobileBanners);
        this.addPromoBanners(SettingsOptions.PROMO_BANNER_MBLE_IMG_2, SettingsOptions.PROMO_BANNER_URL_2, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoMobileBanners);
        this.addPromoBanners(SettingsOptions.PROMO_BANNER_MBLE_IMG_3, SettingsOptions.PROMO_BANNER_URL_3, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoMobileBanners);


        //adding the defaults
        if (this.promoBanners.length == 0) {
          //web; large devices
          this.addDefaultPromoBanners(SettingsOptions.PROMO_BANNER_DSK_IMG, SettingsOptions.PROMO_BANNER_URL, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoBanners);
          this.addDefaultPromoBanners(SettingsOptions.PROMO_BANNER_DSK_IMG_2, SettingsOptions.PROMO_BANNER_URL_2, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoBanners);
          this.addDefaultPromoBanners(SettingsOptions.PROMO_BANNER_DSK_IMG_3, SettingsOptions.PROMO_BANNER_URL_3, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoBanners);

        }

        //adding the defaults
        if (this.promoMobileBanners.length == 0) {
          //mobile; small devices
          this.addDefaultPromoBanners(SettingsOptions.PROMO_BANNER_MBLE_IMG, SettingsOptions.PROMO_BANNER_URL, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoMobileBanners);
          this.addDefaultPromoBanners(SettingsOptions.PROMO_BANNER_MBLE_IMG_2, SettingsOptions.PROMO_BANNER_URL_2, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoMobileBanners);
          this.addDefaultPromoBanners(SettingsOptions.PROMO_BANNER_MBLE_IMG_3, SettingsOptions.PROMO_BANNER_URL_3, SettingsOptions.BANNER_HTML_OVERLAY1, this.promoMobileBanners);

        }

        this.loading = false;
            }

    });

    this.subscriptions.push(programSettingSub);
  }



  //adding each promo banners 
  //bannerlist pass by reference
  //a third parameter was added to display html text
  /**
   * 
   * @param promoBannerSetting 
   * @param promoLinkSetting 
   * @param bannerList 
   * @param mobile 
   */
  addPromoBanners(promoBannerSetting: SettingsOptions,
    promoLinkSetting: SettingsOptions, bannerOverlayHTML1: SettingsOptions, bannerList: any[]) {

    let banner = this.settings.getProgramSetting(promoBannerSetting.toString());
    let bannerLink = this.settings.getProgramSetting(promoLinkSetting.toString());
    let bannerOverlayHTML = this.settings.getProgramSetting(bannerOverlayHTML1.toString());

    if (banner && banner.toLowerCase() !== 'default') {
      bannerList.push({ img: banner, link: bannerLink, text: bannerOverlayHTML });
    }


  }

  //had to separate getting the default and custom banners because 
  // I have to check the list if it has anything before adding the default.
  /**
   * 
   * @param promoBannerSetting 
   * @param promoLinkSetting 
   * @param bannerList 
   * @param mobile 
   */
  addDefaultPromoBanners(promoBannerSetting: SettingsOptions,
    promoLinkSetting: SettingsOptions, bannerOverlayHTML1: SettingsOptions, bannerList: any[]) {


    let banner = this.settings.getDefaultSetting(promoBannerSetting.toString());
    let bannerLink = this.settings.getDefaultSetting(promoLinkSetting.toString());
    let bannerOverlayHTML = this.settings.getProgramSetting(bannerOverlayHTML1.toString());


    if (banner && banner.toLowerCase() !== 'default') {
      bannerList.push({ img: banner, link: bannerLink, text: bannerOverlayHTML });

    }

  }


  ngOnDestroy() {
    this.subscriptions.forEach(x => {
      x.unsubscribe();
    })
  }
}
