import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FnboProductCustomLogicService } from 'src/app/products/fnbo-product-custom-logic-service/fnbo-product-custom-logic.service';
import { CartItemModel, CartShippingClassModel } from 'src/core/models/cart';
import { ProductOptions } from 'src/core/models/settings';
import { CartService } from 'src/core/services/cart.service';

@Component({
  selector: 'app-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss']
})
export class CartItemComponent {

  @Input() cartItem: CartItemModel;
  @Input() showSummary: boolean = false;
  @Input() position: number = 1;
 shippingClasses: CartShippingClassModel[];

  @Output() removeItem : EventEmitter<CartItemModel> =new EventEmitter<CartItemModel>();
  @Output() adjustAmount : EventEmitter<number> =new EventEmitter<number>();
  quantity: number = 0;

  showQuantity : boolean = true;
  

  constructor(private cartService: CartService, private _fnboCustomLogicService:FnboProductCustomLogicService) {

  }

  ngOnInit() {

   this.shippingClasses = this.cartService.cartShippingClasses;

    if(this.cartItem.brand.toLowerCase() == ProductOptions.CASHBACK.toString().toLowerCase()){
      this.showQuantity = false;
    }

    this._fnboCustomLogicService.applyCustomFCARules(this.cartItem);

  }
  /**
   * modify the quantity amount
   * @param amount 
   * @returns 
   */

  modifyAmount(amount) {

    if (amount < 0 && this.cartItem.quantity == 1) {
      return
    }

    this.cartItem.quantity = this.cartItem.quantity + amount;
  
    this.cartItem.pointsExtended = this.cartItem.quantity * this.cartItem.points;

    this.cartService.updateItem(this.cartItem.itemId, amount);
    this.adjustAmount.emit(this.cartItem.itemId);

  }

  //remove from cart is done in the parent
  //I use an eventemitter to communicate with the parent
  removeFromCart(){
    this.removeItem.emit(this.cartItem);
  }

}
