import { EnvironmentConfig } from "./environment-config";

// Production environment
export const environment: EnvironmentConfig = {
  production: true,
  urlBase: "https://cardrewards.fnbo.com/",
  timeoutIdle: 540,
  timeoutTimeout: 60,
  timeoutPingInterval: 15,
  name: "prod",
  gaEnabled: true,
  paymentUrl: "./paymentInformation.html",
  adobeTagManger:"https://assets.adobedtm.com/aaee7fd81fbc/7ea67bec12a3/launch-78a4639e8c21.min.js"
};
