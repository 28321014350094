//basic service that acts as a middle man for the components/pages
import { Inject, Injectable, Renderer2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { OrderInfoModel } from "../models/order";
import { AccountService } from "./account.service";
import { DOCUMENT } from "@angular/common";

@Injectable({
  providedIn: 'root'
})


export class UtilityService {

  //use as a listener for the filter area
  //to toggle it on or off
  filterButton$ = new BehaviorSubject<boolean>(false);

  //global loading setting
  showGlobalLoadingScreen: boolean = false;
  loadingCallsCount: number = 0;
  loadedCallsCount: number = 0;

  constructor() {
  }


  addLoadingCallsCount(val) {

    this.loadingCallsCount += val;
    this.showGlobalLoadingScreen = true;
  }

  addCompletedLoadingCalls(val) {

    this.loadedCallsCount += val;

    if (this.loadedCallsCount >= this.loadingCallsCount) {
      this.showGlobalLoadingScreen = false;
      this.loadedCallsCount = this.loadingCallsCount = 0;
    }
  }

  hideGlobalLoadingScreen() {
    this.showGlobalLoadingScreen = false;
    this.loadedCallsCount = this.loadingCallsCount = 0;
  }

  //utility code to rearrange items 
  //bought over from the old code
  arrayMove(arr, old_index, new_index) {

    if (old_index === new_index) {
      return arr;
    }

    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }


  /**
   * unformat the phone number for storing
   * @param phoneNumber 
   * @returns string 
   */
  stripPhonenumber(phoneNumber) {
    let results = ('' + phoneNumber).replace('+1', '');
    return ('' + results).replace(/\D/g, '');
  }

  /**
   *  formats the phone number just for display
   * @param phoneNumber 
   * @returns string
   */
  formatPhoneNumber(phoneNumber: string) {
    phoneNumber = ('' + phoneNumber).replace(/\D/g, '');
    var match = phoneNumber.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return phoneNumber;
  }

  /**
   * 
   * @param zipcode 
   * @returns the formatted zipcode if it is of length 9
   */
  formatZipCode(zipcode: string) {

    if (zipcode && zipcode.length == 9) {
      return zipcode.replace(/(\d{5})(\d{4})/, "$1-$2");
    }
    return zipcode;
  }


}