import { Pipe, PipeTransform } from "@angular/core";
import { ProductModel } from "../models/product";

@Pipe({
    name: 'productFilter'
})
export class ProductFilterPipe implements PipeTransform {

    transform(products: ProductModel[], brandString: string, catString: string, pointsString: string): any[] {

        //undefined product
        if (!products) { return []; }


        // convert the searchText to lower case
        brandString = brandString.toLowerCase();

        // use to keep track of the state of the product
        //in relation to the filter
        let valid = false;

        // retrun the filtered array
        let newProducts =  products.filter(product => {

            //brand filter
            if (brandString.includes("any")) {
                valid = true;
            } else if ((brandString)) {
                valid = product.brand.toLowerCase() == brandString;

                if (!valid) {
                    return false;
                }
            }

            //category filter
            if (catString == "any") {
                valid = true;
            } else if (catString) {
                valid = product.catId.includes(Number(catString));

                if (!valid) {
                    return false;
                }
            }

            //pointsFilter
            valid = this.pointsFilter(product, pointsString);

            return valid;
        });
        
        this.displayPagination(newProducts);

        return newProducts;
    }

    //used to filter the product list by points
    // it is very important the value used in the select
    //follows the format : min-max or min-over
    // 'over' is the max which is handled below
    pointsFilter(product: ProductModel, pointsString: string): boolean {

        //spliting the value to get the min and max
        let criteria = pointsString.split("-");


        let sMax = criteria.pop();
        let max = Number.MAX_VALUE; //initializing the max 
        let min = Number(criteria.pop());

        if (Number.isNaN(min)) {
            return true;
        }

        //if the max is not over , we set it again with the sMax
        //if there is a need to use another word
        //ensure the value is set accordingly in the html
        // select option
        if (sMax != "over") {
            max = Number(sMax);
        }

        //default max and min for product items
        let productMaxCost = product.startingAt;
        let productMinCost = product.startingAt;

        if (product.items && product.items.length > 0) {

            //getting the min which should be the first
            productMinCost = product.items[0].memberCost;

            productMaxCost = product.items[(product.items.length - 1)].memberCost;

        }

        //filtering the products; if true, it will show and false it will not
        if ((productMinCost == productMaxCost) && (productMinCost < min || productMinCost > max)) {
            return false;
        }
        else {

            if (productMinCost >= min && productMinCost <= max) {
                return true;
            } else if (productMinCost <= max && productMaxCost >= min) {
                return true;
            } else {
                return false;
            }
        }

    }



    //hide pagination if the criteria fits
    displayPagination(products: ProductModel[]) {

        let emptyProductPagniation = document.getElementById("fnbo-products-pagination");
        if (products && products.length <= 1) {


            if (emptyProductPagniation) {
                emptyProductPagniation.classList.add("fnbo-hide");
            }
        }else{
            if (emptyProductPagniation) {
                emptyProductPagniation.classList.remove("fnbo-hide");
            }
        }
    }
}


@Pipe({
    name: 'productSort'
})
export class ProductSortPipe implements PipeTransform {

    //used to sort the product list by a property
    // it is very important the value used in the select
    //follows the format : property-order or startingPrice-asc
    transform(products: ProductModel[], sortByString: string): any[] {

        if (sortByString == "n/a") {
            return products;
        }
        //undefined product
        if (!products || products.length == 0) {
            this.displayEmptyProdsMsg(true);
            return [];
        }

        //getting the field and order
        let criteria = sortByString.split("-");

        let sortByProp = criteria[0];
        let order = criteria[1];

        products.sort((a: ProductModel, b: ProductModel) => {

            if (order == "asc") {

                return this.compareProducts(a[sortByProp], b[sortByProp]);
            } else {
                return this.compareProducts(b[sortByProp], a[sortByProp]);
            }

        });

        this.displayEmptyProdsMsg(false);
        return products;

    }


    compareProducts(a, b) {

        //descending order
        if (a > b) {
            return -1;
        } else if (a < b) { //ascending order
            return 1;

        } else { // equal
            return 0;
        }
    }

    //shows an msg when the product list is empty
    displayEmptyProdsMsg(state) {

        let emptyProductMsg = document.getElementById("fnbo-no-filter-prod");

        if (emptyProductMsg && !state) {
            emptyProductMsg.classList.add("fnbo-hide");
        } else if (emptyProductMsg && state) {
            emptyProductMsg.classList.remove("fnbo-hide");
        }


    }

}