<div id="fnbo-goodbye">

    <div class="fnbo-display-flex">

        <div>


            <div>
                <div> <mat-icon class="fnbo-primary-color">waving_hand</mat-icon></div>
                <h1>THANK YOU!</h1>
            </div>
            <div>

                <h3 class="fnbo-primary-color">You have successfully logged out!</h3>
                <p>Please close this window</p>
            </div>
        </div>
    </div>
</div>