<div id="fnbo-fca-redeem">

    <p>Up to {{maxRedemptionAmt | currency:'USD':"symbol":'1.2-2'}}</p>


    <form [formGroup]="fcaRedeemForm" novalidate>

        <div class="form-group fnbo-space-below">
            <label for="forms-account_number" class="required">Enter Cash Value
                <mat-icon class="fnbo-fca-r-help-icon" #tooltip="matTooltip"
                    matTooltip="All redemptions must be for credits of at least $10.">help</mat-icon>
            </label>
          
            <input class="fnbo-custom-rewards-inpt" [(ngModel)]="dollarAmountEntered" type="text"
                placeholder="0.00"
                [ngClass]="[(fcaRedeemFormControls.dollarAmount.dirty 
                && !fcaRedeemFormControls.dollarAmount.valid) ? 'form-control border-gray is-invalid' : 'form-control' ]" required aria-describedby="help-dollar-entered"
                formControlName="dollarAmount" name="dollarAmount"  autocomplete="off">

            <div *ngIf="fcaRedeemFormControls.dollarAmount.dirty  && !fcaRedeemFormControls.dollarAmount.valid"
                id="help-dollar-entered" class="fnbo-error-msg fnbo-text-sm">
                Must enter an amount of $10 (1000 points) or more and less than the up to amount.
            </div>
            <div *ngIf="formInvalid && !fcaRedeemFormControls.dollarAmount.dirty " class="fnbo-error-msg fnbo-text-sm">
                Check your input.
            </div>
        </div>

    </form>


    <!-- sumbit button -->
    <!-- Flex right if the quantity option is available -->
    <div id="fnbo-fca-r-add-btn">
        <button class="fnbo-primary-color fnbo-primary-border" mat-button (click)="openConfirmationModal()" 
        [disabled]="formInvalid">
            <mat-icon aria-hidden="false" aria-label="assignment turned in">assignment_turned_in</mat-icon>

            Submit</button>
    </div>

    <!-- successful confirmation -->
    <p *ngIf="success" class="text-small">Redemption Successful,
        {{submitedDollarAmount |  currency:'USD':"symbol":'1.2-2'}} approved.</p>

    <!-- successful confirmation ends -->


    <!-- error messages -->
    <section>
        <p *ngIf="failed" class="text-small fnbo-detail-error-message">Redemption Failed, please try again.
            If this continues, please contact administrator.
        </p>
    </section>
    <!-- error messages ends-->

</div>




<!-- dialog for the checks -->
<ng-template let-data #confirmation>
    <div class="fnbo-fca-r-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="fnbo-display-flex">
                <div class="fnbo-flex-align-right">
                    <button id="fnbo-fca-r-dg-close" mat-icon-button class="" (click)="closeConfirmationModal()">
                        <mat-icon class="">close</mat-icon>
                    </button>
                </div>
            </div>
            <div class="fnbo-fca-r-dialog-header">

                <h1>FCA Redeem</h1>
            </div>

            <div class="fnbo-fca-r-dialog-body">
                <div class="fnbo-fca-r-dialog-content">

                    <div>
                        <p>You are about to redeem {{pointsValue | commaFormat}} points for {{dollarAmountEntered |
                            currency:'USD':"symbol":'1.2-2'}}.<br>
                            Are you sure? </p>
                    </div>

                </div>
            </div>
            <div class="fnbo-fca-r-dialog-footer">
                <button class="fnbo-primary-bg" mat-button (click)="submit()">
                    Yes</button>
                <button class="fnbo-primary-bg" mat-button (click)="closeConfirmationModal()">
                    No</button>
            </div>
        </div>
    </div>

</ng-template>