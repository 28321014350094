import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { SettingsOptions } from 'src/core/models/settings';
import { SettingsService } from 'src/core/services/settings.service';

@Component({
  selector: 'app-brand-msg',
  templateUrl: './brand-msg.component.html',
  styleUrls: ['./brand-msg.component.scss']
})
export class BrandMsgComponent {

  subscriptions: Subscription[];
  brandMsg: string;
  brandMsgStyle: string = "";

  constructor(private settings: SettingsService) {
    this.subscriptions = [];
  }

  ngOnInit(): void {
    this.setup();
  }

  setup() {
    //getting the settings
    let programSettingSub = this.settings.$programSettings.subscribe(settings => {

      if (settings) {
      
        //brand message and style
        this.brandMsg = this.settings.getProgramSetting(SettingsOptions.BRAND_MSG.toString());
        this.brandMsgStyle = this.settings.getProgramSetting(SettingsOptions.BRAND_MSG_STYLE.toString());
      }
    });

    this.subscriptions.push(programSettingSub);
  }

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }

}
