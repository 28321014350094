import { Component, HostListener, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription, map } from 'rxjs';
import { OrderAddressComponent } from 'src/core/components/order-address/order-address.component';
import { TermsConditionsDialogComponent } from 'src/core/components/terms-conditions-dialog/terms-conditions-dialog.component';
import { CartItemModel, CartSummaryModel, PartialPayStatus } from 'src/core/models/cart';
import { StyleSettings } from 'src/core/models/settings';
import { AccountService } from 'src/core/services/account.service';
import { CartService } from 'src/core/services/cart.service';
import { OrderService } from 'src/core/services/order.service';
import { UtilityService } from 'src/core/services/utility.service';
import { environment } from 'src/environments';


@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent {

  itemCount: number = 0;
  totalPoints: number = 0;
  cartItems: CartItemModel[];
  cartSummary: CartSummaryModel;

  disableCheckout: boolean = false;
  tncComfirmed: boolean = false;
  loadingCart: boolean = true;
  showTablet: boolean = false;
  showTncError: boolean = false;
  isMagazineOrGiftCard = true;


  //use to go back
  previousUrl: string = "/";

  //holds the subcriptions
  subscriptions: Subscription[];

  @ViewChild(OrderAddressComponent) orderAddressComponent: OrderAddressComponent;

  errorMsg: string;
  username: string | null;

  constructor(private router: Router, private route: ActivatedRoute, private cartService: CartService,
    private orderService: OrderService, public dialog: MatDialog,
    private utilityService: UtilityService, private accountService: AccountService) {
    this.subscriptions = [];
  }

  ngOnInit() {
    if (window.innerWidth <= StyleSettings.MAXTABLETWIDTH) {
      this.showTablet = true;
    } else {
      this.showTablet = false;
    }
    this.setup();
  }

  //setup the cart
  setup() {
    //important to call this first because code after checks
    //for it
    this.username = sessionStorage.getItem('csr_name');


    //getting the query params passed through
    //the route service ; this will not show in the 
    //url area
    let currentStateSubscription = this.route.paramMap.pipe(map(() => window.history.state)).subscribe(x => {
      if (x) {

        //pervious link to navigate to
        if (x.previousUrl) {
          this.previousUrl = x.previousUrl;
        }

      }
    });

    this.subscriptions.push(currentStateSubscription);

    //initial state for the partial payment if user traverse back from order review
    //important code; reset the partial payment

    if (this.cartService.cartSummary) {

      this.cartService.partialPayChange(0, 0, this.cartService.cartSummary.pointsTotal);
    }


    let cartSub = this.cartService.$cart.subscribe(x => {
      this.getCartData();

      this.checkCartProductTypes();

      if (x) {
        this.checkCartValidity();
      }
    });

    let pointsSub = this.cartService.$partialPayPoints.subscribe(x => {
      if (x) {
        this.checkCartValidity();
      }
    });


    this.subscriptions.push(cartSub);
    this.subscriptions.push(pointsSub);


    //temporary
    this.loadingCart = false;
    this.disableCheckout = this.cartService.isDisabled;

  }

  getCartData() {

    //checking if the application is being used by a csr person
    // let username = sessionStorage.getItem("csr_name");

    this.cartSummary = this.cartService.cartSummary;
    this.cartItems = this.cartService.getFullCartList();
    this.itemCount = this.cartSummary.itemCount;
    this.totalPoints = this.cartSummary.pointsTotal;

  }

  /**
   * remove an item from the cart
   * @param cartItem 
   */
  removeItem(cartItem: CartItemModel) {
    this.loadingCart = true;

    this.cartService.removeItem(cartItem.itemId)

    this.getCartData(); // reloading the cart
    this.checkCartProductTypes();

    this.loadingCart = false;
  }

  //go back to the previous page
  goBack() {
    this.router.navigate(["/highlights"]);
  }

  ///checkout the cart
  checkout() {


    let success = true;
    //if order is shippable then the address is checked
    if (this.cartSummary.isOrderShippable) {

      success = this.orderAddressComponent.saveForm();
    } else {
      this.setupDefaultOrderInfo();
    }

    if (!this.tncComfirmed) {
      this.showTncError = true;
      return;
    }


    if ((this.orderService.orderInfo || !this.cartSummary.isOrderShippable) && success) {


      this.cartService.updateCartShippingSelections();

      this.router.navigate(["checkout/order-review"], {
        state: { previousUrl: this.router.url }
      });
    }
  }

  //setup default order; more for a reset when going to a cart
  setupDefaultOrderInfo() {

    let defaultOrderInfo = this.accountService.setupDefaultOrderInfo();
    this.orderService.orderInfo = defaultOrderInfo;
    this.accountService.orderInfo = defaultOrderInfo;
  }

  tncComfirmedChange(value) {
    this.tncComfirmed = value;
    this.showTncError = false;
  }

  /**
   * Open a dialog to show the terms and condition for redemption
   */

  showRedemptionTNC() {
    //open dialog
    const dialogRef = this.dialog.open(TermsConditionsDialogComponent, {
      maxWidth: '760px',
      panelClass: 'fnbo-terms-condition-dialog',
      backdropClass: '',
      data: {
        cartItems: this.cartItems,
        title: 'Redemption Terms & Conditions',
        showDefault: true
      }
    });
  }

  partialPaySelection(value) {
    this.checkCartValidity();
  }
  //checking the validity of the cart
  checkCartValidity() {

    //if there is no data in either then there is nothing to check for
    if (!this.accountService.accountInfo || !this.cartService.cartSummary) {
      return;
    }

    let partialPayStatus = (this.cartService.cartSummary.partialPayStatus as PartialPayStatus);

    if (this.accountService.accountInfo.points < 1000) {

      if (this.accountService.accountInfo.canRedeem) {
        this.errorMsg = 'Sorry, you need to have a minimum of 1000 points to redeem for any item.';
      }

      this.disableCheckout = true;

    } else if (this.cartService.todayPoints + this.cartSummary.totalFace >= 10000) {

      this.errorMsg = 'Redemption amount includes standard shipping, handling, and applicable taxes. '
        + 'Due to daily purchase limits, orders each day can not exceed $10,000 in value.';
      this.disableCheckout = true;

    } else if ((partialPayStatus === PartialPayStatus.PartialPayInsuffientPointsCannotPartialPay) && this.cartService.partialPayPointsTotal <= 0) {

      this.errorMsg = 'Sorry, you do not have enough points to complete your order.';
      this.disableCheckout = true;

    } else if ((partialPayStatus === PartialPayStatus.PartialPayInsuffientPointsMustPartialPay) && this.cartService.partialPayPointsTotal <= 0) {

      if (this.username !== null) {
        this.errorMsg = 'Customer does not have enough points to complete this order. CSR cannot process partial pay.';
      } else {
        this.errorMsg = 'You do not have enough points to complete your order, but don\'t worry. You can use a combination of points and dollars to pay for your transaction.';
      }

      this.disableCheckout = true;

    }

    else {
      this.errorMsg = "";
      this.disableCheckout = false;


    }

    if (environment.name == 'demo' || !this.accountService.accountInfo.canRedeem) {
      this.disableCheckout = true;
    }
  }


    //TODO: there has to be a better way of doing this
  //checking for different product types
  checkCartProductTypes() {
    this.isMagazineOrGiftCard = true;
    this.cartItems.forEach(element => {
      if (element.imageUrl?.toLowerCase().includes("awardcenter")) {
        this.isMagazineOrGiftCard = false;
      }
    });
  }


  //controls what set of banners that shows
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= StyleSettings.MAXTABLETWIDTH) {
      this.showTablet = true;
    } else {
      this.showTablet = false;
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {

      this.subscriptions.forEach(x => {
        x.unsubscribe();
      });
    }
  }


}
