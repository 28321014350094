//FROM OLD PROJECT
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-travel',
  templateUrl: './travel.component.html',
  styleUrls: ['./travel.component.scss']
})
export class TravelComponent implements OnInit {

  targetName: string = 'montrosetravel';
  
  constructor() { }

  ngOnInit(): void {  }



}
