<div *ngIf="loadingItems <= 0" id="fnbo-oresult-content" class="fnbo-center-content fnbo-content">
    <ng-container *ngIf="orderResult">


        <div id="fnbo-oresults-title-area" class="fnbo-display-flex fnbo-primary-border">
            <h2 *ngIf="orderResult.status === 2" class="fnbo-primary-color"> Thank You </h2>
            <h2 *ngIf="orderResult.status !== 2" class="fnbo-primary-color"> Sorry </h2>

            <div class="fnbo-flex-align-right fnbo-horizontal-center-items-flex">
                <a class="fnbo-primary-color" [routerLink]="'/'">

                    <mat-icon class="fnbo-primary-color">arrow_back</mat-icon>
                    <p class="">Back Home</p>
                </a>
            </div>
        </div>

        <!-- main content -->
        <div *ngIf="orderResult.status === 2" class="fnbo-space-above">
            <div class="fnbo-oresult-img-area-mb fnbo-primary-border">


                <div id="fnbo-oresult-img-area" class="fnbo-space-below ">
                    <img class="fnbo-oresult-img" src="assets/images/order-result-thank-you.svg" alt="thank you image"/>
                   
                </div>
                <div class="fnbo-oresult-message-lg">
                    <p>Your order was received!</p>
                </div>
            </div>

            <div class="row">

                <section class="col-lg-6 col-md-12 col-12">
                    <p class="fnbo-oresult-header fnbo-primary-color fnbo-space-below">
                        Order Summary
                    </p>

                    <div id="fnbo-oresult-info" class="fnbo-primary-border fnbo-space-below">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-12">
                                <ng-container *ngIf="orderResult && cartSummary">
                                    <!-- Order Confirmation Number  -->
                                    <div class="fnbo-oresult-prop">
                                        <p class="fnbo-oresult-prop-header fnbo-primary-color"> Order Confirmation
                                            Number
                                        </p>
                                        <p class="fnbo-oresult-prop-value">{{orderResult.id}}</p>
                                    </div>
                                    <!-- Order Date & Time -->
                                    <div class="fnbo-oresult-prop">
                                        <p class="fnbo-oresult-prop-header fnbo-primary-color">Order Date & Time</p>
                                        <p class="fnbo-oresult-prop-value">
                                            {{ orderResult.createDate | date: 'MM-dd-YYYY hh:mm:ss aa' }}</p>
                                    </div>

                                    <!--Total Points Redeemed  -->
                                    <div *ngIf="partialPayPointsTotal || cartSummary.pointsTotal"
                                        class="fnbo-oresult-prop">
                                        <p class="fnbo-oresult-prop-header fnbo-primary-color">Total Points Redeemed</p>
                                        <p class="fnbo-oresult-prop-value" *ngIf="partialPayPointsTotal">
                                            {{partialPayPointsTotal.toString() | commaFormat }}

                                        </p>
                                        <p class="fnbo-oresult-prop-value"
                                            *ngIf="!partialPayPointsTotal && cartSummary.pointsTotal">
                                            {{(cartSummary.pointsTotal.toString()) | commaFormat }}

                                        </p>
                                    </div>
                                    <!--Charged to Credit Card  -->
                                    <div class="fnbo-oresult-prop">
                                        <p class="fnbo-oresult-prop-header fnbo-primary-color">Charged to Credit Card
                                        </p>
                                        <p class="fnbo-oresult-prop-value">
                                            {{cartSummary.cashTotal | currency:'USD':"symbol":'1.2-2'}}
                                        </p>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="col-lg-6  col-md-6 col-12">
                                <ng-container *ngIf="orderInfo">

                                    <!-- Shipping Information -->
                                    <div class="fnbo-oresult-prop">
                                        <p class="fnbo-oresult-prop-header fnbo-primary-color">Shipping Information</p>
                                        <p class="fnbo-oresult-prop-value">{{orderInfo.fullName}}</p>
                                        <p *ngIf="orderInfo.companyName" class="fnbo-oresult-prop-value">
                                            {{orderInfo.companyName}}</p>
                                        <p class="fnbo-oresult-prop-value">{{orderInfo.shippingAddress1}}
                                            {{orderInfo.shippingAddress2}}</p>
                                        <p class="fnbo-oresult-prop-value">{{orderInfo.shippingCity}},
                                            {{orderInfo.shippingState}}</p>
                                        <p class="fnbo-oresult-prop-value">{{orderInfo.shippingCountry}},
                                            {{orderInfo.shippingPostalCode}}</p>
                                    </div>
                                    <!-- Phone -->
                                    <div class="fnbo-oresult-prop">
                                        <p class="fnbo-oresult-prop-header fnbo-primary-color">Phone</p>
                                        <p class="fnbo-oresult-prop-value">({{orderInfo.phoneAreaCode}})
                                            {{orderInfo.phonePrefix}}-{{orderInfo.phoneLine}}</p>
                                    </div>
                                    <!-- Email -->
                                    <div class="fnbo-oresult-prop">
                                        <p class="fnbo-oresult-prop-header fnbo-primary-color">Email</p>
                                        <p class="fnbo-oresult-prop-value">{{orderInfo.emailAddress}}</p>
                                    </div>
                                </ng-container>
                            </div>

                        </div>
                    </div>

                    <div *ngIf="cartItems" id="fnbo-oresult-item-table" class="fnbo-primary-border fnbo-space-below">

                        <table>
                            <tr class="fnbo-primary-color">
                                <th>QTY</th>
                                <th>Description</th>
                                <th>Points</th>
                            </tr>
                            <ng-container *ngFor="let item of cartItems">
                                <tr>
                                    <td>{{item.quantity}}</td>
                                    <td>
                                        <p [innerHTML]="item.name"></p>
                                        <p class="fnbo-text-secondary">{{item.selectedShippingOptionDescription | shippingOption : shippingClasses : item.shipmentChoicesId}}</p>
                                    </td>
                                    <td class="fnbo-text-right">
                                        <p>{{item.pointsExtended.toString() | commaFormat}}</p>
                                    </td>
                                </tr>
                            </ng-container>

                        </table>

                    </div>


                    <div *ngIf="cartSummary" id="fnbo-oresult-cost-table" class="fnbo-primary-border fnbo-space-below">
                        <table>
                            <tr class="fnbo-primary-color">
                                <th></th>
                                <th class="fnbo-text-right"></th>
                            </tr>

                            <tr *ngIf="cartSummary.pointsTotal">
                                <td>
                                    <p *ngIf="cartSummary.cardTotal===0">TOTAL POINTS REDEMPTION:</p>
                                    <p *ngIf="cartSummary.cardTotal>0">Points Subtotal:</p>
                                </td>
                                <td class="fnbo-text-right">
                                    <p>{{cartSummary.pointsTotal.toString() | commaFormat }}</p>
                                </td>
                            </tr>

                            <tr *ngIf="cartSummary.cardTotal>0 && (partialPayPointsTotal || cartSummary.pointsTotal)">
                                <td>
                                    <p>Total Points Redemption:</p>
                                </td>
                                <td class="fnbo-text-right">
                                    <p *ngIf="partialPayPointsTotal">
                                        {{ partialPayPointsTotal.toString() | commaFormat }}
                                    </p>
                                    <p *ngIf="!partialPayPointsTotal && cartSummary.pointsTotal ">
                                        {{ cartSummary.pointsTotal.toString() | commaFormat }}
                                    </p>
                                </td>
                            </tr>
                            <tr *ngIf="cartSummary.shippingTotal>0">
                                <td>
                                    <p>Shipping Charges:</p>
                                </td>
                                <td class="fnbo-text-right">
                                    <p>
                                        {{cartSummary.shippingTotal | currency:'USD':"symbol":'1.2-2'}}
                                    </p>
                                </td>
                            </tr>
                            
                            <tr *ngIf="cartSummary.cardTotal>0">
                                <td>
                                    <p>Charge To Credit Card:</p>
                                </td>
                                <td class="fnbo-text-right">
                                    <p>
                                        {{cartSummary.cashTotal | currency:'USD':"symbol":'1.2-2'}}
                                    </p>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div>
                        <p class="fnbo-note-text"> <strong>Note:</strong> Where applicable, redemption amount includes standard shipping, handling, and applicable taxes.
                        </p>
                    </div>
                    <div>
                        <!-- <p class="fnbo-note-text">
                            If you have any questions about your order, please send us a note here or
                            call us at <strong>

                                <a href="tel:1-888-801-7987" class="plain text-offblack">888-801-7987</a>.
                            </strong>
                        </p> -->
                    </div>

                </section>

                <section class="col-lg-6 col-md-12 col-12">
                    <div class="fnbo-oresult-order-histoy-link fnbo-space-below">
                        <a class="fnbo-primary-color" [routerLink]="'/order-history'">

                            View Complete Order History
                        </a>
                    </div>
                    <div class="fnbo-oresult-img-area-web">


                        <div id="fnbo-oresult-img-area" class="fnbo-space-below">
                           
                            <img class="fnbo-oresult-img" src="assets/images/order-result-thank-you.svg" alt="thank you image"/>
                          
                        </div>
                        <div class="fnbo-oresult-message-lg">
                            <p>Your order was received!</p>
                        </div>
                    </div>
                    <div>
                        <button class="fnbo-primary-bg fnbo-oresult-btn" mat-button (click)="continueShopping()">

                            Continue shopping</button>
                    </div>
                </section>
            </div>
        </div>

        <!-- main content ends -->

        <!-- error occurred -->

        <div *ngIf="orderResult.status !== 2" class="fnbo-space-above">

            <p class="fnbo-oresult-fail-msg">Sorry, we can’t complete your purchase at this time. Please contact Customer Service at <br>
                888-801-7987</p>
        </div>
        <!-- error occurred ends-->
    </ng-container>
</div>


<div *ngIf="loadingItems > 0" class="fnbo-loading-area" >
    <app-loading [loadingMsg]="'Loading Order Summary'"></app-loading>
</div>