<!-- area that holds the denomination options dropdwon -->
<div id="fnbo-prd-detail-denomination" class="fnbo-space-below">

    <!-- select area for choosing a denomination -->

    <p class="fnbo-detail-sm-text">Select method *</p>
    <select class="form-select fnbo-primary-border fnbo-primary-color" id="fnbo-prd-detail-den-select" [(ngModel)]="methodSelected"
        aria-label="Denomination" (ngModelChange)="onChangeDenomination($event)">
        <option value="n/a" selected disabled>Please select one option </option>
    
        <option [selected]="methodSelected == 'Auto Redeem for Check' " value="Auto Redeem for Check">Auto Redeem for Check</option>
        <option [selected]="methodSelected == 'Auto Redeem for Statement Credit' " value="Auto Redeem for Statement Credit">Auto Redeem for Statement Credit</option>
    
    </select>

    <!-- error message -->
    <div class="fnbo-error-msg fnbo-text-sm">
        <p *ngIf="showMethodError">Please, select an option above.</p>
    </div>
    <!-- error message ends -->

    <!-- select area for choosing a denomination ends -->
</div>

<section class="fnbo-autor-optin-sect">
    <mat-checkbox class="example-margin" [disableRipple]="true" (ngModelChange)="onOptInChange($event)" [(ngModel)]="isOptIn">Opt-In</mat-checkbox>
</section>


<!-- add to cart button -->
<div *ngIf="showSaveBtn" id="fnbo-detail-add-btn" class="">
    <button class="fnbo-primary-color fnbo-primary-border" mat-button (click)="updateAutoRedeem()" [disabled]="updated">
        <mat-icon aria-hidden="false" aria-label="shopping cart"> system_update_alt </mat-icon>

        Save Settings</button>
</div>

<!-- If the settings is already saved -->
<div *ngIf="updated && !showSaveBtn" class="">
    <p >Settings saved!</p>
</div>


<div *ngIf="updated && !showSaveBtn" id="fnbo-detail-add-btn" class="">
    <button class="fnbo-primary-color fnbo-primary-border" mat-button (click)="goBack()">
        <mat-icon aria-hidden="false" aria-label="shopping cart"> shopping_cart </mat-icon>

        Continue shopping</button>
</div>