import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { MessagingService } from 'src/core/services/messaging.service';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.scss']
})
export class MessagingComponent {
  @Input() message;
  subscriptionMessage: Subscription;

  constructor(public messagingService: MessagingService) { }

  ngOnInit(): void {

    //Listen to any message from the messaging service.
    this.subscriptionMessage = this.messagingService.$canRedeemMessage.subscribe(
      message => {
        this.message = message
        if (message !== "") {
          this.messagingService.isDisabledAdd(true);
        }
        else {
          this.messagingService.isDisabledAdd(false);
        }
      });
  }

  ngOnDestory() {
    if(this.subscriptionMessage)
      this.subscriptionMessage.unsubscribe();
  }
}
