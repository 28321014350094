import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CartSummaryModel } from 'src/core/models/cart';
import { RedemptionResultModel } from 'src/core/models/redemption';
import { AccountService } from 'src/core/services/account.service';
import { CartService } from 'src/core/services/cart.service';
import { environment } from 'src/environments';


@Component({
  selector: 'app-payment-iframe',
  templateUrl: './payment-iframe.component.html',
  styleUrls: ['./payment-iframe.component.css']
})
export class PaymentIframeComponent implements OnInit {
  frameWidth: number = 500;
  frameHeight: number = 1100;
  responseCode: number = 0;
  subscriptionOrder: Subscription;
  orderResult: RedemptionResultModel;
  creditCardBalance: number = 0;
  subscriptionCart: Subscription;
  paymentUrl: SafeResourceUrl;
  vars : any[] = []

  @Input()
  allValid : boolean = false;

  @Input()
  cartSummary: CartSummaryModel;

  constructor(private cartService: CartService,
              private accountService: AccountService,
              private router: Router,
              private sanitizer: DomSanitizer) {

  }

  parseQueryString(str) {
  
    var arr = str.split('&');
    var pair;
    for (var i = 0; i < arr.length; i++) {
      pair = arr[i].split('=');
      if(pair){

        this.vars[pair[0]] = decodeURIComponent(pair[1])!;
      }
    }
    return this.vars;
  }

  ngOnInit() {

    // Subscribe to redemption order
    this.subscriptionOrder = this.accountService.$redemptionResult
    .subscribe(orderResult => {
        if (orderResult !== undefined) {
          this.orderResult = orderResult;
         
          this.postRedemptionAction();
        }
    });

    this.subscriptionCart = this.cartService.$partialPayCents
    .subscribe(item => {
        this.creditCardBalance = item
    });

       if ((<any>window).CommunicationHandler === undefined) {

      (<any>window).CommunicationHandler = {};

      (<any>window).CommunicationHandler.onReceiveCommunication = (argument) => {


        var params = this.parseQueryString(argument.qstr);

        switch(params['action']) {
          case 'resizeWindow':
                    // Set the frame size
            this.frameWidth = params['width'];
            this.frameHeight = params['height'];

          break;

          case 'cancel':
           
            this.router.navigate(['cart']);
          break;

          case 'transactResponse':

            let tranResponse = JSON.parse(params['response']);
            this.handleTransactionResponse(tranResponse);
          break;

          default:
          break;
        }
      }
    }

    // Set payment URL based on build type (prod/non-prod)
    if(environment.paymentUrl){

      this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.paymentUrl);
    }else{
      //TODO: Error with finding the payment link
    }

  }

  handleTransactionResponse(response: any) {

   

    // Set the response code in the controller
    this.responseCode = Number(response.responseCode);

    switch(this.responseCode) {
      case 1: // success
      
        this.handleSuccessTransaction(response);
        break;

      case 2: // decline
     
        this.handleDeclineTransaction(response);
        break;

      case 4: // hold
        
        this.handleHoldTransaction(response);
        break;

      default:
        
        break;
    }
  }

  handleSuccessTransaction(response: any) {
      this.accountService.doRedemption(response);
  }

  handleDeclineTransaction(response: any) {
    
  }

  handleHoldTransaction(response: any) {
    
  }

  // After order redemption we will either rollback or more forward with funds capture
  postRedemptionAction() {
    // Short circuit undefined actions
    if (this.orderResult === undefined) {
      return;
    }

    if (this.orderResult.status === 2) {
      // Success - capture funds
     
    }
    else {
      // Failure - rollback
      
    }

    // Move to the order result page
    this.router.navigate(['/checkout/order-results']);
  }
}
