import { visitAll } from "@angular/compiler";
import { Pipe, PipeTransform } from "@angular/core";
import { ItemModel } from "../models/product";

@Pipe({ name: "customProductItemSort" })
export class CustomTextSort implements PipeTransform {

    /**
     * sort a list
     * @param value 
     * @param orderby desc -1 or asc 1
     */
    transform(values: ItemModel[], orderby: number): ItemModel[] {

        if (values.length < 2) {
            return values;
        }

        return values.sort((a, b) => {

            //removing all special characters to get the numbers
            let desc1 = Number(String(a.description).replace(/[^\w\s\.]/gi, '') || 0);
            let desc2 = Number(String(b.description).replace(/[^\w\s\.]/gi, '') || 0);

            if ((desc1 > desc2) && orderby == -1) {
                return -1;
            }
            else if ((desc1 < desc2) && orderby == 1) {
                return 1;
            } else if (desc1 < desc2 && orderby == -1) {
                return 1;
            }
            else {
                return -1;
            }
        });

    }

}